/* eslint-disable no-useless-constructor */
import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderProcessBar from '../../../common/OrderProcessBar';
import BannerCategoryContainer from '../../../common/BannerCategoryContainer';
import { setPhase } from '../../../../common/redux/actions';
import OrderFailedPhase from '../../../common/OrderPhasesContainer/OrderFailedPhase/OrderFailedPhase';

// eslint-disable-next-line react/prefer-stateless-function
class CartFailedView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.setPhase(3);
    }

    render() {
        return (
            <>
                <BannerCategoryContainer bannerName="CARRELLO" bgColor="#C8F344" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_carrello-header.png" />

                <OrderProcessBar />
                <OrderFailedPhase />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setPhase: bindActionCreators(setPhase, dispatch),
        },
    };
}

export default connect(null, mapDispatchToProps)(CartFailedView);

CartFailedView.propTypes = {

    actions: PropTypes.func,

};

CartFailedView.defaultProps = {
    actions: undefined,
};
