/* eslint-disable import/prefer-default-export */
import { notification } from 'antd';

export function errorNotification(message) {
    notification.error({
        message: 'Errore:',
        description: message,
    });
}

export function successNotification(title, message) {
    notification.success({
        message: title,
        description: message,
    });
}
