/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import {
	Input,
	Form,
	Table,
	Radio,
	Select,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { fwdPhase } from '../../../../common/redux/actions';
import { hideLoadingOverlay, showLoadingOverlay } from '../../../../common/loadingOverlay';
import { errorNotification } from '../../../../common/Notification';

const { Option } = Select;

class OrderSecondPhase extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			cartItemListKeys: [],
			purchaseItems: [],

			regioniOptions: [],
			provinceOptions: [],
			comuniOptions: [],

			regione: '',
			provincia: '',
			comune: '',

			stateDiscountData: {},

			totalPrice: 0,
			totalPriceIva: 0,

			model: {
				clientType: 1,
				billingInfo: {
					nome: null,
					cognome: null,
					cf: null,
					uniCode: null,
					iva: null,
					ragioneSociale: null,
					indirizzo: null,
					nCivico: null,
					cap: null,
					istatCode: null,
					irpefCode: null,
				},
			},
		};

		this.completePurchase = this.completePurchase.bind(this);

		this.updateClientType = this.updateClientType.bind(this);
		this.updateNome = this.updateNome.bind(this);
		this.updateCognome = this.updateCognome.bind(this);
		this.updateCf = this.updateCf.bind(this);
		this.updateIva = this.updateIva.bind(this);
		this.updateUniCode = this.updateUniCode.bind(this);
		this.updatePec = this.updatePec.bind(this);
		this.updateRagioneSociale = this.updateRagioneSociale.bind(this);
		this.updateIndirizzo = this.updateIndirizzo.bind(this);
		this.updateNCivico = this.updateNCivico.bind(this);
		this.updateCap = this.updateCap.bind(this);

		this.loadRegioniOptions = this.loadRegioniOptions.bind(this);
		this.setTableData = this.setTableData.bind(this);
		this.loadUserData = this.loadUserData.bind(this);

		this.setGeoData = this.setGeoData.bind(this);

		this.getItemDiscount = this.getItemDiscount.bind(this);

		this.updateProvinceRegione = this.updateProvinceRegione.bind(this);
		this.updateComuniProvincia = this.updateComuniProvincia.bind(this);
		this.updateComune = this.updateComune.bind(this);

		this.formValidation = this.formValidation.bind(this);

		this.toFixedNumber = this.toFixedNumber.bind(this);
	}

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.discountData !== {} && nextProps.discountData !== undefined) {
			return {
				stateDiscountData: nextProps.discountData,
			};
		}
		return null;
	}

	componentDidMount() {
		this.loadRegioniOptions();
		this.setTableData();
		this.loadUserData();
	}

	setTableData() {
		const { cartItemList } = this.props;
		let {
			cartItemListKeys,
			purchaseItems,
		} = this.state;

		cartItemListKeys = [];
		purchaseItems = [];

		cartItemList.forEach((cartItem) => {
			cartItemListKeys.push({

				key: cartItem.id,
				id: cartItem.id,
				productId: cartItem.productId,
				descriptionHtml: cartItem.descriptionHtml,
				imgUrl: cartItem.imgUrl,
				ivaPercentage: cartItem.ivaPercentage,
				linkShop: cartItem.linkShop,
				price: cartItem.price,
				title: cartItem.title,

			});

			const itemDiscount = this.getItemDiscount(cartItem.productId);
			const itemDiscounted = cartItem.price - Math.fround(this.toFixedNumber(cartItem.price * itemDiscount / 100.0, 2));
			const itemDiscountedIvaAdded = itemDiscounted + Math.fround(this.toFixedNumber(itemDiscounted * cartItem.ivaPercentage / 100.0, 2));

			purchaseItems.push({
				ProductId: cartItem.productId,
				FinalPrice: this.toFixedNumber(itemDiscountedIvaAdded, 2),
				ProductOrigin: cartItem.productOrigin,
			});
		});

		let newTotalPrice = 0;
		let newTotalPriceIva = 0;

		cartItemListKeys.forEach((item) => {
			const itemDiscount = this.getItemDiscount(item.productId);
			const itemDiscounted = item.price - Math.fround(this.toFixedNumber(((item.price * itemDiscount) / 100.0), 2));
			const itemDiscountedIvaAdded = itemDiscounted + Math.fround(this.toFixedNumber(((itemDiscounted * item.ivaPercentage) / 100.0), 2));

			newTotalPrice += itemDiscounted;
			newTotalPriceIva += itemDiscountedIvaAdded;
		});

		this.setState({ totalPrice: newTotalPrice });
		this.setState({ totalPriceIva: newTotalPriceIva });
		this.setState({ cartItemListKeys, purchaseItems });
	}

	getItemDiscount(itemId) {
		const { stateDiscountData } = this.state;

		if (stateDiscountData.productsData !== undefined) {
			const isDiscounted = stateDiscountData.productsData.filter(propsItemId => propsItemId.id === itemId).length > 0;
			if (isDiscounted) {
				return stateDiscountData.discountPercentage;
			}
		}
		return 0;
	}

	setGeoData(value) {
		let {
			model,
			provinceOptions,
			comuniOptions,
			regione,
			provincia,
			comune,
		} = this.state;

		axios.get('/api/GeoDataApi/getProvinceByRegione', {
			params: {
				sigla: value.regione.sigla,
			},
		}).then((province) => {
			if (province.data) {
				provinceOptions = [];

				province.data.forEach((item) => {
					provinceOptions.push(<Option key={item.id} value={item.sigla}>{item.name}</Option>);
				});

				this.setState({ provinceOptions });

				axios.get('/api/GeoDataApi/getComuniByProvincia', {
					params: {
						sigla: value.provincia.sigla,
					},
				}).then((comuni) => {
					if (comuni.data) {
						comuniOptions = [];

						comuni.data.forEach((item) => {
							const itemValue = JSON.stringify({
								irpefCode: item.irpefCode,
								istatCode: item.istatCode,
							});

							comuniOptions.push(<Option key={item.id} value={itemValue}>{item.name}</Option>);
						});

						this.setState({ comuniOptions });

						regione = value.regione.name;
						provincia = value.provincia.name;
						comune = value.comune.name;
						model.billingInfo.istatCode = value.comune.istatCode;
						model.billingInfo.irpefCode = value.comune.irpefCode;

						this.setState({
							regione,
							provincia,
							comune,
							model,
						});
					}
				}).catch((error) => {
					errorNotification(error.response.data);
				});
			}
		}).catch((error) => {
			errorNotification(error.response.data);
		});
	}

	customFilterOptions = (inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

	toFixedNumber(num, digits) {
		const pow = 10 ** digits;
		return Math.trunc(num * pow) / pow;
	}

	diff(num1, num2) {
		if (num1 > num2) {
			return num1 - num2;
		}

		return num2 - num1;
	}


	loadUserData() {
		const { userData } = this.props;
		let {
			model,
		} = this.state;

		model.clientType = userData.clientType;

		if (userData.id > 0) {
			model.billingInfo = {
				cap: userData.billingInfo.cap,
				cf: userData.billingInfo.cf,
				cognome: userData.billingInfo.cognome,
				indirizzo: userData.billingInfo.indirizzo,
				istatCode: userData.billingInfo.istatCode,
				irpefCode: userData.billingInfo.irpefCode,
				iva: userData.billingInfo.iva,
				nCivico: userData.billingInfo.nCivico,
				nome: userData.billingInfo.nome,
				pec: userData.billingInfo.pec,
				ragioneSociale: userData.billingInfo.ragioneSociale,
				uniCode: userData.billingInfo.uniCode,
			};
		}

		this.setState({ model });

		if (model.billingInfo.istatCode !== null) {
			axios.get('/api/GeoDataApi/getGeoDataByIstat', {
				params: {
					istatCode: model.billingInfo.istatCode,
				},
			}).then(({ data }) => {
				this.setGeoData(data);
			}).catch((error) => {
				errorNotification(error.response.data);
			});
		}
	}

	completePurchase() {
		const { discountData } = this.props;
		const { model, purchaseItems, totalPriceIva } = this.state;

		const isFormValid = this.formValidation();

		if (isFormValid) {
			showLoadingOverlay();
			axios.post('/api/PurchaseApi/confirm', {

				PurchaseItems: purchaseItems,
				ExpectedPrice: this.toFixedNumber(totalPriceIva, 2),
				DiscountCode: discountData.discountCode,
				BillingInfo: {
					Nome: model.billingInfo.nome,
					Cognome: model.billingInfo.cognome,
					Cf: model.billingInfo.cf,
					RagioneSociale: model.billingInfo.ragioneSociale,
					Iva: model.billingInfo.iva,
					UniCode: model.billingInfo.uniCode,
					Pec: model.billingInfo.pec,
					Indirizzo: model.billingInfo.indirizzo,
					NCivico: model.billingInfo.nCivico,
					Cap: model.billingInfo.cap,
					IstatCode: model.billingInfo.istatCode,
					IrpefCode: model.billingInfo.irpefCode,
				},

			}).then(({ data }) => {
				if (data) {
					hideLoadingOverlay();
					window.location.href = data.redirectUrl;
				}
			}).catch((error) => {
				hideLoadingOverlay();
				errorNotification(error.response.data);
			});
		}
	}

	formValidation() {
		const { model } = this.state;

		if (model.billingInfo.nome === null) {
			errorNotification('Devi inserire i campi obbligatori: Nome');
			return false;
		}
		if (model.billingInfo.cognome === null) {
			errorNotification('Devi inserire i campi obbligatori: Cognome');
			return false;
		}
		if (model.billingInfo.cf === null) {
			errorNotification('Devi inserire i campi obbligatori: Codice Fiscale');
			return false;
		}
		if (model.billingInfo.istatCode === null) {
			errorNotification('Devi inserire i campi obbligatori: Regione, Provincia e Comune');
			return false;
		}
		if (model.clientType === 2) {
			if (model.billingInfo.ragioneSociale === null) {
				errorNotification('Devi inserire i campi obbligatori: Ragione Sociale');
				return false;
			}
			if (model.billingInfo.iva === null) {
				errorNotification('Devi inserire i campi obbligatori: Partita IVA');
				return false;
			}
			if (model.billingInfo.uniCode === null && model.billingInfo.pec === null) {
				errorNotification('Devi inserire almeno 1 dei campi obbligatori: Codice Univoco o PEC');
				return false;
			}
		}

		return true;
	}

	updateClientType(value) {
		let { model } = this.state;
		model.clientType = value.target.value;

		if (model.clientType === 1) {
			model.ragioneSociale = null;
			model.iva = null;
			model.uniCode = null;
			model.pec = null;
		}

		this.setState({ model });
	}

	updateCf(value) {
		let { model } = this.state;
		model.billingInfo.cf = value.target.value;
		this.setState({ model });
	}

	updateNome(value) {
		let { model } = this.state;
		model.billingInfo.nome = value.target.value;
		this.setState({ model });
	}

	updateCognome(value) {
		let { model } = this.state;
		model.billingInfo.cognome = value.target.value;
		this.setState({ model });
	}

	updateIva(value) {
		let { model } = this.state;
		model.billingInfo.iva = value.target.value;
		this.setState({ model });
	}

	updateUniCode(value) {
		let { model } = this.state;
		model.billingInfo.uniCode = value.target.value;
		this.setState({ model });
	}

	updatePec(value) {
		let { model } = this.state;
		model.billingInfo.pec = value.target.value;
		this.setState({ model });
	}

	updateRagioneSociale(value) {
		let { model } = this.state;
		model.billingInfo.ragioneSociale = value.target.value;
		this.setState({ model });
	}

	updateIndirizzo(value) {
		let { model } = this.state;
		model.billingInfo.indirizzo = value.target.value;
		this.setState({ model });
	}

	updateNCivico(value) {
		let { model } = this.state;
		model.billingInfo.nCivico = value.target.value;
		this.setState({ model });
	}

	updateCap(value) {
		let { model } = this.state;
		model.billingInfo.cap = value.target.value;
		this.setState({ model });
	}

	loadRegioniOptions() {
		let {
			regioniOptions,
		} = this.state;

		axios.get('/api/GeoDataApi/getRegioniByStato', {
			params: {
				isoCode: 'ITA',
			},
		}).then(({ data }) => {
			if (data) {
				regioniOptions = [];

				data.forEach((item) => {
					regioniOptions.push(<Option key={item.id} value={item.sigla}>{item.name}</Option>);
				});

				this.setState({ regioniOptions });
			}
		}).catch((error) => {
			errorNotification(error.response.data);
		});
	}

	updateProvinceRegione(value) {
		let {
			model,
			provinceOptions,
			comuniOptions,
			regione,
			provincia,
			comune,
		} = this.state;

		axios.get('/api/GeoDataApi/getProvinceByRegione', {
			params: {
				sigla: value.value,
			},
		}).then(({ data }) => {
			if (data) {
				provinceOptions = [];

				data.forEach((item) => {
					provinceOptions.push(<Option key={item.id} value={item.sigla}>{item.name}</Option>);
				});

				regione = value.label;
				provincia = '';
				comune = '';
				model.billingInfo.irpefCode = null;
				model.billingInfo.istatCode = null;

				this.setState({
					model,
					provinceOptions,
					regione,
					provincia,
					comune,
				});

				comuniOptions = [];
				this.setState({ comuniOptions });
			}
		}).catch((error) => {
			errorNotification(error.response.data);
		});
	}

	updateComuniProvincia(value) {
		let {
			model,
			comuniOptions,
			provincia,
			comune,
		} = this.state;

		axios.get('/api/GeoDataApi/getComuniByProvincia', {
			params: {
				sigla: value.value,
			},
		}).then(({ data }) => {
			if (data) {
				comuniOptions = [];

				data.forEach((item) => {
					const itemValue = JSON.stringify({
						irpefCode: item.irpefCode,
						istatCode: item.istatCode,
					});

					comuniOptions.push(<Option key={item.id} value={itemValue}>{item.name}</Option>);
				});

				provincia = value.label;
				comune = '';
				model.billingInfo.irpefCode = null;
				model.billingInfo.istatCode = null;

				this.setState({
					model,
					comuniOptions,
					provincia,
					comune,
				});
			}
		}).catch((error) => {
			errorNotification(error.response.data);
		});
	}

	updateComune(value) {
		let { comune, model } = this.state;
		comune = value.label;
		model.billingInfo.istatCode = JSON.parse(value.value).istatCode;
		model.billingInfo.irpefCode = JSON.parse(value.value).irpefCode;
		this.setState({ comune, model });
	}

	render() {
		const { totalPrice, totalPriceIva } = this.state;

		const columns = [{
			title: 'Prodotto',
			dataIndex: 'title',
			key: 'title',
			align: 'left',
		},
		{
			title: 'Prezzo',
			dataIndex: 'price',
			key: 'price',
			align: 'right',
			render: data => (<p>€ {(data).toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })}</p>),
		},
		{
			title: 'Sconto',
			dataIndex: 'sconto',
			key: 'sconto',
			align: 'right',
			render: (data, row) => {
				const itemDiscount = this.getItemDiscount(row.productId);
				return (<p>{itemDiscount} %</p>);
			},
		},
		{
			title: 'Netto',
			dataIndex: 'price',
			key: 'price',
			align: 'right',
			responsive: ['l', 'xl'],
			render: (data, row) => {
				const itemDiscount = this.getItemDiscount(row.productId);
				const itemDiscounted = data - this.toFixedNumber(((data * itemDiscount) / 100.0), 2);
				return (<p>€ {(itemDiscounted).toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })}</p>);
			},
		},
		{
			title: 'IVA',
			dataIndex: 'ivaPercentage',
			key: 'ivaPercentage',
			align: 'right',
			responsive: ['l', 'xl'],
			render: data => (<p>{data} %</p>),
		},
		{
			title: 'Totale',
			dataIndex: 'totale',
			key: 'totale',
			align: 'right',
			render: (data, row) => {
				const itemDiscount = this.getItemDiscount(row.productId);
				const itemDiscounted = row.price - this.toFixedNumber(((row.price * itemDiscount) / 100.0), 2);
				const itemDiscountedIvaAdded = itemDiscounted + this.toFixedNumber(((itemDiscounted * row.ivaPercentage) / 100.0), 2);
				return (<p>€ {(itemDiscountedIvaAdded).toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })}</p>);
			},
		}];

		const {
			model,
			cartItemListKeys,
			regione,
			provincia,
			comune,
			regioniOptions,
			provinceOptions,
			comuniOptions,
		} = this.state;

		return (
			<>
				<Form onFinish={this.completePurchase}>
					<div className="section1 row margin_lr ">
						<div className="col-md-2 col-12" />
						<div className="col-md-8 col-12 w-100 top_bar_cart justify-content-center">
							<p>Dati fatturazione</p>
						</div>
						<div className="col-md-2 col-12" />
						<div className="col-2" />
						<div className="w-100 col-8">

							<Radio.Group
								onChange={this.updateClientType}
								value={model.clientType}
							>
								<Radio value={1}>Privato</Radio>
								<Radio value={2}>Società/Soggetto IVA</Radio>
							</Radio.Group>

						</div>
						<div className="col-md-2 col-12" />
						<div style={{ height: '30px' }} />
						<div className="row d-flex-inline w-100 justify-content-center">

							<div className="col-2" />
							<div className="col-md-8 col-12 margin_lr row">
								<div className="col-md-6 col-12">
									<label className="col-12">Nome*</label>
									<Input value={model.billingInfo.nome} onChange={this.updateNome} placeholder="Nome" />
								</div>

								<div className="col-md-6 col-12">
									<label className="col-12">Cognome*</label>
									<Input value={model.billingInfo.cognome} onChange={this.updateCognome} placeholder="Cognome" />
								</div>
							</div>
							<div className="col-md-2 col-12" />
						</div>

						<div className="row d-flex-inline w-100 justify-content-center">
							<div className="col-2" />
							<div className="col-md-8 col-12 margin_lr row">
								<div className="col-md-4 col-12">
									<label className="col-12">Codice fiscale*</label>
									<Input value={model.billingInfo.cf} onChange={this.updateCf} className="col-12" maxLength={16} placeholder="Codice fiscale" />
								</div>

								<div className="col-md-4 col-12">
									<label className="col-12">
										Ragione Sociale
										{model.clientType === 2 && '*'}
									</label>
									<Input value={model.billingInfo.ragioneSociale} onChange={this.updateRagioneSociale} className="col-12" disabled={model.clientType === 1} placeholder="Ragione Sociale" />
								</div>

								<div className="col-md-4 col-12">
									<label className="col-12">
										Partita IVA
										{model.clientType === 2 && '*'}
									</label>
									<Input value={model.billingInfo.iva} onChange={this.updateIva} className="col-12" disabled={model.clientType === 1} maxLength={11} placeholder="Partita IVA" />
								</div>
							</div>
							<div className="col-2" />
						</div>

						<div className="row d-flex-inline w-100 justify-content-center">
							<div className="col-2" />
							<div className="col-md-8 col-12 margin_lr row">
								<div className="col-md-6 col-12">
									<label className="col-12">
										Codice Univoco
										{model.clientType === 2 && '**'}
									</label>
									<Input value={model.billingInfo.uniCode} onChange={this.updateUniCode} className="col-12" disabled={model.clientType === 1 || (model.billingInfo.pec !== '' && model.billingInfo.pec !== null)} placeholder="Codice Univoco" />
								</div>

								<div className="col-md-6 col-12">
									<label className="col-12">
										PEC
										{model.clientType === 2 && '**'}
									</label>
									<Input value={model.billingInfo.pec} onChange={this.updatePec} className="col-12" disabled={model.clientType === 1 || (model.billingInfo.uniCode !== '' && model.billingInfo.uniCode !== null)} placeholder="PEC" />
								</div>

								<div className="col-md-4 col-12" />
							</div>
							<div className="col-2" />
						</div>

						<div className="row d-flex-inline w-100 justify-content-center">
							<div className="col-2" />
							<div className="col-md-8 col-12 margin_lr row">
								<div className="col-md-4 col-12">
									<label className="col-12">Indirizzo*</label>
									<Input value={model.billingInfo.indirizzo} onChange={this.updateIndirizzo} className="col-12" placeholder="Indirizzo" />
								</div>

								<div className="col-md-4 col-12">
									<label className="col-12">N° civico*</label>
									<Input value={model.billingInfo.nCivico} onChange={this.updateNCivico} className="col-12" placeholder="N° civico" />
								</div>

								<div className="col-md-4 col-12">
									<label className="col-12">C.A.P.*</label>
									<Input value={model.billingInfo.cap} onChange={this.updateCap} className="col-12" placeholder="C.A.P." />
								</div>
							</div>
							<div className="col-2" />
						</div>

						<div className="row d-flex-inline w-100 justify-content-center">
							<div className="col-2" />
							<div className="col-md-8 col-12 margin_lr row">

								{regioniOptions?.length > 0 ? (

									<div className="col-md-4 col-12">
										<label className="col-12">Regione*</label>

										<Select
											className="col-12 p-0"
											autoComplete="chrome-off"
											showSearch
											placeholder="Regione"
											onChange={this.updateProvinceRegione}
											filterOption={(inputValue, option) => this.customFilterOptions(inputValue, option)}
											labelInValue
											value={regione !== '' ? { key: regione, label: regione } : undefined}
										>
											{regioniOptions}

										</Select>

									</div>

								) : (

									<div className="col-md-4 col-12">
										<label className="col-12">Regione*</label>

										<Select
											autoComplete="chrome-off"
											className="col-12 p-0"
											placeholder="Regione"
											disabled
										/>

									</div>

								)}

								{provinceOptions?.length > 0 ? (

									<div className="col-md-4 col-12">
										<label className="col-12">Provincia*</label>

										<Select
											autoComplete="chrome-off"
											className="col-12 p-0"
											showSearch
											placeholder="Provincia"
											onChange={this.updateComuniProvincia}
											filterOption={(inputValue, option) => this.customFilterOptions(inputValue, option)}
											labelInValue
											value={provincia !== '' ? { key: provincia, label: provincia } : undefined}
										>
											{provinceOptions}
										</Select>

									</div>

								) : (

									<div className="col-md-4 col-12">
										<label className="col-12">Provincia*</label>

										<Select
											autoComplete="chrome-off"
											className="col-12 p-0"
											placeholder="Provincia"
											disabled
										/>

									</div>

								)}

								{comuniOptions?.length > 0 ? (

									<div className="col-md-4 col-12">
										<label className="col-12">Comune*</label>

										<Select
											autoComplete="chrome-off"
											className="col-12 p-0"
											showSearch
											placeholder="Comune"
											onChange={this.updateComune}
											filterOption={(inputValue, option) => this.customFilterOptions(inputValue, option)}
											labelInValue
											value={comune !== '' ? { key: comune, label: comune } : undefined}
										>
											{comuniOptions}
										</Select>

									</div>

								) : (

									<div className="col-md-4 col-12">
										<label className="col-12">Comune*</label>

										<Select
											autoComplete="chrome-off"
											className="col-12 p-0"
											placeholder="Comune"
											disabled
										/>

									</div>

								)}
							</div>
							<div className="col-2" />
						</div>

						<div className="row d-flex-inline w-100 justify-content-center">
							<div className="col-2" />
							<div className="col-8">
								<span>* campi obbligatori</span>
								<br />
								<span>** 1 dei campi è obbligatorio</span>
							</div>
							<div className="col-2" />
						</div>
					</div>
					{/* padding_steps */}
					<div style={{ height: '30px' }} />
					<div className="section2 row margin_lr ">
						<div className="col-md-2 col-12" />
						<div className="col-md-8 col-12 w-100 top_bar_cart justify-content-center">
							<p>Riepilogo ordine</p>
						</div>
						<div className="col-md-2 col-12" />
						<div className="col-md-2 col-12" />
						<Table className="col-8" columns={columns} dataSource={cartItemListKeys} pagination={false} />
						<div className="col-md-2 col-12" />
						<div className="col-md-2 col-12" />
						<div className="text-right w-100 justify-content-end col-8">
							<div className="col-md-2 col-12" />
							<span>Totale Imponibile: {(totalPrice).toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })}</span>
							<br />
							<span>IVA: {(totalPriceIva - totalPrice).toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })}</span>
							<br />
							<span><strong>Totale da pagare: {(totalPriceIva).toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })}</strong></span>

						</div>
					</div>
					<div style={{ height: '30px' }} />
					<div className="section3 row margin_lr">
						<div className="col-md-2 col-12" />
						<div className="col-md-8 col-12 w-100 top_bar_cart justify-content-center">
							<p>Pagamento</p>
						</div>
						<div className="col-md-2 col-12" />
						<div className="row d-flex w-100">
							<div className="col-md-2 col-12" />
							<div className="d-inline-grid w-100 col-md-8 col-12 row justify-content-center align-items-center">
								<div className="col-md-12 col-12 p-3">
									<img className="m-2 img-fluid" height="auto" width="70" src="https://cdn.pixabay.com/photo/2015/05/26/09/37/paypal-784404_960_720.png" alt="paypal" />
									<br />
									<span className="m-2 ">È possibile pagare anche attraverso: </span>
									<br />
									<img className="m-2 img-fluid" height="auto" width="70" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/800px-Mastercard-logo.svg.png" alt="mastercard" />
									<img className="m-2 img-fluid" height="auto" width="60" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png" alt="visa" />
								</div>
								<div className="col-md-4 col-12 p-3" />
							</div>


							<div className="col-md-10 col-12 text-right">
								<button type="submit" className="btn_n">PROCEDI</button>
							</div>
						</div>
					</div>
				</Form>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.userData,
		discountData: state.discountData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			fwdPhase: bindActionCreators(fwdPhase, dispatch),
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSecondPhase);

OrderSecondPhase.propTypes = {

	cartItemList: PropTypes.arrayOf(PropTypes.object),
	userData: PropTypes.shape({
		id: PropTypes.number,
		clientType: PropTypes.number,
		email: PropTypes.string,
		billingInfo: PropTypes.shape({

			nome: PropTypes.string,
			cognome: PropTypes.string,
			cf: PropTypes.string,
			uniCode: PropTypes.string,
			iva: PropTypes.string,
			pec: PropTypes.string,
			ragioneSociale: PropTypes.string,
			indirizzo: PropTypes.string,
			nCivico: PropTypes.string,
			cap: PropTypes.string,
			istatCode: PropTypes.string,
			irpefCode: PropTypes.string,
		}),
		telefono: PropTypes.string,
		professione: PropTypes.string,
		tematicheInteresse: PropTypes.string,
		advConsent: PropTypes.bool,
		username: PropTypes.string,
		isLogged: PropTypes.bool,
	}),
	discountData: PropTypes.shape({
		productsData: PropTypes.arrayOf(PropTypes.object),
		discountPercentage: PropTypes.number,
	}),

};
OrderSecondPhase.defaultProps = {

	cartItemList: [],
	userData: {
		id: 0,
		clientType: 1,
		billingInfo: {

			nome: null,
			cognome: null,
			cf: null,
			uniCode: null,
			iva: null,
			pec: null,
			ragioneSociale: null,
			indirizzo: null,
			nCivico: null,
			cap: null,
			istatCode: null,
			irpefCode: null,
		},
		email: null,
		telefono: null,
		professione: null,
		tematicheInteresse: null,
		advConsent: false,
		username: null,
		isLogged: false,
		isLoaded: false,
	},
	discountData: {
		productsData: [],
		discountPercentage: null,
	},

};
