import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomProgressCircle from '../CustomProgressCircle';

// eslint-disable-next-line react/prefer-stateless-function
class OrderProcessBar extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const { cartPhase } = this.props;
        return (
            <>
                <div className="text-center mobile_hide" style={{ marginBottom: '40px' }}>
                    <div className="d-inline-flex">
                    <div className="d-inline-grid">
                        <CustomProgressCircle phase={1} />
                        <br />
                        <span>Riepilogo ordine</span>
                    </div>
                    <div className={cartPhase > 1 ? 'line_full' : 'line_empty'} />
                    <div className="d-inline-grid">
                        <CustomProgressCircle phase={2} />
                        <br />
                        <span>I tuoi dati</span>
                    </div>
                    <div className={cartPhase > 2 ? 'line_full' : 'line_empty'} />
                    <div className="d-inline-grid">
                        <CustomProgressCircle phase={3} />
                        <br />
                        <span>Conferma</span>
                    </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartPhase: state.cartPhase,
    };
}

export default connect(mapStateToProps)(OrderProcessBar);


OrderProcessBar.propTypes = {

    cartPhase: PropTypes.number,

};
OrderProcessBar.defaultProps = {

    cartPhase: 1,

};
