/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { notification } from 'antd';
import Skeleton from 'react-loading-skeleton';

class ArchivioAreeTematiche extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            thems: props.Thematics,
            contents: [],
            contentsLoading: true,
            pagination: {
                from: 0,
                size: 10,
                total: 0,
            },
        };
    }

    componentDidMount() {
        const { thems } = this.state;

        if (thems && thems !== '') {
            this.loadContenutiTematicaFisco(thems);
        } else {
            notification.info({
                message: 'Etichetta non trovata',
            });
        }
    }

    loadContenutiTematicaFisco(thems, fromRet = false) {
        const { pagination } = this.state;
        let { contents } = this.state;

        if (fromRet) {
            pagination.from += 10;
        }

        axios.post('/api/archive/getContentsByThems', {
            thems, pagination,
        })
        .then(({ data: { data, pagination } }) => {
            if (contents.length === 0) {
                contents = data;
            } else {
                contents = contents.concat(data);
            }
            this.setState({ contents, contentsLoading: false, pagination });
        })
        .catch((error) => {
            if (error) {
                notification.info({
                    message: `Problema caricamento contenuti. \n\n\n ${error}`,
                });
            } else {
                notification.info({
                    message: 'Problema caricamento contenuti',
                });
            }

            this.setState({ contentsLoading: false });
        });

        this.setState({ contentsLoading: true });
    }

    render() {
        const {
            thems,
            contentsLoading,
            contents,
            pagination,
        } = this.state;

        let title = `Archivio contenuti relativi alla tematica <u>${thems[0]}</u>`;
        if (thems.length > 1) {
            title = 'Archivio contenuti relativi alle tematiche ';
            thems.forEach((them, index) => {
                if (index === 0) {
                    title += `<u>${them}</u>,`;
                } else if (index === thems.length - 1) {
                    title += ` <u>${them}</u>`;
                } else {
                    title += ` <u>${them}</u>,`;
                }
            });
        }

        return (
            <div>
                {contentsLoading && (
                    <div className="row">
                        <div className="col-12" style={{ minHeight: '250px' }}>
                            <Skeleton />
                        </div>
                    </div>
                )}

                {!contentsLoading && contents && contents.length > 0 && (
                    <>
                        <div className="row  margin_lr">
                            <div className="col-12 content_title">
                                <h4 dangerouslySetInnerHTML={{ __html: title }} />
                            </div>
                        </div>
                        <div className="row  margin_lr">
                            <div className="col-12 content_list_container">
                                {contents.map((value) => {
                                    let contentUrl = '';
                                    const contentOf = 'di ';
                                    if (value !== undefined) {
                                        switch (value.typeDoc) {
                                            case 'articolo':
                                            case 'articoli':
                                                contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}/?from=archivio`;
                                                break;
                                            case 'news':
                                                contentUrl = `/News/Details/${value.id}/${value.niceUrl}/?from=archivio`;
                                                break;
                                            case 'video':
                                                contentUrl = `/Video/Details/${value.id}/${value.niceUrl}/?from=archivio`;
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    return (
                                        <div key={value.id} style={{ width: '100%' }}>
                                            <div className="content_list_item m-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-lg-2 content_list_item_img">
                                                        <a href={contentUrl}><img src={value.image} alt="item" className="img-fluid" /></a>
                                                    </div>
                                                    <div className="col-12 col-md-8 col-lg-10 content_list_item_txt">
                                                        {(value.thematicCategories || value.thematics) && (
                                                            value.thematicCategories && value.thematicCategories.length > 0 ? (
                                                                value.thematicCategories.map((thematic, index) => (
                                                                    <span key={index} className="content_them">{thematic}</span>
                                                                ))
                                                            ) : (
                                                                value.thematics && value.thematics.map((thematic, index) => (
                                                                    <span key={index} className="content_them">{thematic}</span>
                                                                ))
                                                        ))}
                                                        <a href={contentUrl}><h4>{value.title}</h4></a>
                                                        <p dangerouslySetInnerHTML={{ __html: `${value.shortDescription.substring(0, 300)}...` }} />
                                                        <small>
                                                            <span className="article_of">{contentOf}</span>
                                                            {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                                <span key={index}>{`${author}, `}</span>
                                                            ))}
                                                            <span>{value.dateString}</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {pagination.total > contents.length && (
                            <div className="row  margin_lr">
                                <div className="col-12 show_more_btn_container">
                                    <a href={null} className="btn show_more_btn" onClick={() => this.loadContenutiTematicaFisco(thems, true)}>Carica altri</a>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

ArchivioAreeTematiche.propTypes = {
    Thematics: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArchivioAreeTematiche;
