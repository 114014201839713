/* eslint-disable prefer-const */
import React from 'react';
import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';


// eslint-disable-next-line react/prefer-stateless-function
class ProfileModifierContainer extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

        this.state = {
            collapseShow: false,
            cittaFromIstat: '',
            geoLoaded: false,
        };

        this.showCollapse = this.showCollapse.bind(this);
    }

    showCollapse() {
        const { collapseShow } = this.state;
        this.setState({ collapseShow: !collapseShow });
    }

    loadGeoData() {
        const { userData } = this.props;

        if (userData.billingInfo.istatCode !== '') {
            axios.get('/api/GeoDataApi/getGeoDataByIstat', {
                params: {
                    istatCode: userData.billingInfo.istatCode,
                },
            }).then(({ data }) => {
                this.setState({ geoLoaded: true, cittaFromIstat: data.comune.name });
            });
        }
    }

    render() {
        const { collapseShow, cittaFromIstat, geoLoaded } = this.state;
        const { userData } = this.props;

        if (userData && !geoLoaded) {
            this.loadGeoData();
        }

        return (
            <>
                <div className="row margin_lr" style={{ marginBottom: '10px' }}>
                    <div className="col-md-2 padding_lr" />
                    <div className="col-md-8 padding_lr">
                        <div className="d-flex align-items-center mt-3">
                            <img className="img-fluid header_icon" src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-header.png" alt="profilePic" />
                            <h1 style={{ margin: 0 }}>
                                &nbsp;
                                &nbsp;
                                {`${userData.billingInfo.nome} ${userData.billingInfo.cognome}`}
                            </h1>
                        </div>
                        <br />
                        <a className="" onClick={this.showCollapse}>Visualizza Profilo</a>
                        <hr className="hr_profilo" />

                        <Collapse style={{ display: 'grid' }} isOpened={collapseShow}>
                            <div className="view_profile">
                                <span>Nome: </span>
                                <strong>{userData.billingInfo.nome}</strong>
                                <br />
                                <span>Cognome: </span>
                                <strong>{userData.billingInfo.cognome}</strong>
                                <br />
                                <span>E-mail: </span>
                                <strong>{userData.email}</strong>
                                <br />
                                <span>Codice Fiscale: </span>
                                <strong>{userData.billingInfo.cf}</strong>
                                <br />
                                {userData.clientType === 2
                                    && (
                                        <>
                                            <span>Ragione Sociale: </span>
                                            <strong>{userData.billingInfo.ragioneSociale}</strong>
                                            <br />
                                            <span>Partita IVA: </span>
                                            <strong>{userData.billingInfo.iva}</strong>
                                            <br />
                                            <span>Codice Univoco: </span>
                                            <strong>{userData.billingInfo.uniCode}</strong>
                                            <br />
                                            <span>PEC: </span>
                                            <strong>{userData.billingInfo.pec}</strong>
                                            <br />
                                        </>
                                    )}

                                <span>Residenza: </span>
                                {(userData.billingInfo.nCivico !== null || userData.billingInfo.nCivico !== null || userData.billingInfo.cap !== null) && (
                                    <strong>{`${userData.billingInfo.indirizzo ?? ''} ${userData.billingInfo.nCivico ?? ''} ${userData.billingInfo.cap ?? ''}`}</strong>
                                )}
                                <br />
                                <span>Città: </span>
                                <strong>{cittaFromIstat}</strong>
                                <br />
                                <span>Telefono: </span>
                                <strong>{userData.telefono}</strong>
                                <br />
                                <span>Professione: </span>
                                <strong>{userData.professione}</strong>
                                <br />
                                <span>Tematiche di interesse: </span>
                                <strong>{userData.tematicheInteresse}</strong>
                                <br />
                                <div style={{ height: '30px' }} />
                                <a style={{ fontWeight: '600' }} href="Account/Edit">
                                    <i className="fa fa-pencil" aria-hidden="true" />
                                    &nbsp;Modifica Profilo
                                </a>
                            </div>
                        </Collapse>
                    </div>
                    <div className="col-md-2 col-12" />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

export default connect(mapStateToProps)(ProfileModifierContainer);


ProfileModifierContainer.propTypes = {

    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};
ProfileModifierContainer.defaultProps = {

    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: null,
            cognome: null,
            cf: null,
            uniCode: null,
            iva: null,
            pec: null,
            ragioneSociale: null,
            indirizzo: null,
            nCivico: null,
            cap: null,
            istatCode: null,
        },
        email: null,
        telefono: null,
        professione: null,
        tematicheInteresse: null,
        advConsent: false,
        username: null,
        isLogged: false,
        isLoaded: false,
    },
};
