/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from '../../redux/store/configureStore';

const store = configureStore();

export default function render(component, domId) {
  const domNode = document.getElementById(domId);
  if (domNode) {
    ReactDOM.render(
      <Provider store={store}>
        {component}
      </Provider>,
      domNode,
    );
  }
}
