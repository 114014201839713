/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addCartItem } from '../../../common/redux/actions';
import BannerCategoryContainer from '../../common/BannerCategoryContainer';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { errorNotification } from '../../../common/Notification';


class ProductDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				category: null,
				descriptionHtml: null,
				testoProdotto: null,
				productId: 0,
				imgUrl: null,
				isBought: false,
				ivaPercentage: 0,
				linkShop: null,
				macroCategory: null,
				price: 0,
				title: null,
				productOrigin: null,
			},
		};

		this.addItemToCart = this.addItemToCart.bind(this);
		this.loadProductInfo = this.loadProductInfo.bind(this);
	}

	componentDidMount() {
		this.loadProductInfo();
	}

	addItemToCart(item, userId) {
		const { actions } = this.props;
		actions.addCartItem(item, userId);
	}

	loadProductInfo() {
		const productID = new URLSearchParams(window.location.search).get('id');
		const productOrigin = new URLSearchParams(window.location.search).get('productOrigin');

		showLoadingOverlay();
		axios.get('/api/ShopApi/getProductDetails', {
			params: {
				productId: productID,
				clientOrigin: productOrigin,
			},
		}).then(({ data }) => {
			hideLoadingOverlay();
			let { model } = this.state;
			model = data;
			this.setState({ model });
		}).catch((error) => {
			hideLoadingOverlay();
			errorNotification(error.response.data);
		});
	}

	render() {
		const { model } = this.state;
		const { userData } = this.props;

		return (
			<>
				<BannerCategoryContainer bannerName="Dettaglio Prodotto" bgColor="#C8F246" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_catalogo-header.png" />

				<div className="row margin_lr justify-content-center p-0">
					<div className="col-md-3 col-12 padding_lr">
						<img className="w-100" src={model.imgUrl} alt="Prodotto" />
					</div>
					<div className="col-md-4 col-12 description_panel">
						<div className="title_product">
							{model.title}
						</div>
						<br />
						<div className="subtitle_product">
							<strong>{model.macroCategory}</strong>
							<br />
							{model.category}
						</div>
					</div>
					<div className="col-md-1 col-12 action_panel">
						{model.price > 0 ? (

							<div className="product_price text-right">
								<h4>{(model.price).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h4>
								<p>+ Iva {model.ivaPercentage}%</p>
							</div>
						) : (
							<></>
						)}

						{!model.isBought ? (
							model.price > 0 ? (
								<div className="col-6">
									<a className="btn_acquista" onClick={() => this.addItemToCart(model, userData.id)}>Acquista</a>
								</div>
							) : (
								model.macroCategory !== 'Business Plan' ? (
									<div className="col-6">
										<a className="btn_acquista" onClick={() => this.addItemToCart(model, userData.id)}>Attiva</a>
									</div>
								) : (
									<div className="col-6">
										<a className="btn_acquista" href="/BpDemo">Attiva</a>
									</div>
								)
							)
						) : (
							model.macroCategory !== 'Dichiarazione Aiuti di Stato' && (
								<div className="col-6">
									<a className="btn_acquista" href={model.linkShop}>Accedi</a>
								</div>
							)
						)}

					</div>
					<div className="row margin_lr justify-content-center p-0">
						<div className="col-md-8 col-10">

							<hr />
							<div dangerouslySetInnerHTML={{ __html: model.descriptionHtml }} />
							<div dangerouslySetInnerHTML={{ __html: model.testoProdotto }} />

						</div>
					</div>
					<div className="col-md-8 col-12 py-3">
						<a href="/shop"> &#60; Torna indietro </a>
					</div>
				</div>

			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.userData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			addCartItem: bindActionCreators(addCartItem, dispatch),
		},
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
