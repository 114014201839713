/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from '@brainhubeu/react-carousel';

import axios from 'axios';
import { sponsorsContentApiEndpoint } from '../../variables';


export default class SponsorsContainer extends Component {
  static get propTypes() {
    return {
    };
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      slides: [],
    };
  }

  componentDidMount() {
    this.onLoadSponsors();
  }

  onLoadSponsors() {
    axios.get(`${sponsorsContentApiEndpoint}`)
    .then(({ data }) => {
        this.setState({ loading: true }, () => {
          const slides = [];
          data.forEach((value, i) => {
            if (value) {
              let index = i;
              index++;
              slides.push(<img key={index} alt="logo" src={value.image} className="img-fluid" style={{ padding: '15px' }} />);
            }
          });

          this.setState({ slides });
        });
      }).catch(() => {
        this.setState({ loading: false });
    });
  }

  render() {
    const { loading, slides } = this.state;
    return (
      <div>
        {loading
       ? (
          <div>
            <div className="articles_container">
              {slides && slides.length > 0 && (
                  <Carousel
                    slidesPerPage={5}
                    slidesPerScroll={2}
                    infinite
                    clickToChange
                    centered
                    autoPlay={5000}
                    animationSpeed={4000}
                    arrows
                    breakpoints={{
                      1000: { // these props will be applied when screen width is less than 1000px
                        slidesPerPage: 2,
                        clickToChange: false,
                        centered: false,
                        arrows: true,
                        infinite: false,
                      },
                      500: {
                        slidesPerPage: 1,
                        slidesPerScroll: 1,
                        clickToChange: false,
                        centered: false,
                        // arrowLeft: (<Icon className="icon-example" name="arrow-left" />),
                        // arrowRight: (<Icon className="icon-example" name="arrow-right" />),
                        arrows: true,
                        animationSpeed: 4000,
                        infinite: false,
                      },
                    }}
                   slides={slides}
                  />
              )}
            </div>
          </div>
        )
       : null }
      </div>
    );
  }
}
