/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';

// functional component(pure component) using destructuring { }, implicit return and default value
const GuideGlobalSearchResultItem = ({ item }) => {
	let body = item.bodyRichText;
	let { title } = item;
	if (item.highlight) {
		for (const [index, node] of item.highlight.entries()) {
			if (item.highlight[index].field === 'Titolo') {
				title = node.hint[0];
			}
			if (item.highlight[index].field === 'Abstract' || item.highlight[index].field === 'Contenuto') {
				body = node.hint[0];
			}
		}
	}
	return (
		<div>
			{item.VisibleInFront
				? (
				<div className="item-search-result" data-editionid={item.idEdition} data-nodeid={item.id}>
					<div className="row">
						<div className="col-xs-12 col-sm-3 col-md-2">
							{/* immagine copertina dell'edizione */}
							<a href={`/Contenuti/Guida/Index/?id=${item.idEdition}`} title={item.titleEdition}><img alt={item.titleEdition} src={item.immagineMedia} className="img-responsive item-search-result-image" /></a>
						</div>
						<div className="col-xs-12 col-sm-9 col-md-10">
							<a href={`/Contenuti/Guida/Index/?id=${item.idEdition}`} className="item-search-result-title-ed">{item.titleEdition}</a>
							<a href={`/Contenuti/Guida/Paragrafo/?id=${item.id}&eId=${item.idEdition}&gId=${item.idGuida}`} className="item-search-result-title-par" dangerouslySetInnerHTML={{ __html: title }} />
							<div dangerouslySetInnerHTML={{ __html: body }} className="item-search-result-body" />
						</div>
					</div>
				</div>
				) : null }
		</div>
	);
};

GuideGlobalSearchResultItem.propTypes = {
	item: PropTypes.shape(),
};

GuideGlobalSearchResultItem.defaultProps = {
	item: {},
};

export default GuideGlobalSearchResultItem;
