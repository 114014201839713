import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class GuideBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enableCapitoli: false,
			enableParagrafo: false,
			arrayParti: [],
			arrayCapitoli: [],
			arrayParagrafo: [],
			capitoloId: 0,
		};
	}

	componentDidMount() {
		this.setVisibility();
		this.setSelectedElements();
	}

	setVisibility() {
		const { Tipology } = this.props;
		if (Tipology !== undefined) {
			if (Tipology === 'CAPITOLO') {
				this.setState({ enableCapitoli: true });
			} else 	if (Tipology === 'PARAGRAFO') {
				this.setState({ enableParagrafo: true, enableCapitoli: true });
			}
		}
	}

	setSelectedElements() {
		const {
			Data,
			Tipology,
			ElementId,
			setArrayResult,
		} = this.props;
		const arr = Data.nodes;
		const arrayParti = [];
		const arrayCapitoli = [];
		const arrayParagrafo = [];
		let arrayResult = [];
		let parteTitle = '';
		let isSelected = false;
		let capitoloId = 0;
		let capitoloNum = 0;
		let parteId = 0;
		let parteNum = '';
		let idPrev = 0;
		let idSucc = 0;
		if (Tipology !== undefined) {
			if (Tipology === 'PARTE') {
				arr.forEach((element) => {
					if (element.tipo === Tipology.toLowerCase()) {
						const parte = {};
						parte.id = element.id;
						parte.num = element.numero;
						if (element.id === ElementId) {
							parte.selected = true;
							isSelected = true;
							arrayResult = element.nodes;
							parteTitle = element.titolo;
						}
						arrayParti.push(parte);
					}
				});
			}
			if (Tipology === 'CAPITOLO') {
				arr.forEach((element) => {
					if (element.tipo === 'parte') {
						const parte = {};
						parte.id = element.id;
						parte.num = element.numero;
						const caps = element.nodes;
						if (caps !== undefined) {
							caps.forEach((child) => {
								const capitolo = {};
								capitolo.id = child.id;
								capitolo.num = child.order;
								if (child.id === ElementId) {
									capitolo.selected = true;
									isSelected = true;
									arrayResult = child.nodes;
									parteTitle = child.titolo;
									parte.selected = true;
									capitoloId = capitolo.id;
								}
								arrayCapitoli.push(capitolo);
							});
						}
						arrayParti.push(parte);
					}
				});
			}
			if (Tipology === 'PARAGRAFO') {
				arr.forEach((element) => {
					if (element.tipo === 'parte') {
						const parte = {};
						parte.id = element.id;
						parte.num = element.numero;
						const caps = element.nodes;
						if (caps !== undefined) {
							caps.forEach((child) => {
								const capitolo = {};
								capitolo.id = child.id;
								capitolo.num = child.order;
								const parags = child.nodes;
								if (parags !== undefined) {
									parags.forEach((par) => {
										if (par.id === ElementId) {
											capitolo.selected = true;
											isSelected = true;
											parteTitle = par.titolo;
											parte.selected = true;
											parteId = parte.id;
											parteNum = parte.num;
											capitoloId = capitolo.id;
											capitoloNum = capitolo.num;
											let i;
											const tot = parags.length;
											for (i = 0; i < tot; i++) {
												const paragrafo = {};
												paragrafo.id = parags[i].id;
												paragrafo.num = parags[i].order;
												if (parags[i].id === ElementId) {
													paragrafo.selected = true;
													if (i !== 0) {
														idPrev = parags[i - 1].id;
													}
													if (i !== (tot - 1)) {
														idSucc = parags[i + 1].id;
													}
												}
												arrayParagrafo.push(paragrafo);
											}
										}
									});
								}
								arrayCapitoli.push(capitolo);
							});
						}
						arrayParti.push(parte);
					}
				});
			}
		}
		if (isSelected) {
			this.setState({
				arrayParti,
				arrayCapitoli,
				arrayParagrafo,
				capitoloId,
			});
			setArrayResult(arrayResult, parteTitle, capitoloId, capitoloNum, parteId, parteNum, idPrev, idSucc);
		}
	}

	render() {
		const {
			arrayParti,
			enableCapitoli,
			arrayCapitoli,
			enableParagrafo,
			arrayParagrafo,
			capitoloId,
		} = this.state;
		const {
			idEdition,
			idGuida,
			ElementId,
		} = this.props;
		return (
			<div className="row padded">
				<div className="col-sm-9">
					<div className="breadcrumb-full breadcrumb-full-top" id="js-breadcrumb-guide">
						<div className="breadcrumb-full-heading">PARTE</div>
						<ul>
							{ arrayParti.map(node => (
								<li key={node.id}>
									{ node.selected
										? <span className="active">{node.num}</span>
                                : <a className="js-tooltip" href={`/Guide/Parte/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`}>{node.num}</a> }
								</li>
                         ))}
						</ul>
					</div>
					{enableCapitoli
						? (
						<div className="breadcrumb-full">
							<hr className="hr-soft-gray" />
							<div className="breadcrumb-full-heading">CAPITOLO</div>
							<ul>
								{ arrayCapitoli.map(node => (
									<li key={node.id}>
										{ node.selected
											? <span className="active">{node.num}</span>
                                : <a className="js-tooltip" href={`/Guide/Capitolo/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`}>{node.num}</a> }
									</li>
                         ))}
							</ul>
						</div>
						) : null}
					{enableParagrafo
						? (
						<div className="breadcrumb-full breadcrumb-full-bottom">
							<hr className="hr-soft-gray" />
							<div className="breadcrumb-full-heading">PARAGRAFO</div>
							<ul>
								{ arrayParagrafo.map(node => (
									<li key={node.id}>
										{ node.selected
											? <span className="active">{node.num}</span>
                                : <a className="js-tooltip" href={`/Guide/Paragrafo/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`}>{node.num}</a> }
									</li>
                         ))}
							</ul>
						</div>
						) : null}
				</div>
				{enableCapitoli && !enableParagrafo
					? (
					<div className="col-sm-3">
						<div className="print-btn-group">
							<a href={`/Guide/Capitolo/?id=${capitoloId}&eId=${idEdition}&gId=${idGuida}&print=1`} className="btn btn-guide-default js-btn-print">Stampa Capitolo</a>
							<br />
						</div>
					</div>
						) : null}
				{enableCapitoli && enableParagrafo
					? (
					<div className="col-sm-3">
						<div className="print-btn-group">
							<a href={`/Guide/Capitolo/?id=${capitoloId}&eId=${idEdition}&gId=${idGuida}&print=1`} className="btn btn-guide-default">Stampa Capitolo</a>
							<a href={`/Guide/Paragrafo/?id=${ElementId}&eId=${idEdition}&gId=${idGuida}&print=1`} className="btn btn-guide-default js-btn-print-par">Stampa Paragrafo</a>
						</div>
					</div>
					) : null}

			</div>
		);
	}
}

GuideBreadcrumb.propTypes = {
	Tipology: PropTypes.string.isRequired,
	ElementId: PropTypes.number.isRequired,
	Data: PropTypes.shape(),
	setArrayResult: PropTypes.func.isRequired,
	idEdition: PropTypes.number.isRequired,
	idGuida: PropTypes.number.isRequired,
};

GuideBreadcrumb.defaultProps = {
	Data: {},
};
