/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import GuideSearchResultHeadline from '../GuideSearchResultHeadline';
import GuideSearchResultItem from '../GuideSearchResultItem';
import GuideGlobalSearchResultItem from '../GuideGlobalSearchResultItem';

// functional component(pure component) using destructuring { }, implicit return and default value
const GuideSearchResultList = ({
	items,
	term,
	total,
	editionTitle,
	idEdition,
	idGuida,
	isGlobal,
}) => (
	<div>
		<GuideSearchResultHeadline total={total} term={term} />
		{isGlobal === 'false'
			? (
				<div className="p-5">
					<div dangerouslySetInnerHTML={{ __html: editionTitle }} className="title-edizione" />
					{items.map((item, index) => (
						<GuideSearchResultItem key={`${item.id}_${index}`} item={item} idEdition={idEdition} idGuida={idGuida} />
					), this)}
				</div>
			) : (
				<div className="p-5">
					{items.map((item, index) => <GuideGlobalSearchResultItem key={`${item.id}_${index}`} item={item} />, this)}
				</div>
			)}
	</div>
);

GuideSearchResultList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	term: PropTypes.string,
	total: PropTypes.number,
	editionTitle: PropTypes.string,
	idEdition: PropTypes.number.isRequired,
	idGuida: PropTypes.number.isRequired,
	isGlobal: PropTypes.string,
};

GuideSearchResultList.defaultProps = {
	items: {},
	term: '',
	total: 0,
	editionTitle: '',
	isGlobal: '',
};


export default GuideSearchResultList;
