/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import sanitizeHtml from 'sanitize-html';

const EcommerceCollapse = ({ isOpened, FADs }) => (
    <div>
        <div className="auth-box-full row " style={{ marginRight: '15px' }} key={FADs[1].Id}>
            <Collapse isOpened={isOpened}>
                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '7px',
              }}>
                    <img src={FADs[1].ImmagineDettaglio} alt="" className="img-responsive" role="presentation" />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5%' }}>
                        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{FADs[1].nome}</p>
                        <p dangerouslySetInnerHTML={{ __html: sanitizeHtml((FADs[1].Descrizione && FADs[1].Descrizione !== '' && FADs[1].Descrizione !== undefined && FADs[1].Descrizione !== null) ? FADs[1].Descrizione : FADs[1].TestoProdotto, { allowedTags: ['a', 'b', 'i', 'u'], allowedAttributes: { 'a': ['href', 'target'] } }) }} />
                    </div>
                </div>
            </Collapse>
        </div>
    </div>
);

EcommerceCollapse.propTypes = {
    isOpened: PropTypes.bool,
    FADs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EcommerceCollapse.defaultProps = {
    isOpened: 0,
};

export default EcommerceCollapse;
