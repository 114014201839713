/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const PaperCoreContents = ({ ArticoliNewsPrimoPiano }) => {
    let contentUrl0 = '';
    if (ArticoliNewsPrimoPiano[0] !== undefined) {
        switch (ArticoliNewsPrimoPiano[0].typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl0 = `/Articles/Details/${ArticoliNewsPrimoPiano[0].id}/${ArticoliNewsPrimoPiano[0].niceUrl}/?from=paper`;
                break;
            case 'news':
                contentUrl0 = `/News/Details/${ArticoliNewsPrimoPiano[0].id}/${ArticoliNewsPrimoPiano[0].niceUrl}/?from=paper`;
                break;
            case 'video':
                contentUrl0 = `/Video/Details/${ArticoliNewsPrimoPiano[0].id}/${ArticoliNewsPrimoPiano[0].niceUrl}/?from=paper`;
                break;
            default:
                break;
        }
    }
    let contentUrl1 = '';
    if (ArticoliNewsPrimoPiano[1] !== undefined) {
        switch (ArticoliNewsPrimoPiano[1].typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl1 = `/Articles/Details/${ArticoliNewsPrimoPiano[1].id}/${ArticoliNewsPrimoPiano[1].niceUrl}/?from=paper`;
                break;
            case 'news':
                contentUrl1 = `/News/Details/${ArticoliNewsPrimoPiano[1].id}/${ArticoliNewsPrimoPiano[1].niceUrl}/?from=paper`;
                break;
            case 'video':
                contentUrl1 = `/Video/Details/${ArticoliNewsPrimoPiano[1].id}/${ArticoliNewsPrimoPiano[1].niceUrl}/?from=paper`;
                break;
            default:
                break;
        }
    }
    let contentUrl2 = '';
    if (ArticoliNewsPrimoPiano[2] !== undefined) {
        switch (ArticoliNewsPrimoPiano[2].typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl2 = `/Articles/Details/${ArticoliNewsPrimoPiano[2].id}/${ArticoliNewsPrimoPiano[2].niceUrl}/?from=paper`;
                break;
            case 'news':
                contentUrl2 = `/News/Details/${ArticoliNewsPrimoPiano[2].id}/${ArticoliNewsPrimoPiano[2].niceUrl}/?from=paper`;
                break;
            case 'video':
                contentUrl2 = `/Video/Details/${ArticoliNewsPrimoPiano[2].id}/${ArticoliNewsPrimoPiano[2].niceUrl}/?from=paper`;
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="row inevidence_news_articles">
                <div className="col-12 col-lg-6">
                    <div className="firstappareance_item_image">
                        <a href={contentUrl0}><img className="img-fluid" alt="in evidenza" src={ArticoliNewsPrimoPiano[0].image} /></a>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="firstappareance_item_content">
                        <small>IN PRIMO PIANO</small>
                        <a href={contentUrl0}><h4>{ArticoliNewsPrimoPiano[0].title}</h4></a>
                        <span dangerouslySetInnerHTML={{ __html: `${ArticoliNewsPrimoPiano[0].shortDescription.substring(0, 140)}...` }} />
                        <div className="other_box_author">
                            <span>
                                {/* <PersonIcon /> */}
                                <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                &nbsp;&nbsp;
                                <span className="article_of">di&nbsp;</span>
                                {ArticoliNewsPrimoPiano[0].authorsNames !== undefined && ArticoliNewsPrimoPiano[0].authorsNames.map((author, index) => (
                                <span key={index}>{`${author} `}</span>
                                ))}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5 appareance_item_container">
                <div className="col-12 col-lg-6">
                    {ArticoliNewsPrimoPiano[1] && (
                        <div className="appareance_item_content">
                            {(ArticoliNewsPrimoPiano[1].thematicCategories || ArticoliNewsPrimoPiano[1].thematics) && (
                            ArticoliNewsPrimoPiano[1].thematicCategories.length > 0 ? (
                                ArticoliNewsPrimoPiano[1].thematicCategories.map((thematic, index) => (
                                    <span key={index} className="them_item_appareance">{thematic}</span>
                                ))
                            ) : (
                                ArticoliNewsPrimoPiano[1].thematics.map((thematic, index) => (
                                    <span key={index} className="them_item_appareance">{thematic}</span>
                                ))
                            ))}
                            <a href={contentUrl1}><h4>{ArticoliNewsPrimoPiano[1].title}</h4></a>
                            <span dangerouslySetInnerHTML={{ __html: `${ArticoliNewsPrimoPiano[1].shortDescription.substring(0, 140)}...` }} />
                            <div className="other_box_author">
                                <span>
                                    {/* <PersonIcon /> */}
                                    <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                    &nbsp;&nbsp;
                                    <span className="article_of">di&nbsp;</span>
                                    {ArticoliNewsPrimoPiano[1].authorsNames !== undefined && ArticoliNewsPrimoPiano[1].authorsNames.map((author, index) => (
                                        <span key={index}>{`${author} `}</span>
                                    ))}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12 col-lg-6">
                    {ArticoliNewsPrimoPiano[2] && (
                        <div className="appareance_item_content">
                            {(ArticoliNewsPrimoPiano[2].thematicCategories || ArticoliNewsPrimoPiano[2].thematics) && (
                            ArticoliNewsPrimoPiano[2].thematicCategories.length > 0 ? (
                                ArticoliNewsPrimoPiano[2].thematicCategories.map((thematic, index) => (
                                    <span key={index} className="them_item_appareance">{thematic}</span>
                                ))
                            ) : (
                                ArticoliNewsPrimoPiano[2].thematics.map((thematic, index) => (
                                    <span key={index} className="them_item_appareance">{thematic}</span>
                                ))
                            ))}
                            <a href={contentUrl2}><h4>{ArticoliNewsPrimoPiano[2].title}</h4></a>
                            <span dangerouslySetInnerHTML={{ __html: `${ArticoliNewsPrimoPiano[2].shortDescription.substring(0, 150)}...` }} />
                            <div className="other_box_author">
                                <span>
                                    {/* <PersonIcon /> */}
                                    <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                    &nbsp;&nbsp;
                                    <span className="article_of">di&nbsp;</span>
                                    {ArticoliNewsPrimoPiano[2].authorsNames !== undefined && ArticoliNewsPrimoPiano[2].authorsNames.map((author, index) => (
                                        <span key={index}>{`${author} `}</span>
                                    ))}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

PaperCoreContents.propTypes = {
    ArticoliNewsPrimoPiano: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PaperCoreContents;
