import React from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


// eslint-disable-next-line react/prefer-stateless-function
class CustomProgressCircle extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const { phase, cartPhase } = this.props;

        return (
            <>
                <Progress className={`progressCustom ${phase <= cartPhase ? 'ant-prog-done' : 'ant-prog-todo'}`} type="circle" width={40} percent={phase <= cartPhase ? 100 : 0} strokeWidth={10} strokeColor="#787878" format={() => ' '} />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartPhase: state.cartPhase,
    };
}

export default connect(mapStateToProps)(CustomProgressCircle);


CustomProgressCircle.propTypes = {

    phase: PropTypes.number,
    cartPhase: PropTypes.number,

};
CustomProgressCircle.defaultProps = {

    phase: 1,
    cartPhase: 1,

};
