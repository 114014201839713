/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Banner from '../../../common/BannerCategoryContainer';
import ProfileForm from '../../../common/ProfileFormContainer';


class EditContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Banner bannerName="IL TUO PROFILO" bgColor="#FFFFFF" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-header.png" />
                <div className="row margin_lr">
                <div className="col-md-2 col-12" />
                    <div className="col-md-10 col-12 d-flex flex-column justify-content-center align-items-start">
                        <span><strong>VUOI MODIFICARE I TUOI DATI?</strong></span>
                        <span>Verifica i tuoi dati e accedi ai servizi Directio</span>
                    </div>

                </div>

                <ProfileForm formType="Edit" />

                <div className="col-md-3 col-12 btn_torna">
                    <a href="/">&#60; Torna indietro</a>
                </div>

            </>
        );
    }
}

export default EditContainer;
