import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setMacroCategory, setCategory } from '../../../common/redux/actions';

const TreeMenu = (props) => {
    const dispatch = useDispatch();
    const shopFilter = useSelector(state => state.shopFilter);
    const { itemList } = props;

    const updateProductsByMacroCategory = (macroCategoryData) => {
        const filterOption = {
            type: 'macroCategory',
            category: macroCategoryData,
        };

        dispatch(setMacroCategory(filterOption));
    };

    const updateProductsByCategory = (categoryData) => {
        const filterOption = {
            type: 'category',
            category: categoryData,
        };

        dispatch(setCategory(filterOption));
    };

    const resetFilterOptions = () => {
        const filterOption = {
            type: '',
            category: '',
        };

        dispatch(setCategory(filterOption));
    };

    return (
        <>
            <ul className="nav_tree">
                <li className="nav_tree">
                    <ul className="nav_tree" key={-1}>
                        <a className={(shopFilter.type === '') ? 'text-dark font-weight-bold' : ''} onClick={() => resetFilterOptions()}>Tutti i prodotti</a>
                        <li className="nav_tree" />
                    </ul>
                    {itemList.length > 0 && (
                        itemList.map(item => (
                            <ul className="nav_tree" key={item.id}>
                                <a className={(shopFilter.category === item.name) ? 'text-dark font-weight-bold' : ''} onClick={() => updateProductsByMacroCategory(item.name, item.id)}>{item.name}</a>
                                {item.subItems !== undefined && (
                                    item.subItems.map(subItem => (
                                        <li className="nav_tree" key={subItem.id}>
                                            <a className={(shopFilter.category === subItem.value) ? 'text-dark font-weight-bold' : ''} onClick={() => updateProductsByCategory(subItem.value, subItem.id)}>{subItem.value}</a>
                                        </li>
                                    )))
                                }
                            </ul>
                        )))
                    }

                </li>
            </ul>
        </>
    );
};

TreeMenu.propTypes = {
    itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TreeMenu;
