/* eslint-disable prefer-destructuring */
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';

import GuideSearchResultListContainer from '../../../components/frontend/guide/GuideSearchResultListContainer/GuideSearchResultListContainer';


export default function setupSearch() {
	const gid = __globalJsData.idGuida;
	const eid = __globalJsData.idEdition;
	const cid = __globalJsData.idComunita;
	const {
		editionTitle,
	} = __globalJsData;
	let initialTerm = '';
	let isGlobal = '';
	const GetParams = [];
	const prmstr = window.location.search.substr(1);
	const prmarr = prmstr.split('&');
	const prmarrLen = prmarr.length;
	let tmparr = [];
	let i = 0;
	for (i = 0; i < prmarrLen; i++) {
		tmparr = prmarr[i].split('=');
		GetParams[tmparr[0]] = tmparr[1];
	}

	if (GetParams.q) {
		initialTerm = decodeURIComponent(GetParams.q);
	}

	if (GetParams.isGlobal) {
		isGlobal = decodeURIComponent(GetParams.isGlobal);
	}

	// do render only if the dom handle is on page
	const searchResults = document.getElementById('js-react-searchresults');
	if (searchResults) {
		ReactDOM.render(<GuideSearchResultListContainer editionTitle={editionTitle} searchTerms={initialTerm} idGuida={gid} idEdition={eid} idComunita={cid} isGlobal={isGlobal} />, searchResults);
	}
}
