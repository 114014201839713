import React from 'react';
import reactDom from '../../../common/react/dom/index';
import PaperContainer from '../../../components/frontend/paperhome/PaperContainer';

const t = __globalJsData.jsonLanding;
let landingObj = {};
if (t && t !== '') {
	landingObj = JSON.parse(t);
}

export default function init() {
	reactDom(<PaperContainer LandingObject={landingObj} size={10} />, 'js-react-paper-container');
}
