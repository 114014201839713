import initialState from '../initialStates/userData/initialState';

export default function cartItemsReducer(state = initialState, action) {
    switch (action.type) {
        case 'GETUSERDATASUCCESS':
            return action.userData;

        default:
            return state;
    }
}
