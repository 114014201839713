export default function loginModalVisibleReducer(state = false, action) {
    switch (action.type) {
        case 'SETVISIBLE':

            return true;

        case 'SETINVISIBLE':

            return false;

        default:
            return state;
    }
}
