/* eslint-disable quote-props */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import sanitizeHtml from 'sanitize-html';
import Skeleton from 'react-loading-skeleton';

import axios from 'axios';
import { spotlightContentApiEndpoint } from '../../variables';


export default class SpotlightContainer extends Component {
  static get propTypes() {
    return {
    };
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      slides: [],
    };
  }

  componentDidMount() {
    this.onLoadSpotlight();
  }

  onLoadSpotlight() {
    axios.get(`${spotlightContentApiEndpoint}`)
    .then(({ data }) => {
        this.setState({ loading: true }, () => {
          const slides = [];
          data.forEach((value, i) => {
            if (value) {
              let index = i;
              index++;
              slides.push(
              <div>
               <div>
                <img key={index} alt="logo" src={value.image} className="img-fluid" style={{ padding: '15px', height: '885px' }} />
               </div>
               <div className="spotlight_info_container">
                  <span className="title_spotlight">{value.name}</span>
               <span className="">
                <p className="subtitle_spotlight" dangerouslySetInnerHTML={{ __html: sanitizeHtml(value.description) }} />
               </span>
               </div>
              </div>,
                );
            }
          });

          this.setState({ slides });
        });
      }).catch(() => {
        this.setState({ loading: false });
    });
  }

  render() {
    const { loading, slides } = this.state;
    return (
      <div>
        {loading
       ? (
          <div>
            <div className="spotlight_container">
              {slides && slides.length > 0 && (
                  <Carousel
                    slidesPerPage={1}
                    slidesPerScroll={1}
                    infinite
                    clickToChange
                    centered
                    // autoPlay={5000}
                    animationSpeed={3000}
                    arrows
                    dots
                    value={this.value}
                    breakpoints={{
                      1000: { // these props will be applied when screen width is less than 1000px
                        slidesPerPage: 1,
                        clickToChange: false,
                        centered: false,
                        arrows: true,
                        infinite: false,
                      },
                      500: {
                        slidesPerPage: 1,
                        slidesPerScroll: 1,
                        clickToChange: false,
                        centered: false,
                        // arrowLeft: (<Icon className="icon-example" name="arrow-left" />),
                        // arrowRight: (<Icon className="icon-example" name="arrow-right" />),
                        arrows: true,
                        animationSpeed: 2000,
                        infinite: false,
                      },
                    }}
                   slides={slides}
                  />
              )}
            </div>
            {/* {spotlight.map(data => (
                <div key={data.id}>
                  {data.name}
                  <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.description) }} />
                </div>
                ))} */}
          </div>
        )
       : <Skeleton height={300} /> }
      </div>
    );
  }
}
