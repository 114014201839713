/* eslint-disable no-useless-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import ProductRow from '../ProductRow';

// eslint-disable-next-line react/prefer-stateless-function
class ProductsList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { cartItemList } = this.props;

        return (
            <>
                {cartItemList.map(element => <ProductRow item={element} key={element.productId} />)}
            </>
        );
    }
}


export default ProductsList;

ProductsList.propTypes = {

    cartItemList: PropTypes.arrayOf(PropTypes.object),

};
ProductsList.defaultProps = {

    cartItemList: [],

};
