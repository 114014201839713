import React from 'react'; // eslint-disable-line no-unused-vars
import reactDom from './react/dom/index';
import RelativesListContainer1 from '../components/common/RelativesListContainer';

// onAddSelection() {
// 	console.log(this.state.selectedElements);
// },

export default function init() {
	reactDom(<RelativesListContainer1 />, 'js-react-relativesList');
}
