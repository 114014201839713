import React from 'react';
import PropTypes from 'prop-types';

const GuidePartiList = ({
	resultTitle,
	arrayResult,
	idEdition,
	idGuida,
}) => (
	<div className="container">
		<div className="row guide-central-body">
			<div className="col-sm-12 col-print-full-width guida-content">
				<h1 className="heading">{resultTitle}</h1>
				<hr className="hr" />
				{ arrayResult.map(node => (
					<div key={node.id}>
						<div className="label-paragrafo">
							CAPITOLO&nbsp;
							{node.order}
						</div>
						<h3 className="mid-heading"><a className="color-link" href={`/Guide/Capitolo/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`}>{node.titolo}</a></h3>
						<hr className="hr" />
					</div>
					))}
			</div>
		</div>
	</div>
);

GuidePartiList.propTypes = {
	arrayResult: PropTypes.arrayOf(PropTypes.object),
	resultTitle: PropTypes.string,
	idEdition: PropTypes.number.isRequired,
	idGuida: PropTypes.number.isRequired,
};

GuidePartiList.defaultProps = {
 arrayResult: [],
 resultTitle: '',
};

export default GuidePartiList;
