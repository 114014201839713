/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import axios from 'axios';
import { eventsContentApiEndpoint } from '../../../variables';

import EventDetail from '../EventDetail';

export default class EventsContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      events: [],
    };
  }

  componentDidMount() {
    this.onLoadSponsors();
  }

  onLoadSponsors() {
    axios.get(`${eventsContentApiEndpoint}`)
    .then(({ data }) => {
      // const result = data.resultList.splice(0, 3);
      this.setState({ events: data, loading: true });
    }).catch(() => {
      this.setState({ events: [], loading: false });
        });
  }

  render() {
    const { events, loading } = this.state;
    return (
      <div>
        <div>
          <div className="row margin_lr top_title_all_events">
            <div className="col-6">ULTIMI EVENTI</div>
            <div className="col-6">
              <a className="view_all_events" href="/Archivio/Eventi/">VEDI TUTTI</a>
            </div>
          </div>
            <div className="row margin_lr">
              {events.slice(0, 3).map(data => (
                <div className="col-lg-4" style={{ padding: '4%' }} key={data.id}>
                  { loading
                  ? <EventDetail title={data.titolo} image={data.immagine} link={`/Events/Details/${data.id}`} description={data.abstract} loading={loading} />
                  : <Skeleton /> }
                </div>
              ))}
            </div>
        </div>
      </div>
    );
  }
}
