import React from 'react';
import PropTypes from 'prop-types';
// import { searchSubject } from 'utils/services/search';

class GuideSearchBoxContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			term: props.initialTerm,
			global: false,
			focus: false,
			page: 0,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleGlobalChange = this.handleGlobalChange.bind(this);
		this.handleNotGlobalChange = this.handleNotGlobalChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onReset = this.onReset.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.submit = this.submit.bind(this);
	}

	// when the component is attached to the DOM
	componentDidMount() {
	}

	componentWillUnmount() {
    // SearchSubject.onCompleted();
	}

	onKeyPress(evt) {
		if (evt.charCode === 13) {
			this.submit();
		}
	}

	onSubmit(evt) {
		evt.preventDefault();
		this.submit();
	}

	onReset() {
		// when user click on the X icon -> empty search field and close the dropdown if it open
		this.setState({ term: '', focus: false });
		// searchSubject.onNext({});
	}

	onFocus() {
		// Se ho cliccato fuori dall'elemento e anche fuori dai figli chiudo la finestrella
		// Attacco l'evento solo al focus e lo rimuovo quando non serve più (quando si attiva l'evento viene rimosso)
		window.addEventListener('click', this.clickOut, false);
		// when the user click inside search field, open the dropdown
		this.setState({ focus: true });
	}

	handleChange(evt) {
		this.setState({ term: evt.target.value, page: 0 });
		// searchSubject.onNext(newState);
	}

	handleGlobalChange() {
		this.setState({ global: true });
	}

	handleNotGlobalChange() {
		this.setState({ global: false });
	}

	submit() {
		const { ...props } = this.props;
		const { ...state } = this.state;
		if (props.hidecombo) {
			window.location.href = `/Guide/Search/?q=${state.term}&isGlobal=true`;
		} else {
			window.location.href = `/Guide/Search/?q=${state.term}&eid=${props.eid}&gid=${props.gid}&isGlobal=${state.global}`;
		}
	}

	render() {
		const {
			term,
			global,
		} = this.state;
		const {
			isGlobalSearchEnabled,
		} = this.props;
		const enableGlobal = isGlobalSearchEnabled;
		return (
			<div className="mod-search mod-search--internal">
				<div className="row">
					<div className="col-xs-12">
						<p>
							Non trovi quello che stai cercando?
							<br />
							Prova la funzione di ricerca specifica tra tutte le&nbsp;
							<strong>
								Guide multimediali
							</strong>
						</p>
					</div>
					<div className="col-xs-12">
						<form onSubmit={this.onSubmit}>
							<div className="form-group input-search-wrap">
								{enableGlobal ? (
									<div className="radio-search-global-wrap">
										<div className="radio">
											<label htmlFor={1} className="radio-search-global-label">
												<input type="radio" className="radio-search-global" onChange={this.handleNotGlobalChange} checked={!global} onKeyPress={this.onKeyPress} />
												&nbsp;in questa guida
											</label>
										</div>
										<div className="radio">
											<label htmlFor={2} className="radio-search-global-label">
												<input type="radio" className="radio-search-global" onChange={this.handleGlobalChange} checked={global} onKeyPress={this.onKeyPress} />
												&nbsp;in tutte le guide
											</label>
										</div>
									</div>
								) : null }
								<div className="input-search-global-wrap">
									<input type="text" className="form-control" placeholder="cerca" value={term} onChange={this.handleChange} />
									<i className="material-icons mi-search" onClick={this.onSubmit}>search</i>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

GuideSearchBoxContainer.propTypes = {
	hidecombo: PropTypes.bool,
	initialTerm: PropTypes.string,
	isSearchPage: PropTypes.bool,
	isGlobalSearchPage: PropTypes.bool,
	isGlobalSearchEnabled: PropTypes.bool,
	eid: PropTypes.number,
	gid: PropTypes.number,
};

GuideSearchBoxContainer.defaultProps = {
	hidecombo: false,
	initialTerm: '',
	isSearchPage: false,
	isGlobalSearchPage: false,
	isGlobalSearchEnabled: false,
	eid: 0,
	gid: 0,
};

export default GuideSearchBoxContainer;
