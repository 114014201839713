/* eslint-disable no-unused-expressions, indent, react/jsx-indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import GuideBreadcrumb from '../GuideBreadcrumb';
import GuidePartiList from '../GuidePartiList';
import GuideCapitoliList from '../GuideCapitoliList';
import GuideParagrafoDetails from '../GuideParagrafoDetails';
import { getGuidaCompleta } from '../../../variables';

const d = '';

const { CancelToken } = axios;
// const source = CancelToken.source();
let cancel;

const {
	idGuida,
	idEdition,
	} = __globalJsData;

export default class GuideDetailsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			guidaCompleta: {},
			isLoadData: false,
			arrayResult: [],
			isLoadResult: false,
			parteTitle: '',
			capitoloId: 0,
			capitoloNum: 0,
			parteId: 0,
			parteNum: '',
			idPrev: 0,
			idSucc: 0,
		};
		this.setArrayResult = this.setArrayResult.bind(this);
	}

	componentWillMount() {
		this.onLoadTree(idGuida);
	}

	onLoadTree = (id) => {
		cancel && (() => cancel());
		if (id != null) {
			let guidaCompleta = {};
			this.setState({ isGuidaLoading: true });
			axios.get(`${getGuidaCompleta}/?id=${id}`, { cancelToken: new CancelToken((c) => { cancel = c; }) }).then(({ data }) => {
				if (data.data != null) {
					guidaCompleta = data.data;
				}
				this.setState({ guidaCompleta, isLoadData: true });
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		} else {
			this.setState({ guidaCompleta: {}, isLoadData: false });
		}
	}

	setArrayResult(arr, parteTitle, capitoloId, capitoloNum, parteId, parteNum, idPrev, idSucc) {
		const { Tipology } = this.props;
		if (arr !== undefined && parteTitle !== undefined) {
			this.setState({ arrayResult: arr, isLoadResult: true, parteTitle });
		}
		if (Tipology === 'PARAGRAFO') {
			this.setState({
				capitoloId,
				capitoloNum,
				parteId,
				parteNum,
				idPrev,
				idSucc,
			});
		}
	}

	render() {
		const {
            isLoadData,
            guidaCompleta,
            parteTitle,
			isLoadResult,
			arrayResult,
			idSucc,
			idPrev,
			parteNum,
			parteId,
			capitoloId,
			capitoloNum,
        } = this.state;
		const {
			Tipology,
			ElementId,
		} = this.props;
		return (
			<div>
				<div className="gray-bg hidden-print">
					<div className="container">
						{isLoadData ? <GuideBreadcrumb Data={guidaCompleta} Tipology={Tipology} ElementId={ElementId} idEdition={idEdition} idGuida={idGuida} setArrayResult={this.setArrayResult} /> : null }
						<br />
					</div>
				</div>
				<div className="container">
					{ Tipology === 'PARTE' && isLoadResult
						? <GuidePartiList arrayResult={arrayResult} resultTitle={parteTitle} idEdition={idEdition} idGuida={idGuida} />
                                : null }
					{ Tipology === 'CAPITOLO' && isLoadResult
						? <GuideCapitoliList arrayResult={arrayResult} resultTitle={parteTitle} idEdition={idEdition} idGuida={idGuida} />
                                : null }
					{ Tipology === 'PARAGRAFO' && isLoadResult
						? <GuideParagrafoDetails resultTitle={parteTitle} id={ElementId} idEdition={idEdition} idGuida={idGuida} idSucc={idSucc} idPrev={idPrev} numParte={parteNum} idParte={parteId} idCapitolo={capitoloId} numCapitolo={capitoloNum} />
                                : null }
					<br />
				</div>
			</div>
		);
	}
}

GuideDetailsContainer.propTypes = {
	Tipology: PropTypes.string,
	ElementId: PropTypes.number,
};

GuideDetailsContainer.defaultProps = {
    Tipology: '',
    ElementId: 0,
};
