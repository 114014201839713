/* eslint-disable react/no-array-index-key */
import React from 'react';
import ImmaginiCorrelateItem from './ImmaginiCorrelateItem';

const { ImmaginiCorrelate } = __globalJsData;

function ImmaginiCorrelateEventi() {
  return (
  <div>
  <div className="flexbin">
    {ImmaginiCorrelate && ImmaginiCorrelate.length > 0 && (
        ImmaginiCorrelate.map(image => (
          <ImmaginiCorrelateItem key={image.Id} src={image.Url} />
        ))
    )}
  </div>
  </div>
  );
}

export default ImmaginiCorrelateEventi;
