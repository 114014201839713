import React from 'react';
import PropTypes from 'prop-types';

// import classNames from 'classnames';

// functional components(pure components) with destructuring, implicit return and default value
const BannerItemSmall = ({ link, title, image }) => (
	(link && image)
		? (
			<div className="mod-correlati--item">
				<a href={link} target="_blank" rel="noopener noreferrer" title={title}>
					<div className="d-flex w-100 justify-content-between">
						<img src={`${image}`} alt={title} title={title} className="img-responsive img-fluid" />
					</div>
				</a>
				{/* {Type ? <span className="badge">{Type}</span> : null } */}
			</div>
) : null
);

BannerItemSmall.propTypes = {
	link: PropTypes.string,
	title: PropTypes.string,
	image: PropTypes.string,
};

BannerItemSmall.defaultProps = {
	link: '',
	title: '',
	image: '',
};

export default BannerItemSmall;
