import React from 'react';
import PropTypes from 'prop-types';
import useIntersectionObserver from '../../../common/Hooks/IntersectionObserver';
import Image from './Image';

const ImmaginiCorrelateItem = ({ src }) => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

return (
    <div ref={ref}>
      {isVisible && (
        <Image src={src} alt="immagine correlata evento" style={{ padding: 2 }} />
       )}
    </div>
  );
};

ImmaginiCorrelateItem.propTypes = {
    src: PropTypes.string,
};

ImmaginiCorrelateItem.defaultProps = {
    src: '',
};

export default ImmaginiCorrelateItem;
