/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import axios from 'axios';
import { getFullDatesExpired } from '../../../variables';

export default class DatesExpiredContainer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            items: [],
            links: [],
            indexActive: 0,
        };

        this.selectDay = this.selectDay.bind(this);
    }

    componentDidMount() {
        this.onLoad();
    }

    onLoad() {
        axios.get(`${getFullDatesExpired}`).then(({ data }) => {
            const dataList = data.data;
            if (Array.isArray(dataList) && dataList.length) {
                const listExpired = dataList[0].Value.Scadenze;
                this.setState({ items: dataList, links: listExpired });
            }
          }).catch(() => {
            this.setState({ items: [] });
        });
    }

    selectDay(index) {
        const { items } = this.state;
        const listExpired = items[index].Value.Scadenze;
        this.setState({ links: listExpired, indexActive: index });
    }

    render() {
        const { items, links, indexActive } = this.state;
      return (
          <div>
        <div className="scadenze-tab-wrap">
        <ul id="scadenze-box-days" className="nav nav-tabs">
            {items.map((item, i) => (
                <li key={item.Key} className={`date-box-wrap ${ i === indexActive ? 'active' : ''}`}>
                    <a href="#" onClick={() => this.selectDay(i)}>
                        <div className="date-box">
                            <span className="date-day">{item.Value.day}</span>
                            <span className="date-month">{item.Value.month}</span>
                        </div>
                    </a>
                </li>
            ))}
        </ul>
        <div id="scadenze-tab-content" className="tab-content">
            <ul className="list-scadenze list-unstyled bg_container c1">
            {links.map((value, k) => (
                <li key={value.Id + k + 50} className="box-list-item article_list">
                    <a href={`https://www.consulenza.it${value.Url}`} target="_blank" rel="noreferrer" className="box-list-item-text">
                        {value.title}
                    </a>
                </li>
                        ))}
            </ul>
        </div>
        </div>
    <div className="scadenze-footer">
            <p>
{' '}
In collaborazione con
<a href="https://www.consulenza.it" target="_blank" rel="noreferrer">
            <img src="https://bdbackofficestorage.blob.core.windows.net/servizi-siteassets/images/Logo-Buffetti-Consulenza.png" alt="consulenza" height="40" />
</a>
            </p>
    </div>
          </div>
      );
    }
  }
