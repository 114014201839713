import { combineReducers } from 'redux';
import cartPhaseReducer from './cartPhaseReducer';
import cartItemsReducer from './cartItemsReducer';
import userDataReducer from './userDataReducer';
import discountReducer from './discountReducer';
import loginModalVisibleReducer from './loginModalVisibleReducer';
import shopFilterReducer from './shopFilterReducer';

const allReducers = combineReducers({

    cartPhase: cartPhaseReducer,
    cartItemList: cartItemsReducer,
    userData: userDataReducer,
    discountData: discountReducer,
    loginModalVisible: loginModalVisibleReducer,
    shopFilter: shopFilterReducer,

});

export default allReducers;
