import React from 'react';
import reactDom from '../../../common/react/dom/index';

import ArchivioVideo from '../../../components/frontend/archivio/ArchivioVideo/index';

const { thematics } = __globalJsData;
let arr = [];
if (thematics && thematics !== '' && thematics !== '[]') {
    arr = JSON.parse(thematics);
}

export default function init() {
	reactDom(<ArchivioVideo />, 'js-react-archiviovideo');
}
