import React, { Component } from 'react';
import axios from 'axios';
// import notification from 'common/notification';
// import autobind from 'autobind-decorator';
// import cssModules from 'react-css-modules';
// import ReactInterval from 'react-interval';
// import LoadingSpinner from 'components/LoadingSpinner';
import {
	articoloRelativesContentApiEndpoint, lastArticlesContentApiEndpoint,
	newsRelativesContentApiEndpoint, lastNewsContentApiEndpoint,
	videoRelativesContentApiEndpoint, lastVideoContentApiEndpoint,
	bannerRelativesContentApiEndpoint,
} from '../../variables';

// import classnames from 'classnames';
import ArticoloItemSmall from '../../frontend/articles/ArticoloItem';
import VideoItemSmall from '../../frontend/video/VideoItemSmall';
import BannerItemSmall from '../../frontend/banner/BannerItemSmall';
import NewsItemSmall from '../../frontend/news/NewsItem';

// import styles from './styles.less';

const {
 VideoCorrelati, ArticoliCorrelati, NewsCorrelate, BannerCorrelati,
} = __globalJsData;

const category = __globalJsData.Category;
const area = __globalJsData.AreaTematica;
const id = __globalJsData.Id;

// @cssModules(styles)
export default class RelativesListContainer extends Component {
	state = {
		articoloList: [],
		videoList: [],
		bannerList: [],
		newsList: [],
	};

	componentDidMount() {
		this.onReloadRelativesArticles();
		this.onReloadRelativesBanners();
		this.onReloadRelativesVideos();
		this.onReloadRelativesNews();
	}

	onReloadRelativesArticles = () => {
		let newArticoloList = [];
		// let isLoaded = false;
		// if (ArticoliCorrelati != null && ArticoliCorrelati.length > 0) {
			axios.post(articoloRelativesContentApiEndpoint, {
				ArticoliCorrelati,
			}).then(({ data }) => {
				// isLoaded = true;
				if (data != null) {
					newArticoloList = data;
				}
				this.setState({ articoloList: newArticoloList });
			});
		// } else {
		// 	const url = `${lastArticlesContentApiEndpoint}/?id=${id}&t=${area}&c=${category}&size=3`;

		// 	axios.get(url).then(({ data }) => {
		// 		// isLoaded = true;
		// 		if (data.resultList != null) {
		// 			newArticoloList = data.resultList;
		// 		}
		// 		this.setState({ articoloList: newArticoloList });
		// 	});
		// }
	}

	onReloadRelativesNews = () => {
		let newArticoloList = [];
		// let isLoaded = false;
		// if (NewsCorrelati != null && NewsCorrelati.length > 0) {
			axios.post(newsRelativesContentApiEndpoint, {
				NewsCorrelate,
			}).then(({ data }) => {
				// isLoaded = true;
				if (data != null) {
					newArticoloList = data;
				}
				this.setState({ newsList: newArticoloList });
			});
		// } else {
		// 	const url = `${lastNewsContentApiEndpoint}/?id=${id}&t=${area}&c=${category}&size=3`;

		// 	axios.get(url).then(({ data }) => {
		// 		// isLoaded = true;
		// 		if (data.resultList != null) {
		// 			newArticoloList = data.resultList;
		// 		}
		// 		this.setState({ newsList: newArticoloList });
		// 	});
		// }
	}

	onReloadRelativesVideos = () => {
		let newVideoList = [];
		// let isLoaded = false;
		// if (VideoCorrelati != null && VideoCorrelati.length > 0) {
			axios.post(videoRelativesContentApiEndpoint, {
				VideoCorrelati,
			}).then(({ data }) => {
				// isLoaded = true;
				if (data != null) {
					newVideoList = data;
				}
				this.setState({ videoList: newVideoList });
			});
		// } else {
		// 	const url = `${lastVideoContentApiEndpoint}/?id=${id}&t=${area}&c=${category}&size=3`;
		// 	axios.get(url).then(({ data }) => {
		// 		// isLoaded = true;
		// 		if (data.resultList != null) {
		// 			newVideoList = data.resultList;
		// 		}
		// 		this.setState({ videoList: newVideoList });
		// 	});
		// }
	}


	onReloadRelativesBanners = () => {
		let newBannerList = [];
		// let isLoaded = false;
		if (BannerCorrelati != null && BannerCorrelati.length > 0) {
			const object = { idList: BannerCorrelati };
			axios.post(bannerRelativesContentApiEndpoint, {
				BannerCorrelati,
			}).then(({ data }) => {
				// isLoaded = true;
				if (data != null) {
					newBannerList = data;
				}
				this.setState({ bannerList: newBannerList });
			});
		}
	}

	render() {
		const {
 articoloList, videoList, bannerList, newsList,
} = this.state;

		return (
<div className="sidebar-panel">
			{(articoloList != null && articoloList.length > 0) ? (
<div className="article mod-correlati">
					<div className="mod-correlati-heading d-flex w-100 justify-content-between">
						<h4 className="h4">
							<i className="material-icons mi-chat" style={{ verticalAlign: 'bottom' }}>chat</i>
							EDITORIALI CORRELATI
						</h4>

						{/* <a href="/Articles" target="_blank" rel="noopener noreferrer">Tutti</a> */}
					</div>
					<hr />
					<div className="mod-correlati-list">
						{articoloList.map(article => <ArticoloItemSmall key={article.id} {...article} />)}
					</div>
					<br />
</div>

) : null}
{(newsList != null && newsList.length > 0) ? (
	<div className="article mod-correlati">
						<div className="mod-correlati-heading d-flex w-100 justify-content-between">
							<h4 className="h4">
								<i className="material-icons mi-chat" style={{ verticalAlign: 'bottom' }}>chat</i>
								NEWS CORRELATE
							</h4>

							{/* <a href="/NEWS" target="_blank" rel="noopener noreferrer">Tutti</a> */}
						</div>
						<hr />
						<div className="mod-correlati-list">
							{newsList.map(n => <NewsItemSmall key={n.id} {...n} />)}
						</div>
						<br />
	</div>
	) : null}

			{(videoList != null && videoList.length > 0) ? (
<div className="video mod-correlati">
				<div className="mod-correlati-heading d-flex w-100 justify-content-between">
					<h4 className="h4">
						<i className="material-icons mi-play_circle_fill" style={{ verticalAlign: 'bottom' }}>play_circle_fill</i>
						VIDEO CORRELATI
					</h4>


					{/* <a href="/Video" target="_blank" rel="noopener noreferrer">Tutti</a> */}
				</div>
				<hr />
				<div className="mod-correlati-list">
					{videoList.map(video => <VideoItemSmall key={video.id} {...video} />)}
				</div>
				<br />
</div>
) : null}
			{(bannerList != null && bannerList.length > 0) ? (

<div>
	{bannerList.map(banner => <BannerItemSmall key={banner.id} {...banner} />)}
</div>
) : null}
</div>
		);
	}
}
