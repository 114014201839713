/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { notification } from 'antd';
import Skeleton from 'react-loading-skeleton';

class ArchivioEventi extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contents: [],
            contentsLoading: true,
            pagination: {
                from: 0,
                size: 10,
                total: 0,
            },
        };
    }

    componentDidMount() {
        this.loadContenuti();
    }

    loadContenuti(fromRet = false) {
        const { pagination, ...state } = this.state;
        let { contents } = this.state;

        if (fromRet) {
            pagination.from += 10;
        }

        axios.post('/api/archive/getAllEvents', {
            pagination,
        })
        .then(({ data: { data, pagination } }) => {
            if (contents.length === 0) {
                contents = data;
            } else {
                contents = contents.concat(data);
            }
            this.setState({ contents, contentsLoading: false, pagination });
        })
        .catch((error) => {
            if (error) {
                notification.info({
                    message: `Problema caricamento contenuti. \n\n\n ${error}`,
                });
            } else {
                notification.info({
                    message: 'Problema caricamento contenuti',
                });
            }

            this.setState({ contentsLoading: false });
        });

        this.setState({ contentsLoading: true });
    }

    render() {
        const {
            contentsLoading,
            contents,
            pagination,
        } = this.state;

        const title = 'Archivio Eventi';

        return (
            <div>
                {contentsLoading && (
                    <div className="row">
                        <div className="col-12" style={{ minHeight: '250px' }}>
                            <Skeleton />
                        </div>
                    </div>
                )}

                {!contentsLoading && contents && contents.length > 0 && (
                    <>
                        <div className="row margin_lr">
                            <div className="col-12 content_title">
                                <h4 dangerouslySetInnerHTML={{ __html: title }} />
                            </div>
                        </div>
                        <div className="row margin_lr">
                            <div className="col-12 content_list_container">
                                {contents.map((value) => {
                                    let contentUrl = '';
                                    let contentOf = 'di ';
                                    if (value !== undefined) {
                                                contentUrl = `/Events/Details/${value.id}`;
                                                contentOf = 'Evento di ';
                                    }
                                    return (
                                        <div key={value.id} style={{ width: '100%' }}>
                                            <div className="content_list_item m-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-lg-2 content_list_item_img">
                                                        <a href={contentUrl}><img src={value.immagine} alt="item" className="img-fluid" /></a>
                                                    </div>
                                                    <div className="col-12 col-md-8 col-lg-10 content_list_item_txt">
                                                        <a href={contentUrl}><h4 style={{ fontWeight: 'bold' }}>{value.titolo}</h4></a>
                                                        <h5>
                                                            {value.dateIT}
                                                            &nbsp;-&nbsp;
                                                            {value.luogo}
                                                        </h5>
                                                        <p dangerouslySetInnerHTML={{ __html: `${value.abstract.substring(0, 300)}...` }} />
                                                        {/* <small>
                                                            <span className="article_of">{contentOf}</span>
                                                            {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                                <span key={index}>{`${author} `}</span>
                                                            ))}
                                                        </small> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {pagination.total > contents.length && (
                            <div className="row margin_lr">
                                <div className="col-12 show_more_btn_container">
                                    <a href={null} className="btn show_more_btn" onClick={() => this.loadContenuti(true)}>Carica altri</a>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default ArchivioEventi;
