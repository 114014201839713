/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import axios from 'axios';
import {
  Form,
  Input,
  Button,
  Checkbox,
} from 'antd';
import {
  showLoadingOverlay,
  hideLoadingOverlay,
} from '../../../common/loadingOverlay';
import {
  errorNotification,
  successNotification,
} from '../../../common/Notification';

export default class PromoContainer extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values) {
    showLoadingOverlay();

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const model = { ...values };
    model.CampaignType = params.campaignType;

    axios
      .post('/api/CampagneApi/SubmitCampaignFormBp', {
        ...model,
      })
      .then(() => {
        hideLoadingOverlay();
        successNotification(
          "Grazie per averci contattato, riceverai un'email a breve.",
        );
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      })
      .catch((error) => {
        hideLoadingOverlay();
        errorNotification(error.response.data);
      });
  }

  render() {
    return (
      <>
        <div className="row w-100 text-center p-3">
          <div className="col-2" />
          <div className="col-md-8 col-12">
            <h2 style={{ color: '#77B3D4' }}>
              Inserisci i tuoi dati nel form di seguito per ricevere le offerte
              a te dedicate.
            </h2>
            {/* <p>Compila questo form per richiedere una demo gratuita ed esplorare le caratteristiche funzionali della piattaforma Business Plan Directio. Riceverai via mail le credenziali di accesso al tuo account di prova che ti permetterà di creare il tuo piano e valutare tutte le funzionalità della piattaforma per 7 giorni.</p> */}
          </div>
          <div className="col-2" />
        </div>
        <div className="row">
          <div className="col-md-2 col-1" />
          <div className="col-md-8 col-10">
            <Form layout="vertical" onFinish={this.submitForm}>
              <div className="row">
                <Form.Item
                  className="col-md-6 col-12"
                  label="Nome"
                  name="nome"
                  rules={[
                    { required: true, message: 'Il nome è obbligatorio.' },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="col-md-6 col-12"
                  label="Cognome"
                  name="cognome"
                  rules={[
                    { required: true, message: 'Il cognome è obbligatorio.' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="row">
                <Form.Item
                  className="col-md-6 col-12"
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "L' email è obbligatorio." },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="col-md-6 col-12"
                  label="Telefono"
                  name="telefono"
                  rules={[
                    {
                      required: true,
                      message: 'Il numero di telefono è obbligatorio.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="row">

              <Form.Item
                  className="col-md-6 col-12"
                  label="Provincia"
                  name="provincia"
                  rules={[
                    {
                      required: true,
                      message: 'La provincia è obbligatorio.',
                    },
                  ]}
              >
                  <Input />
              </Form.Item>

                <Form.Item
                  className="col-md-6 col-12"
                  label="Città"
                  name="citta"
                  rules={[
                    { required: true, message: 'La città è obbligatoria.' },
                  ]}
                >
                  <Input />
                </Form.Item>

              </div>

              <div className="row">
                <div className="col-0 col-md-3" />
                <Form.Item
                  className="col-md-6 col-12"
                  label="Studio/Azienda"
                  name="studioAzienda"
                  rules={[
                    {
                      required: true,
                      message: 'Lo studio/azienda è obbligatorio.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <div className="col-0 col-md-3" />
              </div>

              <div className="row align-items-end">
                <div id="checkBoxMargin">
                  <Form.Item name="auth" valuePropName="checked">
                    <Checkbox>
                      Autorizzo il trattamento dei miei dati personali per
                      finalità commerciali dirette, ai sensi del Decreto
                      Legislativo 30 giugno 2003, n. 196 e del GDPR (Regolamento
                      UE 2016/679)*
                    </Checkbox>
                  </Form.Item>
                </div>

                <div id="checkBoxMargin">
                  <Form.Item name="tp_auth" valuePropName="checked">
                    <Checkbox>
                      Autorizzo il trattamento dei miei dati personali per
                      finalità commerciali di terze parti, ai sensi del Decreto
                      Legislativo 30 giugno 2003, n. 196 e del GDPR (Regolamento
                      UE 2016/679)
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>

              <div className="text-center">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Invia
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="col-md-2 col-1" />
        </div>
      </>
    );
  }
}
