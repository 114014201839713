/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import { propTypes } from 'react-bootstrap/esm/Image';

const ECommerceOtherItemsContainer = ({ RiferimentiProdotti, openModal }) => {
    const prodotto = RiferimentiProdotti[0];
    return (
        <div>
            <a href={null} onClick={() => openModal()}>
                <div className="ecommerce_footer_title">
                    {prodotto.Nome}
                </div>
                <div>
                    <span className="ecommerce_footer_text" dangerouslySetInnerHTML={{ __html: `${prodotto.Descrizione}` }} />
                </div>
                <img src={prodotto.ImmaginePiccola} alt="riferimento prodotto" style={{ maxWidth: '100%' }} />
            </a>
        </div>
    );
};

ECommerceOtherItemsContainer.propTypes = {
    RiferimentiProdotti: PropTypes.arrayOf(PropTypes.object).isRequired,
    openModal: PropTypes.func.isRequired,
};

export default ECommerceOtherItemsContainer;
