/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Modal,
    Form,
    Input,
    Checkbox,
} from 'antd';
import axios from 'axios';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { setModalInvisible } from '../../../common/redux/actions';
import { errorNotification } from '../../../common/Notification';

class LoginModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            password: '',
            rememberMe: false,
            resetPassword: '',
            usernameOrEmail: '',
        };

        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.login = this.login.bind(this);
        this.reset = this.reset.bind(this);
        this.handleChangeRememberMe = this.handleChangeRememberMe.bind(this);
        this.handleChangeUsernameOrEmail = this.handleChangeUsernameOrEmail.bind(this);
        this.setResetPasswordModal = this.setResetPasswordModal.bind(this);
    }

    setResetPasswordModal() {
        const { resetPassword } = this.state;
        this.setState({ resetPassword: !resetPassword });
    }

    handleChangeUserName(e) {
        if (e) {
            this.setState({ userName: e.target.value });
        }
    }

    handleChangeUsernameOrEmail(e) {
        if (e) {
            this.setState({ usernameOrEmail: e.target.value });
        }
    }

    handleChangePassword(e) {
        if (e) {
            this.setState({ password: e.target.value });
        }
    }

    handleChangeRememberMe(e) {
        if (e) {
            this.setState({ rememberMe: e.target.checked });
        }
    }

    login() {
        const { actions } = this.props;
        const {
            userName,
            password,
            rememberMe,
            returnUrl,
        } = this.state;

        showLoadingOverlay();

        axios.post('/Account/Login', {
            userName,
            password,
            rememberMe,
            returnUrl,
        })
            .then(() => {
                hideLoadingOverlay();
                actions.setModalInvisible();
                window.location.reload();
            })
            .catch((error) => {
                hideLoadingOverlay();
                errorNotification(error.response.data);
            });
    }

    reset() {
        const { actions } = this.props;
        const { usernameOrEmail } = this.state;

        showLoadingOverlay();

        axios.post('/Account/Reset', {
            usernameOrEmail,
        }).then(() => {
            hideLoadingOverlay();
            actions.setModalInvisible();
            window.location.href = '/';
        }).catch((error) => {
            hideLoadingOverlay();
            errorNotification(error.response.data);
        });
    }

    handleCancel() {
        const { actions } = this.props;
        this.setState({
            userName: '',
            password: '',
            rememberMe: false,
            resetPassword: '',
            usernameOrEmail: '',
        }, () => {
            actions.setModalInvisible();
        });
    }

    render() {
        const { loginModalVisible } = this.props;

        const {
            userName,
            password,
            rememberMe,
            resetPassword,
            usernameOrEmail,
        } = this.state;

        return (

            <Modal
                visible={loginModalVisible}
                onCancel={this.handleCancel}
                footer={null}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                destroyOnClose
            >
                {!resetPassword ? (
                    <>
                        <Form onFinish={this.login}>
                            <div className="row margin_contact" style={{ fontFamily: 'Barlow', fontWeight: '500' }}>
                                <div className="col-lg-12 text-center">

                                    <img src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-header.png" alt="login" />
                                    <h1 className="font-zillaslab">ACCEDI AL PORTALE DIRECTIO</h1>

                                    <Input onChange={this.handleChangeUserName} className="form-control my-3 rounded-0" name="username" id="login-username" placeholder="Nome Utente" type="text" value={userName} />
                                    <Input onChange={this.handleChangePassword} className="form-control my-3 rounded-0" name="Password" id="login-password" placeholder="Password" type="password" value={password} />

                                </div>
                                <div className="row w-100 d-flex align-items-center m-3">
                                    <div className="col-lg-6">
                                        <Checkbox onChange={this.handleChangeRememberMe} className="w-100" name="RememberMe" checked={rememberMe}>Ricordami</Checkbox>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <a onClick={this.setResetPasswordModal}>Reset password</a>
                                    </div>
                                </div>
                                <div className="row w-100 d-flex align-items-center mx-3">
                                    <div className="col-lg-12 text-center">
                                        <div className="line_thin" />
                                        <button className="btn_n" type="submit">Login</button>
                                        <div className="line_thin" />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-flex d-inline align-items-center justify-content-center m-1">
                                <div className="text-center not-register">Non sei ancora registrato?&nbsp;</div>
                                <a href="/account/register">Registrati ora</a>
                            </div>
                        </Form>
                    </>
                ) : (

                    <>
                        <Form onFinish={this.reset}>
                            <div className="row margin_contact" style={{ fontFamily: 'Barlow', fontWeight: '500' }}>

                                <div className="col-lg-12 text-center">

                                    <img src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-header.png" alt="login" />
                                    <h1 className="font-zillaslab">RESET PASSWORD</h1>

                                    <span>Inserisci il tuo nome utente o la tua mail di registrazione per impostare la tua nuova password.</span>
                                    <br />
                                    <span>Riceverai una mail con la password provvisoria.</span>

                                    <Input onChange={this.handleChangeUsernameOrEmail} className="form-control my-3 rounded-0" name="UsernameOrEmail" id="login-servizi-username-email" placeholder="Username o email" type="text" value={usernameOrEmail} />

                                </div>
                            </div>

                            <div className="col-lg-12 text-center">
                                <div className="line_thin" />
                                <button className="btn_n" type="submit">RESET</button>
                                <div className="line_thin" />
                            </div>
                        </Form>

                    </>

                )
                }
            </Modal>

        );
    }
}

function mapStateToProps(state) {
    return {
        loginModalVisible: state.loginModalVisible,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setModalInvisible: bindActionCreators(setModalInvisible, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);


LoginModal.propTypes = {
    loginModalVisible: PropTypes.bool,
};

LoginModal.defaultProps = {
    loginModalVisible: false,
};
