import React, { Component } from 'react';
import axios from 'axios';
import { errorNotification } from '../../../common/Notification';
import downloadReport from '../../../common/report/ReportFunctions';

import {
  showLoadingOverlay,
  hideLoadingOverlay,
} from '../../../common/loadingOverlay';

export default class PromoReportContainer extends Component {
  constructor(props) {
    super(props);

    this.downloadReportFunc = this.downloadReportFunc.bind(this);
  }

  downloadReportFunc = () => {
    showLoadingOverlay();

    axios.get('/api/CampagneApi/GetAllCampaign')
      .then(({ data }) => {
        hideLoadingOverlay();
        downloadReport(data);
      })
      .catch((error) => {
        hideLoadingOverlay();
        errorNotification(error.response.data);
      });
  }

  render() {
    return (
      <div className="absoluteCentered">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.downloadReportFunc()}
        >
          SCARICA
        </button>
      </div>
    );
  }
}
