/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions, indent, react/jsx-indent, react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
    getParagrafoDetails,
    videoRelativesContentApiEndpoint,
	bannerRelativesContentApiEndpoint,
} from '../../../variables';

const { CancelToken } = axios;
// const source = CancelToken.source();
let cancel;

const d = '';

export default class GuideParagrafoDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paragrafo: {},
			isLoading: false,
			bannerList: [],
			bannerListFirst: [],
			videoList: [],
			videoLoaded: false,
			firstbannerLoaded: false,
			bannerLoaded: false,
			clicked: false,
		};
	}

	componentWillMount() {
		const { id } = this.props;
		this.onLoadDetails(id);
	}

	componentDidUpdate() {
		this.printFunc();
	}

	onReloadRelativesVideos = () => {
		const { paragrafo } = this.state;
		cancel && (() => cancel());
		let newVideoList = [];
		let isLoaded = false;
		if (paragrafo.VideoCorrelati != null && paragrafo.VideoCorrelati.length > 0) {
			axios.post(videoRelativesContentApiEndpoint, {
				idList: paragrafo.VideoCorrelati,
			}, { cancelToken: new CancelToken((c) => { cancel = c; }) }).then(({ data }) => {
				isLoaded = true;
				if (data.data != null) {
					newVideoList = data.data;
				}
				this.setState({ videoList: newVideoList, videoLoaded: isLoaded });
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		}
	}

	onReloadRelativesBanners = () => {
		const { paragrafo } = this.state;
		cancel && (() => cancel());
		let newBannerList = [];
		let isLoaded = false;
		if (paragrafo.BannerCorrelati != null && paragrafo.BannerCorrelati.length > 0) {
			axios.post(bannerRelativesContentApiEndpoint, {
				idList: paragrafo.BannerCorrelati,
			}, { cancelToken: new CancelToken((c) => { cancel = c; }) }).then(({ data }) => {
				isLoaded = true;
				if (data.data != null) {
					newBannerList = data.data;
				}
				this.setState({ bannerList: newBannerList, bannerLoaded: isLoaded });
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		}
	}

	onReloadRelativesFirstBanners = () => {
		const { paragrafo } = this.state;
		cancel && (() => cancel());
		let newFirstBannerList = [];
		let isLoaded = false;
		if (paragrafo.FirstBannerCorrelati != null && paragrafo.FirstBannerCorrelati.length > 0) {
			axios.post(bannerRelativesContentApiEndpoint, {
				idList: paragrafo.FirstBannerCorrelati,
			}, { cancelToken: new CancelToken((c) => { cancel = c; }) }).then(({ data }) => {
				isLoaded = true;
				if (data.data != null) {
					newFirstBannerList = data.data;
				}
				this.setState({ bannerListFirst: newFirstBannerList, firstbannerLoaded: isLoaded });
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		}
	}

	onLoadDetails = (id) => {
		const { idEdition } = this.props;
		cancel && (() => cancel());
		let paragrafo = {};
		if (id != null) {
			this.setState({ isLoading: true });
			axios.get(`${getParagrafoDetails}/?id=${id}&idE=${idEdition}`, { cancelToken: new CancelToken((c) => { cancel = c; }) }).then(({ data }) => {
				if (data.data != null) {
					paragrafo = data.data;
				}
				this.setState({ paragrafo, isLoading: true });
				this.onReloadRelativesFirstBanners();
				this.onReloadRelativesBanners();
				this.onReloadRelativesVideos();
				this.onReloadRelativesNorme();
				this.onReloadRelativesPrassi();
				this.onReloadRelativesGiurisprudenza();
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		} else {
			this.setState({ guidaTOC: {}, isLoading: false });
		}
	}

	printFunc() {
		const { clicked } = this.state;
		if (!clicked) {
			const GetParams = [];
			const prmstr = window.location.search.substr(1);
			const prmarr = prmstr.split('&');
			const prmarrLen = prmarr.length;
			let tmparr = [];
			let i = 0;
			for (i = 0; i < prmarrLen; i++) {
				tmparr = prmarr[i].split('=');
				GetParams[tmparr[0]] = tmparr[1];
			}
			if (GetParams.print === '1') {
				window.print();
			}
			this.setState({ clicked: true });
		}
	}

	render() {
		const {
			idParte,
			idEdition,
			idGuida,
			idCapitolo,
			numParte,
			numCapitolo,
			idPrev,
			idSucc,
		} = this.props;
		const {
			paragrafo,
			bannerListFirst,
			bannerList,
			videoList,
		} = this.state;
		return (
			<div>
				<div className="row guide-central-body">
					<div className="col-sm-9 col-print-full-width guida-content">
						<div className="clearfix breadcrumb-navigation">
							<ul className="breadcrumb pull-left">
								<li>
									<a href={`/Guide/Parte/?id=${idParte}&eId=${idEdition}&gId=${idGuida}`}>
									PARTE&nbsp;
									{numParte}
									</a>
								</li>
								<li>
									<a href={`/Guide/Capitolo/?id=${idCapitolo}&eId=${idEdition}&gId=${idGuida}`}>
										CAPITOLO&nbsp;
										{numCapitolo}
									</a>
								</li>
								<li className="active">
									PARAGRAFO&nbsp;
								{paragrafo.number}
								</li>
							</ul>
							<ul className="pager-guide pull-right">
								{idPrev !== 0
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									? <li className="previous"><a href={`/Guide/Paragrafo/?id=${idPrev}&eId=${idEdition}&gId=${idGuida}`} /></li>
								: <li className="previous disabled"><span /></li>}
								{idSucc !== 0
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									? <li className="next"><a href={`/Guide/Paragrafo/?id=${idSucc}&eId=${idEdition}&gId=${idGuida}`} /></li>
								: <li className="next disabled"><span /></li>}
							</ul>
						</div>

						<h2 className="heading">{paragrafo.titolo}</h2>
						{paragrafo.author
							? (
							<h4>
								A cura di&nbsp;
								{paragrafo.Author}
							</h4>
							) : null}
						<hr className="hr" />
						<div dangerouslySetInnerHTML={{ __html: paragrafo.testo }} />

						{paragrafo.storyline
							? (
							<div className="storyline-wrapper hidden-print">
								<iframe title="storyline" src={paragrafo.storyline} frameBorder="0" hspace="0" marginHeight="0" marginWidth="0" scrolling="no" vspace="0" allowTransparency="true" />
							</div>
							) : null }
					</div>
					<div className="col-sm-3 hidden-print">
						<div id="links-col-dx">
							{/*  @* First BANNERS*@ */}
							{(bannerListFirst != null && bannerListFirst.length > 0)
								? (
								<ul className="sidebarbanner-wrap">
									{bannerListFirst.map(b => (
										<li key={b.id} className="sidebarbanner-item">
											<a href={b.Link}>
												<img src={`${b.ImmagineMedia}`} alt="immagineMedia" className="img-responsive" role="presentation" />
											</a>
										</li>
									))}
								</ul>
								) : null }

							{/*  @*Novita *@ */}
							{paragrafo.novitaSulTemaCorrelati
								? (
								<div className="panel-guida video">
									<div className="panel-guida-heading">
										<i className="material-icons mi-new-releases">&#xE031;</i>
										<h5>Novit&agrave; sul tema</h5>
									</div>
									<div className="panel-guida-content">
										{paragrafo.novitaSulTemaCorrelati.map((node, i) => (
											<div key={i.id} className="novita-item">
												<div className="novita-title">{node.titolo}</div>
												<div className="novita-body">{node.descrizione}</div>
												<ul className="links-group">
													{node.link.map(link => (
														<li key={link.id}>
															<i className="material-icons mi-attach-file link-icon">&#xE226;</i>
															<a href={link.url} target="_blank" rel="noopener">{link.title}</a>
														</li>
                                            ))}
												</ul>
											</div>
                                ))}
									</div>
								</div>
								) : null }
							{/*  @*BANNERS*@ */}
							{(bannerList != null && bannerList.length > 0)
								? (
								<ul className="sidebarbanner-wrap">
									{bannerList.map(b => (
										<li key={b.id} className="sidebarbanner-item">
											<a href={b.Link}>
												<img src={`${b.immagineMedia}`} alt="immagineMedia" className="img-responsive" role="presentation" />
											</a>
										</li>
									))}
								</ul>
								) : null }
							{/* @*VIDEO COMMENTI*@ */}
							{(videoList != null && videoList.length > 0)
								? (
								<div className="panel-guida video">
									<div className="panel-guida-heading">
										<i className="material-icons mi-play-circle-filled">&#xE038;</i>
										<h5>Video Commenti</h5>
									</div>
									<div className="panel-guida-content">
										{videoList.map(v => (
											<div key={v.id} className="video-item">
												<div className="video-player-wrap">
													<video id={`video-item-${v.id}`} controlsList="nodownload" height="100%" width="100%" poster={`${v.immagineMedia}`} controls preload="none" data-setup='{"controls":true,"preload":"none","fluid":true,"controlsList":"nodownload","aspectRatio":"16:9"}'>
														<track kind="captions" />
														<source src={`${v.videoMP4}`} type="video/mp4" />
													</video>
												</div>
												<div className="video-title">
													{v.title}
													&nbsp;
												</div>
											</div>
										))}
									</div>
								</div>
								) : null }
							{/* ALTRI DOCUMENTI E LINK */}
							{paragrafo.linksCorrelati
								? (
								<div className="panel-guida video">
									<div className="panel-guida-heading">
										<i className="material-icons">&#xE157;</i>
										<h5>Altri documenti e link</h5>
									</div>
									<div className="panel-guida-content">
										<ul className="links-group">
											{paragrafo.linksCorrelati.map(link => (
												<li key={link.id}>
													<i className="material-icons mi-attach-file link-icon">&#xE226;</i>
													<a href={link.url} target="_blank" rel="noopener">{link.title}</a>
												</li>
                                ))}
										</ul>
									</div>
								</div>
								) : null }
							{/* CASI E QUESTIONI */}
							{paragrafo.casieQuestioniCorrelati
								? (
								<div className="panel-guida video">
									<div className="panel-guida-heading">
										<i className="material-icons mi-mode-comment">&#xE253;</i>
										<h5>Casi e questioni</h5>
									</div>
									<div className="panel-guida-content">
										<ul className="links-group">
											{paragrafo.casieQuestioniCorrelati.map(link => (
												<li key={link.id}>
													<i className="material-icons mi-attach-file link-icon">&#xE226;</i>
													<a href={link.url} target="_blank" rel="noopener">{link.title}</a>
												</li>
                                ))}
										</ul>
									</div>
								</div>
								) : null }
							{/* TI POTREBBE INTERESSARE */}
							{paragrafo.linkInteressantiCorrelati
								? (
								<div className="panel-guida video">
									<div className="panel-guida-heading">
										<i className="material-icons mi-border-color">&#xE22B;</i>
										<h5>Ti potrebbe interessare</h5>
									</div>
									<div className="panel-guida-content">
										<ul className="links-group">
											{paragrafo.linkInteressantiCorrelati.map(link => (
												<li key={link.id}>
													<i className="material-icons mi-attach-file link-icon">&#xE226;</i>
													<a href={link.url} target="_blank" rel="noopener">{link.title}</a>
												</li>
                                ))}
										</ul>
									</div>
								</div>
								) : null }
						</div>
					</div>
				</div>
			</div>
		);
	}
}

GuideParagrafoDetails.propTypes = {
	idEdition: PropTypes.number.isRequired,
	idGuida: PropTypes.number.isRequired,
	id: PropTypes.number.isRequired,
	idParte: PropTypes.number,
	numParte: PropTypes.string,
	idCapitolo: PropTypes.number,
	numCapitolo: PropTypes.number,
	idPrev: PropTypes.number,
	idSucc: PropTypes.number,
};

GuideParagrafoDetails.defaultProps = {
	numParte: '',
	numCapitolo: -1,
	idPrev: -1,
	idSucc: -1,
	idParte: -1,
	idCapitolo: -1,
};
