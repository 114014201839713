const cartPhaseReducer = (state = 1, action) => {
    switch (action.type) {
        case 'FWDPHASE':
            if (state < 3) {
                return state + 1;
            }
            return state;

        case 'BKWPHASE':
            if (state > 1) {
                return state - 1;
            }
            return state;

        case 'SETPHASE':
            if (action.phase >= 1 && action.phase <= 3) {
                return action.phase;
            }
            return state;

        default:
            return state;
    }
};

export default cartPhaseReducer;
