/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
    Menu,
    Dropdown,
} from 'antd';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShopCartContainer from '../ShopCartContainer';
import {
    getCartItem,
    getUserData,
    clearCartItem,
    setModalVisible,
} from '../../../common/redux/actions';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import LoginModalContainer from '../LoginModalContainer';
import { errorNotification } from '../../../common/Notification';

// const defaultAvatarPath = 'https://directiositeassets.blob.core.windows.net/servizi/images/avatar.jpg';
const defaultAvatarPath = 'https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-menu.png';

class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,

            userLoaded: false,
            cartItemList: {
                itemList: [],
                isLoaded: false,
            },
            cartLoaded: false,
        };

        this.showModal = this.showModal.bind(this);
        this.logout = this.logout.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userData.isLoaded && !prevState.userLoaded) {
            return {
                userLoaded: nextProps.userData.isLoaded,
            };
        }

        if (JSON.stringify(nextProps.cartItemList) !== JSON.stringify(prevState.cartItemList) && prevState.userLoaded) {
            return {
                cartItemList: nextProps.cartItemList,
                cartLoaded: nextProps.cartItemList.isLoaded,
            };
        }
        return null;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getUserData();
    }

    componentDidUpdate() {
        const { userLoaded, cartLoaded } = this.state;
        const { actions, userData } = this.props;

        if (userLoaded && !cartLoaded) {
            actions.getCartItem(userData.id);
        }
    }

    showModal() {
        const { actions } = this.props;
        actions.setModalVisible();
    }

    logout() {
        showLoadingOverlay();

        axios.post('/Account/LogOff', {})
            .then(() => {
                hideLoadingOverlay();
                window.location.href = '/';
            })
            .catch((error) => {
                hideLoadingOverlay();
                errorNotification(error.response.data);
            });
    }

    render() {
        const {
            visible,
            cartItemList,
        } = this.state;

        const { userData } = this.props;

        const profileMenu = (
            <Menu>
                <Menu.Item key="0">
                    <a href="/profile">AREA UTENTI</a>
                </Menu.Item>
                <Menu.Item key="1">
                    <a href="/account/editpassword">MODIFICA PASSWORD</a>
                </Menu.Item>
                <Menu.Item key="3">
                    <a onClick={this.logout}>ESCI</a>
                </Menu.Item>
            </Menu>
        );

        return (

            <>
                <div>
                    <div className="row ml-1">
                    {/* <div className="d-inline-flex justify-content-center align-items-center  menu_fix"> */}

                        {cartItemList.itemList
                            && (

                                <ShopCartContainer cartItems={cartItemList.itemList.length} />

                            )}
                        {!userData.isLogged ? (
                            <div className="navbar-brand">
                                <a onClick={this.showModal} className="btn_n btn_login_mobile_hide">Login</a>
                            </div>
                        ) : (

                            <>
                                <Dropdown overlay={profileMenu} trigger={['click']} placement="bottomRight">
                                    <a className="ant-dropdown-link p-1 d-flex" onClick={e => e.preventDefault()}>
                                        <img src={defaultAvatarPath} className="rounded-circle" alt="avatar" height={40} width={40} />
                                        <span className="text_menu_shop btn_login_mobile_hide m-auto">{userData.username}</span>
                                    </a>
                                </Dropdown>
                            </>

                        )}
                    </div>

                    <LoginModalContainer visible={visible} />

                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartItemList: state.cartItemList,
        userData: state.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            getCartItem: bindActionCreators(getCartItem, dispatch),
            getUserData: bindActionCreators(getUserData, dispatch),
            clearCartItem: bindActionCreators(clearCartItem, dispatch),
            setModalVisible: bindActionCreators(setModalVisible, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);


LoginContainer.propTypes = {

    cartItemList: PropTypes.shape({
        itemList: PropTypes.arrayOf(PropTypes.object),
        isLoaded: PropTypes.bool,
    }),
    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};
LoginContainer.defaultProps = {

    cartItemList: {
        itemList: [],
        isLoaded: false,
    },
    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: '',
            cognome: '',
            cf: '',
            uniCode: '',
            iva: '',
            pec: '',
            ragioneSociale: '',
            indirizzo: '',
            nCivico: '',
            cap: '',
            istatCode: '',
        },
        email: '',
        telefono: '',
        professione: '',
        tematicheInteresse: '',
        advConsent: false,
        username: '',
        isLogged: false,
        isLoaded: false,
    },
};
