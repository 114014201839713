/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import { propTypes } from 'react-bootstrap/esm/Image';

const ECommerceOtherItemsContainer = ({ Content }) => {
    const prodotto = Content[0];
    return (
        <div>
            <a href={null}>
                <div className="ecommerce_footer_title">
                    {prodotto.title}
                </div>
                <div>
                    {prodotto.dateIT}
                </div>
                {prodotto.shortDescription
                    ? (
                        <div>
                            <span className="ecommerce_footer_text" dangerouslySetInnerHTML={{ __html: `${prodotto.shortDescription}` }} />
                        </div>
                    ) : null}
            </a>
        </div>
    );
};

ECommerceOtherItemsContainer.propTypes = {
    Content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ECommerceOtherItemsContainer;
