import React from 'react';
import PropTypes from 'prop-types';

// functional components(pure components) with destructuring, implicit return and default value
const LastVideosItem = ({
	Id,
	title,
	DateIT,
	ImmagineMedia,
	Type,
}) => (
	<li className="single-content-vid">
		<h4 className="wH3">VIDEO</h4>
		<br />
		<h5 className="wH4">{DateIT}</h5>
		<br />
		<div className="img-txt">
			<div className="img">
				<a href={`/Contenuti/Videoteca/Video/${Id}`}>
					<img className="wImg" src={`${ImmagineMedia}`} alt={title} />
				</a>
			</div>
			<div className="txt">
				<p className="wP">
					<a href={`/Contenuti/Videoteca/Video/${Id}`} title={title}>{title}</a>
				</p>
			</div>
			<hr className="wHr" />
		</div>
		{Type ? <span>{Type}</span> : null }
	</li>
);

LastVideosItem.propTypes = {
	Id: PropTypes.number.isRequired,
	title: PropTypes.string,
	// shortDescription: PropTypes.string.isRequired,
	DateIT: PropTypes.string.isRequired,
	ImmagineMedia: PropTypes.string.isRequired,
	Type: PropTypes.string,
	// NiceUrl: PropTypes.string,
};

LastVideosItem.defaultProps = {
	title: '',
	Type: '',
};

export default LastVideosItem;
