import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';
import reactDom from '../../../common/react/dom/index';

import * as vars from '../../../components/variables';
import GuideSearchBoxContainer from '../../../components/frontend/guide/GuideSearchBoxContainer/GuideSearchBoxContainer';


export default function setupGuideSearchBox() {
	const {
		isGlobalSearchPage,
		isSearchPage,
		isGlobalSearchEnabled,
	} = __globalJsData;
	const eid = __globalJsData.idEdition;
	const gid = __globalJsData.idGuida;
	const hidecombo = false;
	// do render only if the dom handle is on page
	const searchbox = document.getElementById('js-searchboxReactGuide');
	if (searchbox) {
		// get the q from the query or empty string if not defined
		const initialTerm = vars.GetParams.q ? decodeURIComponent(vars.GetParams.q) : '';
		reactDom(
			<GuideSearchBoxContainer
				hidecombo={hidecombo}
				initialTerm={initialTerm}
				eid={eid}
				gid={gid}
				isGlobalSearchPage={isGlobalSearchPage}
				isSearchPage={isSearchPage}
				isGlobalSearchEnabled={isGlobalSearchEnabled}
			/>, 'js-searchboxReactGuide',
		);
	}
}
