/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShopCartContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { cartItems } = this.props;

        return (

            <div className="d-inline-flex justify-content-center align-items-center">

                <div className="position-relative">

                    <a href="/cart">
                        <img height={40} width={40} src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_carrello-menu.png" alt="cart" />
                        {cartItems > 0 && (
                            <div className="bg-secondary rounded-circle position-absolute d-flex justify-content-center align-items-center cart_item_number" style={{ height: '20px', width: '20px', top: '50%' }}>
                                {cartItems}
                            </div>
                        )}
                        <span className="text_menu_shop">CARRELLO</span>
                    </a>

                </div>

                <div>

                    <a href="/shop">
                        <img height={40} width={40} src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_catalogo-menu.png" alt="shop" />
                        <span className="text_menu_shop">CATALOGO</span>
                    </a>

                </div>

            </div>

        );
    }
}

export default ShopCartContainer;

ShopCartContainer.propTypes = {

    cartItems: PropTypes.number,

};
ShopCartContainer.defaultProps = {

    cartItems: 0,

};
