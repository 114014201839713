import React from 'react';
import PropTypes from 'prop-types';

// functional component(pure component) using destructuring { }, implicit return and default value -- {actual} di , actual
const GuideSearchResultHeadline = ({ term, total }) => (
	<div>
		<div className="search-headline">
			{total}
			{total > 1 || total === 0 ? (
				<span> Risultati </span>
			) : <span> Risultato </span>
			}
			della ricerca:&nbsp;
			<span className="search-term">
				{term}
			</span>
		</div>
	</div>
);

GuideSearchResultHeadline.propTypes = {
	total: PropTypes.number.isRequired,
	term: PropTypes.string,
};

GuideSearchResultHeadline.defaultProps = {
	term: '',
};

export default GuideSearchResultHeadline;
