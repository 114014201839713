/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';

const PaperOtherItemsContainer = ({ Item }) => {
        let contentUrl = '';
        let contentOf = 'di ';
        switch (Item.typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl = `/Articles/Details/${Item.id}/${Item.niceUrl}/?from=paper`;
                contentOf = 'di ';
                break;
            case 'news':
                contentUrl = `/News/Details/${Item.id}/${Item.niceUrl}/?from=paper`;
                contentOf = 'di ';
                break;
            case 'video':
                contentUrl = `/Video/Details/${Item.id}/${Item.niceUrl}/?from=paper`;
                contentOf = 'di ';
                break;
            default:
                break;
        }

        return (
            <div className="col-12 col-lg-3 other_item_box">
                <div className="other_item_box_img">
                    <a href={contentUrl}>
                        <img src={Item.image} className="img-fluid" alt="box" />
                    </a>
                </div>
                {(Item.thematicCategories || Item.thematics) && (
                    Item.thematicCategories.length > 0 ? (
                        Item.thematicCategories.map((thematic, index) => (
                            <span key={index} className="them_item_other">{thematic}</span>
                        ))
                    ) : (
                        Item.thematics.map((thematic, index) => (
                            <span key={index} className="them_item_other">{thematic}</span>
                        ))
                ))}
                <p className="other_box_p"><a style={{ fontFamily: 'Zilla Slab, Serif', fontWeight: 'bold', color: 'black' }} href={contentUrl}>{Item.title}</a></p>
                <span className="other_box_author">
                    {/* <PersonIcon /> */}
                    <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                    &nbsp;&nbsp;
                    <span className="article_of">{contentOf}</span>
                    {Item.authorsNames !== undefined && Item.authorsNames.map((author, index) => (
                        <span key={index}>{`${author} `}</span>
                    ))}
                </span>
            </div>
        );
};

PaperOtherItemsContainer.propTypes = {
    Item: PropTypes.shape({
        id: PropTypes.number,
        typeDoc: PropTypes.string,
        niceUrl: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        thematicCategories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
        thematics: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
        authorsNames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    }).isRequired,
};

export default PaperOtherItemsContainer;
