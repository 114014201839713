/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Form,
    Input,
    Radio,
    Select,
    Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { errorNotification } from '../../../common/Notification';

const { Option } = Select;

class ProfileFormContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {

            regioniOptions: [],
            provinceOptions: [],
            comuniOptions: [],

            userLoaded: false,
            geoLoaded: false,

            regione: '',
            provincia: '',
            comune: '',

            model: {
                clientType: 1,
                email: null,
                billingInfo: {
                    nome: null,
                    cognome: null,
                    cf: null,
                    uniCode: null,
                    pec: null,
                    iva: null,
                    ragioneSociale: null,
                    indirizzo: null,
                    nCivico: null,
                    cap: null,
                    istatCode: null,
                    irpefCode: null,
                },
                telefono: null,
                professione: null,
                tematicheInteresse: null,
                username: null,
                password: null,
                confirmPassword: null,
                advConsent: false,
                dataConsent: false,
            },
        };

        this.updateClientType = this.updateClientType.bind(this);
        this.updateNome = this.updateNome.bind(this);
        this.updateCognome = this.updateCognome.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateCf = this.updateCf.bind(this);
        this.updateIva = this.updateIva.bind(this);
        this.updateUniCode = this.updateUniCode.bind(this);
        this.updatePec = this.updatePec.bind(this);
        this.updateRagioneSociale = this.updateRagioneSociale.bind(this);
        this.updateIndirizzo = this.updateIndirizzo.bind(this);
        this.updateNCivico = this.updateNCivico.bind(this);
        this.updateCap = this.updateCap.bind(this);
        this.updateTelefono = this.updateTelefono.bind(this);
        this.updateProfessione = this.updateProfessione.bind(this);
        this.updateTematicheInteresse = this.updateTematicheInteresse.bind(this);
        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateConfirmPassword = this.updateConfirmPassword.bind(this);
        this.updateAdvConsent = this.updateAdvConsent.bind(this);
        this.updateDataConsent = this.updateDataConsent.bind(this);

        this.customFilterOptions = this.customFilterOptions.bind(this);

        this.setGeoData = this.setGeoData.bind(this);
        this.loadGeoData = this.loadGeoData.bind(this);

        this.loadRegioniOptions = this.loadRegioniOptions.bind(this);
        this.updateProvinceRegione = this.updateProvinceRegione.bind(this);
        this.updateComuniProvincia = this.updateComuniProvincia.bind(this);
        this.updateComune = this.updateComune.bind(this);

        this.submitAccount = this.submitAccount.bind(this);

        this.checkUserLogged = this.checkUserLogged.bind(this);

        this.formValidation = this.formValidation.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userData.isLoaded && !prevState.userLoaded) {
            if (nextProps.formType !== 'Register') {
                return {
                    model: nextProps.userData,
                    userLoaded: true,
                };
            }
            return { userLoaded: true };
        }
        return null;
    }

    componentDidMount() {
        this.loadRegioniOptions();
    }

    setGeoData(value) {
        let {
            model,
            provinceOptions,
            comuniOptions,
            regione,
            provincia,
            comune,
        } = this.state;

        axios.get('/api/GeoDataApi/getProvinceByRegione', {
            params: {
                sigla: value.regione.sigla,
            },
        }).then((province) => {
            if (province.data) {
                provinceOptions = [];

                province.data.forEach((item) => {
                    provinceOptions.push(<Option key={item.id} value={item.sigla}>{item.name}</Option>);
                });

                this.setState({ provinceOptions });

                axios.get('/api/GeoDataApi/getComuniByProvincia', {
                    params: {
                        sigla: value.provincia.sigla,
                    },
                }).then((comuni) => {
                    if (comuni.data) {
                        comuniOptions = [];

                        comuni.data.forEach((item) => {
                            const itemValue = JSON.stringify({
                                irpefCode: item.irpefCode,
                                istatCode: item.istatCode,
                            });

                            comuniOptions.push(<Option key={item.id} value={itemValue}>{item.name}</Option>);
                        });

                        this.setState({ comuniOptions });

                        regione = value.regione.name;
                        provincia = value.provincia.name;
                        comune = value.comune.name;
                        model.billingInfo.istatCode = value.comune.istatCode;
                        model.billingInfo.irpefCode = value.comune.irpefCode;

                        this.setState({
                            regione,
                            provincia,
                            comune,
                            model,
                        });
                    }
                }).catch((error) => {
                    errorNotification(error.response.data);
                });
            }
        }).catch((error) => {
            errorNotification(error.response.data);
        });
    }

    customFilterOptions = (inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

    loadGeoData() {
        const { model } = this.state;

        if (model.billingInfo.istatCode !== null) {
            axios.get('/api/GeoDataApi/getGeoDataByIstat', {
                params: {
                    istatCode: model.billingInfo.istatCode,
                },
            }).then(({ data }) => {
                this.setState({ geoLoaded: true });
                this.setGeoData(data);
            }).catch((error) => {
                errorNotification(error.response.data);
            });
        }
    }

    checkUserLogged() {
        const { userData } = this.props;
        const { formType } = this.props;

        if (formType === 'Register' && userData.isLogged) {
            window.location.href = '/';
        } else if (formType === 'Edit' && !userData.isLogged) {
            window.location.href = '/';
        }
    }

    updateClientType(value) {
        let { model } = this.state;
        model.clientType = value.target.value;

        if (model.clientType === 1) {
            model.billingInfo.ragioneSociale = null;
            model.billingInfo.iva = null;
            model.billingInfo.uniCode = null;
            model.billingInfo.pec = null;
        }

        this.setState({ model });
    }

    updateNome(value) {
        let { model } = this.state;
        model.billingInfo.nome = value.target.value;
        this.setState({ model });
    }

    updateCognome(value) {
        let { model } = this.state;
        model.billingInfo.cognome = value.target.value;
        this.setState({ model });
    }

    updateEmail(value) {
        let { model } = this.state;
        model.email = value.target.value;
        this.setState({ model });
    }

    updateCf(value) {
        let { model } = this.state;
        model.billingInfo.cf = value.target.value;
        this.setState({ model });
    }

    updateIva(value) {
        let { model } = this.state;
        model.billingInfo.iva = value.target.value;
        this.setState({ model });
    }

    updateUniCode(value) {
        let { model } = this.state;
        model.billingInfo.uniCode = value.target.value;
        this.setState({ model });
    }

    updatePec(value) {
        let { model } = this.state;
        model.billingInfo.pec = value.target.value;
        this.setState({ model });
    }

    updateRagioneSociale(value) {
        let { model } = this.state;
        model.billingInfo.ragioneSociale = value.target.value;
        this.setState({ model });
    }

    updateIndirizzo(value) {
        let { model } = this.state;
        model.billingInfo.indirizzo = value.target.value;
        this.setState({ model });
    }

    updateNCivico(value) {
        let { model } = this.state;
        model.billingInfo.nCivico = value.target.value;
        this.setState({ model });
    }

    updateCap(value) {
        let { model } = this.state;
        model.billingInfo.cap = value.target.value;
        this.setState({ model });
    }

    updateTelefono(value) {
        let { model } = this.state;
        model.telefono = value.target.value;
        this.setState({ model });
    }

    updateProfessione(value) {
        let { model } = this.state;
        model.professione = value.target.value;
        this.setState({ model });
    }

    updateTematicheInteresse(value) {
        let { model } = this.state;
        model.tematicheInteresse = value.target.value;
        this.setState({ model });
    }

    updateUsername(value) {
        let { model } = this.state;
        model.username = value.target.value;
        this.setState({ model });
    }

    updatePassword(value) {
        let { model } = this.state;
        model.password = value.target.value;
        this.setState({ model });
    }

    updateConfirmPassword(value) {
        let { model } = this.state;
        model.confirmPassword = value.target.value;
        this.setState({ model });
    }

    updateAdvConsent() {
        let { model } = this.state;
        model.advConsent = !model.advConsent;
        this.setState({ model });
    }

    updateDataConsent() {
        let { model } = this.state;
        model.dataConsent = !model.dataConsent;
        this.setState({ model });
    }

    loadRegioniOptions() {
        let { regioniOptions } = this.state;

        axios.get('/api/GeoDataApi/getRegioniByStato', {
            params: {
                isoCode: 'ITA',
            },
        }).then(({ data }) => {
            if (data) {
                regioniOptions = [];

                data.forEach((item) => {
                    regioniOptions.push(<Option key={item.id} value={item.sigla}>{item.name}</Option>);
                });

                this.setState({ regioniOptions });
            }
        }).catch((error) => {
            errorNotification(error.response.data);
        });
    }

    updateProvinceRegione(value) {
        let {
            model,
            provinceOptions,
            comuniOptions,
            regione,
            provincia,
            comune,
        } = this.state;

        axios.get('/api/GeoDataApi/getProvinceByRegione', {
            params: {
                sigla: value.value,
            },
        }).then(({ data }) => {
            if (data) {
                provinceOptions = [];

                data.forEach((item) => {
                    provinceOptions.push(<Option key={item.id} value={item.sigla}>{item.name}</Option>);
                });

                regione = value.label;
                provincia = '';
                comune = '';
                model.billingInfo.istatCode = null;

                this.setState({
                    model,
                    provinceOptions,
                    regione,
                    provincia,
                    comune,
                });

                comuniOptions = [];
                this.setState({ comuniOptions });
            }
        }).catch((error) => {
            errorNotification(error.response.data);
        });
    }

    updateComuniProvincia(value) {
        let {
            model,
            comuniOptions,
            provincia,
            comune,
        } = this.state;

        axios.get('/api/GeoDataApi/getComuniByProvincia', {
            params: {
                sigla: value.value,
            },
        }).then(({ data }) => {
            if (data) {
                comuniOptions = [];

                data.forEach((item) => {
                    const itemValue = JSON.stringify({
                        istatCode: item.istatCode,
                    });

                    comuniOptions.push(<Option key={item.id} value={itemValue}>{item.name}</Option>);
                });

                provincia = value.label;
                comune = '';
                model.billingInfo.istatCode = null;

                this.setState({
                    model,
                    comuniOptions,
                    provincia,
                    comune,
                });
            }
        }).catch((error) => {
            errorNotification(error.response.data);
        });
    }

    updateComune(value) {
        let { comune, model } = this.state;
        comune = value.label;
        model.billingInfo.istatCode = JSON.parse(value.value).istatCode;
        this.setState({ comune, model });
    }

    formValidation() {
        const { model } = this.state;

        const { formType } = this.props;

        if (model.email === null) {
            errorNotification('Devi inserire i campi obbligatori: Email');
            return false;
        }
        if (model.billingInfo.nome === null) {
            errorNotification('Devi inserire i campi obbligatori: Nome');
            return false;
        }
        if (model.billingInfo.cognome === null) {
            errorNotification('Devi inserire i campi obbligatori: Cognome');
            return false;
        }
        if (model.billingInfo.cf === null) {
            errorNotification('Devi inserire i campi obbligatori: Codice Fiscale');
            return false;
        }
        if (model.clientType === 2) {
            if (model.billingInfo.ragioneSociale === null) {
                errorNotification('Devi inserire i campi obbligatori: Ragione Sociale');
                return false;
            }
            if (model.billingInfo.iva === null) {
                errorNotification('Devi inserire i campi obbligatori: Partita IVA');
                return false;
            }
            if (model.billingInfo.uniCode === null && model.billingInfo.pec === null) {
                errorNotification('Devi inserire almeno 1 dei campi obbligatori: Codice Univoco o PEC');
                return false;
            }
        }
        if (formType === 'Register') {
            if (model.username === null) {
                errorNotification('Devi inserire i campi obbligatori: Username');
                return false;
            }
            if (model.password === null) {
                errorNotification('Devi inserire i campi obbligatori: Password');
                return false;
            }
            if (model.confirmPassword === null) {
                errorNotification('Devi inserire i campi obbligatori: Conferma Password');
                return false;
            }
        }

        return true;
    }

    submitAccount() {
        const { formType } = this.props;
        const { model } = this.state;

        const isFormValid = this.formValidation();

        if (isFormValid) {
            showLoadingOverlay();
            axios.post(`/Account/${formType}`,
                {
                    ...model,
                })
                .then(() => {
                    hideLoadingOverlay();
                    if (formType === 'Register') {
                        window.location.href = '/';
                    } else {
                        window.location.href = '/profile';
                    }
                })
                .catch((error) => {
                    hideLoadingOverlay();
                    errorNotification(error.response.data);
                });
        }
    }

    render() {
        const { formType } = this.props;
        const {
            model,
            regione,
            provincia,
            comune,
            regioniOptions,
            provinceOptions,
            comuniOptions,
            userLoaded,
            geoLoaded,
        } = this.state;

        if (userLoaded && !geoLoaded) {
            this.checkUserLogged();
            this.loadGeoData();
        }

        let buttonText = '';

        switch (formType) {
            case 'Register':
                buttonText = 'REGISTRATI';
                break;
            case 'Edit':
                buttonText = 'SALVA';
                break;
            default:
                break;
        }

        return (

            <>
                <Form onFinish={this.submitAccount}>
                    <div style={{ height: '30px' }} />
                    <div className="section1 row margin_lr">
                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 ">
                            <div className="p-2 bg-DF mr_header">
                                <h4><b>INSERISCI I TUOI DATI</b></h4>
                            </div>
                        </div>
                        <div className="col-md-2 col-12" />
                        <div style={{ height: '30px' }} />
                        <div className="col-md-2 col-12" />

                        <div className="col-md-8 col-12 align_table">

                            <Radio.Group
                                onChange={this.updateClientType}
                                value={model.clientType}
                            >
                                <Radio value={1}>Privato</Radio>
                                <Radio value={2}>Società/Soggetto IVA</Radio>
                            </Radio.Group>

                        </div>
                        <div className="col-md-2 col-12" />
                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 row">

                            <div className="col-md-4 col-12">
                                <label className="col-12">Nome*</label>
                                <Input value={model.billingInfo.nome} onChange={this.updateNome} placeholder="Nome" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">Cognome*</label>
                                <Input value={model.billingInfo.cognome} onChange={this.updateCognome} placeholder="Cognome" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">Indirizzo e-mail*</label>
                                <Input value={model.email} type="email" onChange={this.updateEmail} placeholder="E-mail" />
                            </div>

                        </div>
                        <div className="col-md-2 col-12" />
                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 align_table row">

                            <div className="col-md-4 col-12">
                                <label className="col-12">Codice fiscale*</label>
                                <Input value={model.billingInfo.cf} onChange={this.updateCf} className="col-12" maxLength={16} placeholder="Codice fiscale" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">
                                    Ragione Sociale
                                    {model.clientType === 2 && '*'}
                                </label>
                                <Input value={model.billingInfo.ragioneSociale} onChange={this.updateRagioneSociale} className="col-12" disabled={model.clientType === 1} placeholder="Ragione Sociale" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">
                                    Partita IVA
                                    {model.clientType === 2 && '*'}
                                </label>
                                <Input value={model.billingInfo.iva} onChange={this.updateIva} className="col-12" disabled={model.clientType === 1} maxLength={11} placeholder="Partita IVA" />
                            </div>

                        </div>
                        <div className="col-md-2 col-12" />
                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 row">

                            <div className="col-md-6 col-12">
                                <label className="col-12">
                                    Codice Univoco
                                    {model.clientType === 2 && '**'}
                                </label>
                                <Input value={model.billingInfo.uniCode} onChange={this.updateUniCode} className="col-12" disabled={model.clientType === 1 || (model.billingInfo.pec !== '' && model.billingInfo.pec !== null)} placeholder="Codice Univoco" />
                            </div>

                            <div className="col-md-6 col-12">
                                <label className="col-12">
                                    PEC
                                    {model.clientType === 2 && '**'}
                                </label>
                                <Input value={model.billingInfo.pec} onChange={this.updatePec} className="col-12" disabled={model.clientType === 1 || (model.billingInfo.uniCode !== '' && model.billingInfo.uniCode !== null)} placeholder="PEC" />
                            </div>

                            <div className="d-inline-grid col-4 row p-5" />

                        </div>
                        <div className="col-md-2 col-12" />

                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 row">

                            <div className="col-md-4 col-12">
                                <label className="col-12">Indirizzo</label>
                                <Input value={model.billingInfo.indirizzo} onChange={this.updateIndirizzo} placeholder="Indirizzo" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">N° civico</label>
                                <Input value={model.billingInfo.nCivico} onChange={this.updateNCivico} placeholder="N° civico" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">C.A.P.</label>
                                <Input value={model.billingInfo.cap} onChange={this.updateCap} placeholder="C.A.P." />
                            </div>

                        </div>
                        <div className="col-md-2 col-12" />
                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 row">


                            {regioniOptions?.length > 0 ? (

                                <div className="col-md-4 col-12">
                                    <label className="col-12">Regione</label>

                                    <Select
                                        autoComplete="chrome-off"
                                        className="col-12 p-0"
                                        showSearch
                                        placeholder="Regione"
                                        onChange={this.updateProvinceRegione}
                                        filterOption={(inputValue, option) => this.customFilterOptions(inputValue, option)}
                                        labelInValue
                                        value={regione !== '' ? { key: regione, label: regione } : undefined}
                                    >
                                        {regioniOptions}

                                    </Select>

                                </div>

                            ) : (

                                <div className="col-md-4 col-12">
                                    <label className="col-12">Regione</label>

                                    <Select
                                        autoComplete="chrome-off"
                                        className="col-12 p-0"
                                        placeholder="Regione"
                                        disabled
                                    />

                                </div>

                            )}

                            {provinceOptions?.length > 0 ? (

                                <div className="col-md-4 col-12">
                                    <label className="col-12">Provincia</label>

                                    <Select
                                        autoComplete="chrome-off"
                                        className="col-12 p-0"
                                        showSearch
                                        placeholder="Provincia"
                                        onChange={this.updateComuniProvincia}
                                        filterOption={(inputValue, option) => this.customFilterOptions(inputValue, option)}
                                        labelInValue
                                        value={provincia !== '' ? { key: provincia, label: provincia } : undefined}
                                    >
                                        {provinceOptions}
                                    </Select>

                                </div>

                            ) : (

                                <div className="col-md-4 col-12">
                                    <label className="col-12">Provincia</label>

                                    <Select
                                        autoComplete="chrome-off"
                                        className="col-12 p-0"
                                        placeholder="Provincia"
                                        disabled
                                    />

                                </div>

                            )}

                            {comuniOptions?.length > 0 ? (

                                <div className="col-md-4 col-12">
                                    <label className="col-12">Comune</label>

                                    <Select
                                        autoComplete="chrome-off"
                                        className="col-12 p-0"
                                        showSearch
                                        placeholder="Comune"
                                        onChange={this.updateComune}
                                        filterOption={(inputValue, option) => this.customFilterOptions(inputValue, option)}
                                        labelInValue
                                        value={comune !== '' ? { key: comune, label: comune } : undefined}
                                    >
                                        {comuniOptions}
                                    </Select>

                                </div>

                            ) : (

                                <div className="col-md-4 col-12">
                                    <label className="col-12">Comune</label>

                                    <Select
                                        autoComplete="chrome-off"
                                        className="col-12 p-0"
                                        placeholder="Comune"
                                        disabled
                                    />

                                </div>

                            )}

                        </div>
                        <div className="col-md-2 col-12" />
                        <div className="col-md-2 col-12" />
                        <div className="col-md-8 col-12 row">

                            <div className="col-md-4 col-12">
                                <label className="col-12">Telefono</label>
                                <Input value={model.telefono} onChange={this.updateTelefono} placeholder="Telefono" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">Professione</label>
                                <Input value={model.professione} onChange={this.updateProfessione} placeholder="Professione" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">Tematiche d&apos;interesse</label>
                                <Input value={model.tematicheInteresse} onChange={this.updateTematicheInteresse} placeholder="Tematiche d'interesse" />
                            </div>

                        </div>
                        <div className="col-md-2 col-12" />
                    </div>
                    <div style={{ height: '30px' }} />
                    {formType === 'Register'
                        ? (


                            <div className="section2  row margin_lr">
                                <div className="col-md-2 col-12" />
                                <div className="col-md-8 col-12">
                                    <div className="p-2 bg-DF mr_header">
                                        <h4><b>I TUOI DATI DI ACCESSO</b></h4>
                                    </div>
                                </div>
                                <div className="col-md-2 col-12" />
                                <div className="col-md-2 col-12" />
                                <div className="col-md-8 col-12 row">

                                    <div className="col-md-4 col-12">
                                        <label className="col-12">Nome utente*</label>
                                        <Input value={model.username} onChange={this.updateUsername} placeholder="Nome utente" />
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <label className="col-12">Password*</label>
                                        <Input value={model.password} type="password" onChange={this.updatePassword} placeholder="Password" />
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <label className="col-12">Conferma Password*</label>
                                        <Input value={model.confirmPassword} type="password" onChange={this.updateConfirmPassword} placeholder="Password" />
                                    </div>

                                    <div id="consentCheckboxes" className="p-5 w-100">

                                        <Checkbox checked={model.advConsent} onChange={this.updateAdvConsent}>
                                            Accetto di ricevere informative inerenti novità funzionali e servizi. Ho letto ed accetto l&apos;
                                            <a href="#">informativa Privacy</a>
                                        </Checkbox>

                                        <br />

                                        <Checkbox checked={model.dataConsent} onChange={this.updateDataConsent}>
                                            Presto il consenso al trattamento dei dati personali ai fini dell&apos;erogazione dei servizi disponibili sulla piattaforma.
                                            <br />
                                            Ho letto ed accetto l&apos;
                                            <a href="#">informativa Privacy</a>
                                            *
                                        </Checkbox>
                                        <br />
                                        <br />
                                        <span>*campi obbligatori</span>
                                        <br />
                                        <span>**compilare almeno un campo</span>

                                    </div>

                                </div>
                            </div>
                        ) : (

                            <div className="section2 row margin_lr">
                                <div className="col-md-2 col-12" />
                                <div className="col-md-8 col-12">

                                    <div id="consentCheckboxes" className="p-5 w-100">

                                        <div className="p-2">

                                            <Checkbox checked={model.advConsent} onChange={this.updateAdvConsent}>
                                                Accetto di ricevere informative inerenti novità funzionali e servizi. Ho letto ed accetto l&apos;
                                                <a href="#">informativa Privacy</a>
                                            </Checkbox>

                                        </div>

                                        <div className="p-2">

                                            <span>Attenzione, non è possibile eliminare l&apos;autorizzazione del trattamento dei dati per finalità tecniche legate all&apos;accesso ai servizi Directio.</span>
                                            <br />
                                            <span>Se vuoi eliminare i dati del tuo utente dal nostro data base scrivi a<a href="mailto:info@directio.it">info@directio.it</a></span>
                                            <br />
                                            <span>*campi obbligatori</span>
                                            <br />
                                            <span>**compilare almeno un campo</span>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        )
                    }

                    <div className="col-md-10 col-8">
                        <div id="formButtons" className="d-flex w-100 justify-content-end ">

                            <button type="submit" className="ant-btn btn_acquista" style={{ marginTop: '-5vw', padding: '5px 25px', marginRight: '30px' }}>{buttonText}</button>

                        </div>
                    </div>
                </Form>

            </>

        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

export default connect(mapStateToProps)(ProfileFormContainer);

ProfileFormContainer.propTypes = {

    formType: PropTypes.string,
    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};
ProfileFormContainer.defaultProps = {

    formType: 'default',
    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: '',
            cognome: '',
            cf: '',
            uniCode: '',
            iva: '',
            pec: '',
            ragioneSociale: '',
            indirizzo: '',
            nCivico: '',
            cap: '',
            istatCode: '',
        },
        email: '',
        telefono: '',
        professione: '',
        tematicheInteresse: '',
        advConsent: false,
        username: '',
        isLogged: false,
        isLoaded: false,
    },

};
