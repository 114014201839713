import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// import '../../node_modules/@fortawesome/fontawesome-free/js/all';

import login from './libs/common/login';

import configRelativesList from './libs/common/relativesList';
import configImmaginiCorrelate from './libs/common/immaginiCorrelate';
import configVideoEventiCorrelati from './libs/common/videoEventiCorrelati';
import setupSearchBox from './libs/common/setupSearchBox';

import configPageHome from './libs/frontend/configPage/home';
import configPageInformativeHome from './libs/frontend/configPage/informativehome';
import configPagePaperHome from './libs/frontend/configPage/paperhome';
import configArchivioEtichette from './libs/frontend/configPage/archivio/etichette';
import configArchivioThematics from './libs/frontend/configPage/archivio/thematics';
import configArchivioEvents from './libs/frontend/configPage/archivio/events';
import configArchivioVideo from './libs/frontend/configPage/archivio/video';
import configSponsors from './libs/frontend/configPage/fad';
import configEvents from './libs/frontend/configPage/events';
import configBP from './libs/frontend/configPage/BP';
import configReactCollapse from './libs/frontend/configPage/reactcollapse';
import configECommerce from './libs/frontend/configPage/ecommerce';
import configEditionPage from './libs/frontend/configPage/guide/edition';
import configGuidaParagrafoPage from './libs/frontend/configPage/guide/paragrafo';
import configGuidaCapitoloPage from './libs/frontend/configPage/guide/capitolo';
import configPartePage from './libs/frontend/configPage/guide/parte';
import configGuideSearchPage from './libs/frontend/configPage/guide/search';
import configWidgetAreeTematiche from './libs/frontend/configPage/widget/index';
import configShop from './libs/frontend/configPage/shop/index';
import configProfile from './libs/frontend/configPage/profile/index';
import configProfileHelpdesk from './libs/frontend/configPage/profile/helpdesk';
import configCart from './libs/frontend/configPage/cart';
import configCartCompleted from './libs/frontend/configPage/cart/completed';
import configCartFailed from './libs/frontend/configPage/cart/failed';
import configRegister from './libs/frontend/configPage/account/register';
import configEdit from './libs/frontend/configPage/account/edit';
import configEditPassword from './libs/frontend/configPage/account/editPassword';
import setupGuideSearchBox from './libs/frontend/configPage/guide/setupGuideSearchBox';
import configProductDetail from './libs/frontend/configPage/productdetail/index';
import configBpDemo from './libs/frontend/configPage/bpdemo';
import configPromo from './libs/frontend/configPage/promo';
import configPromoReport from './libs/frontend/configPage/promoReport';
import configArchivio from './libs/frontend/configPage/archivio/archivio';

// import $ from 'jquery';
import 'bootstrap';
import '../scss/main.scss';

global.jQuery = require('jquery');
global.$ = require('jquery');

const directioJsClient = {
    common: {
        init() {
            setupSearchBox();
        },
        finalize() {
            login();
            // called only from page with main-content/data-controller set
        },
    },
    Homepage: {
        init() {
            configPageHome();
      },
    },
    InformativeHomepage: {
        init() {
            configPageInformativeHome();
      },
    },
    PaperHomepage: {
        init() {
            configPagePaperHome();
      },
    },
    ContentsDetailPage: {
		init() {
            configRelativesList();
            configReactCollapse();
        },
    },
    ArchivioEtichettePage: {
        init() {
            configArchivioEtichette();
        },
    },
    ArchivioThematicsPage: {
        init() {
            configArchivioThematics();
        },
    },
    ArchivioEventsPage: {
        init() {
            configArchivioEvents();
        },
    },
    ArchivioVideoPage: {
        init() {
            configArchivioVideo();
        },
    },
    FadPage: {
        init() {
            configSponsors();
      },
    },
    LandingECommerce: {
        init() {
            configECommerce();
        },
    },
    Events: {
        init() {
            configEvents();
      },
    },
    configBPPage: {
        init() {
            configBP();
        },
    },
    configEditionPage: {
		init() {
			setupGuideSearchBox();
			configEditionPage();
		},
	},
    configGuidaParagrafoPage: {
		init() {
			setupGuideSearchBox();
			configGuidaParagrafoPage();
		},
	},
    configGuidaCapitoloPage: {
		init() {
			setupGuideSearchBox();
			configGuidaCapitoloPage();
		},
	},
    configPartePage: {
		init() {
			setupGuideSearchBox();
			configPartePage();
		},
	},
    configGuideSearchPage: {
		init() {
			setupGuideSearchBox();
			configGuideSearchPage();
		},
	},
    EventiDetailPage: {
		init() {
            configRelativesList();
            configImmaginiCorrelate();
            configVideoEventiCorrelati();
        },
    },
    configWidgetPage: {
		init() {
            configWidgetAreeTematiche();
        },
    },
    ShopPage: {
		init() {
            configShop();
        },
    },
    ProfilePage: {
		init() {
            configProfile();
        },
    },
    HelpPage: {
		init() {
            configProfileHelpdesk();
        },
    },
    CartPage: {
		init() {
            configCart();
        },
    },
    CompletedOrderPage: {
		init() {
            configCartCompleted();
        },
    },
    FailedOrderPage: {
		init() {
            configCartFailed();
        },
    },
    RegisterPage: {
		init() {
            configRegister();
        },
    },
    EditPage: {
		init() {
            configEdit();
        },
    },
    EditPasswordPage: {
		init() {
            configEditPassword();
        },
    },
    ProductDetailPage: {
		init() {
            configProductDetail();
        },
    },
    BpDemoPage: {
		init() {
            configBpDemo();
        },
    },
    PromoPage: {
		init() {
            configPromo();
        },
    },
    PromoReportPage: {
		init() {
            configPromoReport();
        },
    },
    ArchivioPage: {
		init() {
            configArchivio();
        },
    },
};

const UTIL = {
    fire(func, funcname = 'init') {
        const namespace = directioJsClient;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
            namespace[func][funcname]();
        }
    },
    loadEvents() {
        // hit up common first.
        UTIL.fire('common');
        const mainContent = document.getElementById('directio-content');
        if (mainContent === 'undefined' || mainContent === null) {
            return;
        }
        const controller = mainContent.getAttribute('data-controller');
        if (controller !== '') {
            UTIL.fire(controller);
        }
        UTIL.fire('common', 'finalize');
    },
};

// eslint-disable-next-line no-undef
$(window).on('load', () => {
    UTIL.loadEvents();
});
