import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { latestVideoEndPoint } from '../../../variables';

import VideoItem from '../VideoItem';

export default class VideoContainer extends Component {
    static get propTypes() {
        return {
            size: PropTypes.number.isRequired,
        };
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            video: [],
            start: 0,
           // size: 5,
        };
    }

    componentDidMount() {
        this.onLoadData();
    }

    onLoadData() {
        const { start } = this.state;
        const { size } = this.props;

        axios.get(`${latestVideoEndPoint}?start=${start}&size=${size}`).then(({ data }) => {
            this.setState({ video: data.resultList, loading: true });
          }).catch(() => {
            this.setState({ video: [], loading: false });
        });
    }

    render() {
        const { video, loading } = this.state;

      return (
          <div>
           {loading
            ? (


           <div className="video_container">

           {/* <div className="col-lg-12 col-lg-8 col-sm-3"> */}

                {video.map(data => (
                    <div key={data.id}>
                        <span className="video_item"><VideoItem {...data} /></span>
                    </div>
                ))}

           </div>
)
        : null }
          </div>
      );
    }
  }
