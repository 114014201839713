export default function discountReducer(state = {}, action) {
    switch (action.type) {
        case 'DISCOUNTSETSUCCESS':

            return action.discountData;

        case 'DISCOUNTRESETSUCCESS':

            return {};

        default:
            return state;
    }
}
