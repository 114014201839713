/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RowServiziContainer from '../RowServiziContainer';

class SezioniServiziContainer extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const {
            profileServices,
        } = this.props;

        return (
            <>
                <div>
                    {profileServices?.map(service => (
                        <div key={service.idServizio}>
                            <div id="title" className={`p-2 bg-${service.code}`}><h4><b>{service.titolo}</b></h4></div>
                            <div id="rows">
                                <RowServiziContainer serviceContent={service.prodottiDisponibili} />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

export default SezioniServiziContainer;

SezioniServiziContainer.propTypes = {
    profileServices: PropTypes.arrayOf(PropTypes.object),
};

SezioniServiziContainer.defaultProps = {
    profileServices: [],
};
