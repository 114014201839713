/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalVisible } from '../../../common/redux/actions';

class MenuContainer extends Component {
    constructor(props) {
        super(props);

        this.loginModal = this.loginModal.bind(this);
    }

    loginModal() {
        const { actions } = this.props;
        actions.setModalVisible();
    }

    render() {
        const { userData } = this.props;
        return (
            <div style={{ display: 'grid' }}>

                <button
                    className="navbar-toggler navbar-toggler-right"
                    style={{
                        float: 'left',
                        padding: '10px',
                        width: 'fit-content',
                        zIndex: '1000',
                    }}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">

                    <ul className="navbar-nav">

                        <li>
                            <a className="nav-link" href="/">Home</a>
                        </li>

                        <li>
                            <a className="nav-link" href="/Services/Paper/4">Informazione Economica</a>
                        </li>

                        <li>
                            <a className="nav-link" href="/Services/FAD">Formazione a distanza</a>
                        </li>

                        <li>
                            <a className="nav-link" href="/Services/BP">Business Plan</a>
                        </li>

                        <li>
                            <a className="nav-link" href="/Services/Events">Eventi</a>
                        </li>

                        <li>
                            <a className="nav-link" href="/Services/Projects">Progetti Speciali</a>
                        </li>

                        {!userData.isLogged && (
                            <li>
                                <a onClick={this.loginModal} className="btn_n mobile_show">Login</a>
                            </li>
                        )}

                    </ul>

                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setModalVisible: bindActionCreators(setModalVisible, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
