import React from 'react';
import reactDom from '../../../common/react/dom/index';

import ArchivioEtichette from '../../../components/frontend/archivio/ArchivioEtichette/index';

const { etichettaId, etichettaName } = __globalJsData;

export default function init() {
	reactDom(<ArchivioEtichette EtichettaId={etichettaId} EtichettaName={etichettaName} />, 'js-react-archivioetichette');
}
