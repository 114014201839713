import initialState from '../initialStates/cartItems/initialState';

export default function cartItemsReducer(state = initialState, action) {
    switch (action.type) {
        case 'CARTGETSUCCESS':

            localStorage.setItem('cartItems', JSON.stringify(action.cartData));
            return action.cartData;

        case 'CARTADDSUCCESS':

            localStorage.setItem('cartItems', JSON.stringify(action.cartData));
            return action.cartData;

        case 'CARTREMOVESUCCESS':

            localStorage.setItem('cartItems', JSON.stringify(action.cartData));
            return action.cartData;

        case 'CARTCLEARSUCCESS':

            localStorage.setItem('cartItems', JSON.stringify(action.cartData));
            return action.cartData;

        default:
            return state;
    }
}
