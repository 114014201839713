/* eslint-disable prefer-const */
function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
}

export default function showLoadingOverlay(text) {
    const truncatedText = (stripHtml(text)).substr(0, 200);

    if (truncatedText === stripHtml(text)) {
        return truncatedText;
    }

    const arrayText = truncatedText.split(' ');
    let finalString = '';
    for (let i = 0; i < arrayText.length - 1; i++) {
        finalString = finalString.concat(`${arrayText[i]} `);
    }
    finalString = finalString.concat('...');
    return finalString;
}
