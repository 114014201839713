import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class BannerCategoryContainer extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const {
            bannerName,
            bgColor,
            icon,
        } = this.props;
        return (
            <div className="banner_shop row margin_lr p-2" style={{ backgroundColor: bgColor, borderBottom: '1px solid gray', marginBottom: '20px' }}>
                <div className="col-md-2 col-12" />
                <div className="col-md-8 col-12">
                <img className="img-fluid header_icon" src={icon} alt="Assistenza" />
                    <h1 style={{ marginBottom: '20px' }}>
                        &nbsp;&nbsp;
                        {bannerName}
                    </h1>
                </div>
                <div className="col-md-2 col-12" />
            </div>
        );
    }
}

export default BannerCategoryContainer;

BannerCategoryContainer.propTypes = {

    bannerName: PropTypes.string,
    bgColor: PropTypes.string,
    icon: PropTypes.string,

};
BannerCategoryContainer.defaultProps = {

    bannerName: 'BANNER',
    bgColor: '#ffffff',
    icon: '',

};
