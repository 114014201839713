import React from 'react';
import PropTypes from 'prop-types';

const ECommerceUltimora = ({ Ultimora }) => (
    <div>
        <div className="row">
            <div className="col-12 float-left" style={{ padding: '0px' }}>
                {/* <h4>ULTIM&apos;ORA</h4> */}
                <div className="top_title" style={{ paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}>ULTIME NEWS SU E-COMMERCE</div>

            </div>
        </div>
        <div
            className="row"
            style={{
                paddingBottom: '15px',
                borderBottom: '1px solid #D3D3D3',
                backgroundColor: '#f7f7f7',
                justifyContent: 'center',
            }}
        >
            {Ultimora.map((value) => {
                let contentUrl = '';

                switch (value.typeDoc) {
                    case 'articolo':
                    case 'articoli':
                        contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}`;
                        break;
                    case 'news':
                        contentUrl = `/News/Details/${value.id}/${value.niceUrl}`;
                        break;
                    case 'video':
                        contentUrl = `/Video/Details/${value.id}/${value.niceUrl}`;
                        break;
                    default:
                        break;
                }
                return (
                    <div key={value.id} className="col-md-3 col-12">
                        <a className="articles_txt_ecommerce" href={contentUrl} title={value.title} rel="noopener noreferrer">
                            <div>
                                <img src={`${value.image}`} alt={value.title} className="articles_img_ecommerce" />
                            </div>
                            <h3 className="articles_txt_ecommerce">{value.title}</h3>
                        </a>
                    </div>
                );
            })}
        </div>

    </div>
);

ECommerceUltimora.propTypes = {
    Ultimora: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ECommerceUltimora;
