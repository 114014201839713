import React from 'react';
import PropTypes from 'prop-types';

const EventDetail = ({
 title, image, link, description,
}) => (
	<div>
		<a href={link} className="no_underline">
			<img className="img_video_events" src={image} alt={title} />
			<p className="title_video_events no_underline">
				{title}
				<br />
			</p>
		<p className="txt_video_events">
			<span dangerouslySetInnerHTML={{ __html: description }} />
		</p>
		</a>
	</div>
);

EventDetail.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	link: PropTypes.string,
	description: PropTypes.string,
};

EventDetail.defaultProps = {
	title: '',
	image: '',
	link: '',
	description: '',
};

export default EventDetail;
