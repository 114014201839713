import React from 'react';
import PropTypes from 'prop-types';

// functional components(pure components) with destructuring, implicit return and default value
const LastNewsItem = ({
	Id,
	title,
	DateIT,
	Type,
	NiceUrl,
}) => (
	<li className="single-content-new">
		<h4 className="wH3">NEWS</h4>
		<br />
		<h5 className="wH4">{DateIT}</h5>
		<br />
		<p className="wP">
			<a href={`/Contenuti/News/News/${Id}/${NiceUrl}`} title={title}>{title}</a>
		</p>
		{Type ? <span>{Type}</span> : null }
		<hr className="wHr" />
	</li>
);

LastNewsItem.propTypes = {
	Id: PropTypes.number.isRequired,
	title: PropTypes.string,
	// shortDescription: PropTypes.string.isRequired,
	DateIT: PropTypes.string.isRequired,
	// ImmagineMedia: PropTypes.string.isRequired,
	Type: PropTypes.string,
	NiceUrl: PropTypes.string,
};

LastNewsItem.defaultProps = {
	title: '',
	Type: '',
	NiceUrl: '',
};

export default LastNewsItem;
