import React, { Component } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class RowServiziContainer extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const {
            serviceContent,
        } = this.props;

        return (
            <>
                {serviceContent?.map(item => !item.isChildHidden && (
                    <div key={item.idProdotto} className="row d-flex align-middle row_style_services margin_lr">
                        <div className="col-md-4 col-5 row">
                            <div className="col-md-4 col-12">
                                <img className="img_product_cart" src={item.img} alt="icon" />
                            </div>
                            <div className="col-md-6 col-12 product_description_cart">
                                <span><b>{item.nome}</b></span>
                            </div>
                        </div>
                        {item.idServizio !== 35 && (
                            <div className="col-md-7 col-6 d-inline-flex align-items-center justify-content-end">
                                <a className="btn_n m-2" href={item.url}>Accedi</a>
                            </div>
                        )}
                    </div>
                ))}
            </>
        );
    }
}

export default RowServiziContainer;

RowServiziContainer.propTypes = {
    serviceContent: PropTypes.arrayOf(PropTypes.object),
};

RowServiziContainer.defaultProps = {
    serviceContent: [],
};
