import React, { Component } from 'react';
import { notification } from 'antd';

// import axios from 'axios';
// import { addUserInNewsletterEndPoint } from '../../variables';

// let retUrl = 'https://directio.it/';
// let serviziUrl = 'https://servizi.directio.it/';
// const isProd = !!(__globalJsData.env && __globalJsData.env !== '' && __globalJsData.env === 'production');
// if (!isProd) {
// 	retUrl = 'https://localhost:44305/';
// 	serviziUrl = 'https://localhost:44381/';
// }

export default class AddIntoNewsletterContainer extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			textEmail: '',
			textNome: '',
			textCognome: '',
		};

		this.saveClick = this.saveClick.bind(this);
		this.handleChangeMail = this.handleChangeMail.bind(this);
		this.handleChangeNome = this.handleChangeNome.bind(this);
		this.handleChangeCognome = this.handleChangeCognome.bind(this);
	}

	handleChangeMail(e) {
		if (e) {
			this.setState({ textEmail: e.target.value });
		}
	}

	handleChangeNome(e) {
		if (e) {
			this.setState({ textNome: e.target.value });
		}
	}

	handleChangeCognome(e) {
		if (e) {
			this.setState({ textCognome: e.target.value });
		}
	}

	saveClick() {
		const { textEmail, textCognome, textNome } = this.state;
		let hasError = false;

		if (textEmail.length === 0) {
			hasError = true;
		}
		if (textCognome.length === 0) {
			hasError = true;
		}
		if (textNome.length === 0) {
			hasError = true;
		}
		if (hasError) {
			notification.info({ message: 'Iscrizione newsletter', description: 'è necessario inserire tutti i campi' });
			return;
		}

		const url = `https://servizi.directio.it/NewsletterUser/Subscribe?nome=${textNome}&cognome=${textCognome}&email=${textEmail}`;
		window.location.href = url;
	}

	render() {
		const { textEmail, textNome, textCognome } = this.state;

		return (
			<div className="container" style={{ background: '#77b3d5' }}>
				<h3 className="banner_blue">RESTA AGGIORNATO SULLE ULTIME NOVIT&Agrave; </h3>
				<center>
					<div className="input-group">
						<input type="text" value={textNome} onChange={this.handleChangeNome} className="form_n" placeholder="nome" name="Newsletter" />
						<input type="text" value={textCognome} onChange={this.handleChangeCognome} className="form_n" placeholder="cognome" name="Newsletter" />
						<input type="text" value={textEmail} onChange={this.handleChangeMail} className="form_n" placeholder="il tuo indirizzo email" name="Newsletter" />
						<button onClick={this.saveClick} className="btn_n" type="submit" style={{ marginBottom: '10px' }}>ISCRIVITI</button>
					</div>
				</center>
			</div>
		);
	}
}
