/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import showLoadingOverlay from '../../../common/textFunctions';

import { addCartItem } from '../../../common/redux/actions';

const numberOfItemsPerRow = 3;

const ShopView = ({ shopItems }) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData);

    const shopRows = !shopItems
        ? []
        : Array.from(
            { length: Math.floor(shopItems.length / numberOfItemsPerRow) + (shopItems.length % numberOfItemsPerRow > 0 ? 1 : 0) },
            (_x, i) => {
                const startingFrom = i * numberOfItemsPerRow;
                return (
                    {
                        startIndex: startingFrom,
                        endIndex: Math.min(startingFrom + numberOfItemsPerRow, shopItems.length),
                    }
                );
            },
        );
    return (
        <>
            <div key="shop">
                {shopRows.map(row => (
                    // INIZIO SINGOLA RIGA DEL CATALOGO
                    <div className="row margin_lr bottom_divider" key={row.startIndex}>
                        {shopItems.slice(row.startIndex, row.endIndex).map(item => (
                            // SINGOLO PRODOTTO DEL CATALOGO
                            <div style={{ marginBottom: '25px' }} className="col-lg-4 col-md-6 col-12" key={item.productId}>

                                <a href={`/product/detail/?id=${item.productId}&productOrigin=${item.productOrigin}`}>
                                    <img className="img-fluid" alt="immagine prodotto" src={item.imgUrl} />
                                    <h3 className="product_title">{item.title}</h3>
                                </a>

                                <div className="product_description" dangerouslySetInnerHTML={{ __html: showLoadingOverlay(item.descriptionHtml) }} />
                                <div className="row">
                                    <div className="col-6 product_price">
                                        <h4>{(item.price).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h4>
                                        <p>+ Iva {item.ivaPercentage}%</p>
                                    </div>
                                    {!item.isBought ? (
                                        item.price > 0 ? (
                                            <div className="col-6">
                                                <a className="btn_acquista" onClick={() => dispatch(addCartItem(item, userData.id))}>Acquista</a>
                                            </div>
                                        ) : (
                                            item.macroCategory !== 'Business Plan' ? (
                                                <div className="col-6">
                                                    <a className="btn_acquista" onClick={() => dispatch(addCartItem(item, userData.id))}>Attiva</a>
                                                </div>
                                            ) : (
                                                !userData.isLogged ? (
                                                    <div className="col-6">
                                                        <a className="btn_acquista" href="/BpDemo">Attiva</a>
                                                    </div>
                                                ) : (
                                                    <div className="col-6">
                                                        <a className="btn_acquista" onClick={() => dispatch(addCartItem(item, userData.id))}>Attiva</a>
                                                    </div>
                                                )
                                            )
                                        )
                                    ) : (
                                        item.macroCategory !== 'Dichiarazione Aiuti di Stato' && (
                                            <div className="col-6">
                                                <a className="btn_acquista" href={item.linkShop}>Accedi</a>
                                            </div>
                                        )
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <a href="/"> &#60; Torna indietro </a>
        </>
    );
};

ShopView.propTypes = {
    shopItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        descriptionHtml: PropTypes.string,
        imgUrl: PropTypes.string,
        price: PropTypes.number,
        iva: PropTypes.number,
        linkShop: PropTypes.string,
    })).isRequired,
};

export default ShopView;
