/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import LiveTvIcon from '@material-ui/icons/LiveTv';

const PaperVideoBox = ({ VideoPrimoPianoList, VPIndex, HandleClickVideoPoint }) => {
    let contentUrl0 = '';
    if (VideoPrimoPianoList[0] !== undefined) {
        switch (VideoPrimoPianoList[0].typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl0 = `/Articles/Details/${VideoPrimoPianoList[0].id}/${VideoPrimoPianoList[0].niceUrl}/?from=paper`;
                break;
            case 'news':
                contentUrl0 = `/News/Details/${VideoPrimoPianoList[0].id}/${VideoPrimoPianoList[0].niceUrl}/?from=paper`;
                break;
            case 'video':
                contentUrl0 = `/Video/Details/${VideoPrimoPianoList[0].id}/${VideoPrimoPianoList[0].niceUrl}/?from=paper`;
                break;
            default:
                break;
        }
    }
    let contentUrl1 = '';
    if (VideoPrimoPianoList[1] !== undefined) {
        switch (VideoPrimoPianoList[1].typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl1 = `/Articles/Details/${VideoPrimoPianoList[1].id}/${VideoPrimoPianoList[1].niceUrl}/?from=paper`;
                break;
            case 'news':
                contentUrl1 = `/News/Details/${VideoPrimoPianoList[1].id}/${VideoPrimoPianoList[1].niceUrl}/?from=paper`;
                break;
            case 'video':
                contentUrl1 = `/Video/Details/${VideoPrimoPianoList[1].id}/${VideoPrimoPianoList[1].niceUrl}/?from=paper`;
                break;
            default:
                break;
        }
    }
    let contentUrl2 = '';
    if (VideoPrimoPianoList[2] !== undefined) {
        switch (VideoPrimoPianoList[2].typeDoc) {
            case 'articolo':
            case 'articoli':
                contentUrl2 = `/Articles/Details/${VideoPrimoPianoList[2].id}/${VideoPrimoPianoList[2].niceUrl}/?from=paper`;
                break;
            case 'news':
                contentUrl2 = `/News/Details/${VideoPrimoPianoList[2].id}/${VideoPrimoPianoList[2].niceUrl}/?from=paper`;
                break;
            case 'video':
                contentUrl2 = `/Video/Details/${VideoPrimoPianoList[2].id}/${VideoPrimoPianoList[2].niceUrl}/?from=paper`;
                break;
            default:
                break;
        }
    }

    let vpiContentUrl = '';
    switch (VPIndex) {
        case 0:
            vpiContentUrl = contentUrl0;
            break;
        case 1:
            vpiContentUrl = contentUrl1;
            break;
        case 2:
            vpiContentUrl = contentUrl2;
            break;
        default:
            vpiContentUrl = contentUrl0;
    }

    return (
        <>
            <div className="card-heading">
                <a href={vpiContentUrl}><img className="img-fluid" src={VideoPrimoPianoList[VPIndex].immagineGrandeMedia} alt="main video" /></a>
            </div>
            <div className="card-body pt-0">
                <div className="card_wrap">
                    <div className="them_video">
                        {VideoPrimoPianoList[VPIndex].thematicCategories !== undefined && VideoPrimoPianoList[VPIndex].thematicCategories.length > 0 ? (
                            VideoPrimoPianoList[VPIndex].thematicCategories.map((thematic, index) => (
                                <span key={index} className="them_item_appareance">{thematic}</span>
                            ))
                        ) : (
                            VideoPrimoPianoList[VPIndex].thematics.map((thematic, index) => (
                                <span key={index} className="them_item_appareance">{thematic}</span>
                            ))
                        )}
                    </div>
                    <div className="video_title">
                        <a href={vpiContentUrl}><p>{VideoPrimoPianoList[VPIndex].title}</p></a>
                    </div>
                    <div className="video_desc">
                        <p dangerouslySetInnerHTML={{ __html: `${VideoPrimoPianoList[VPIndex].shortDescription.substring(0, 150)}...` }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 watch_all_video">
                        <a href="/Archivio/Video">
                            <LiveTvIcon />
                            &nbsp;&nbsp;
                            <span>Guarda tutti i video</span>
                        </a>
                    </div>
                    <div className="col-4 watch_all_video dots">
                        {VideoPrimoPianoList[0] !== undefined && (
                            VPIndex === 0 ? (
                                <i
                                    role="switch"
                                    aria-checked="true"
                                    onClick={() => HandleClickVideoPoint(0)}
                                    onKeyDown={null}
                                    className="fa fa-circle"
                                    tabIndex="-2"
                                />
                            ) : (
                                <i
                                    role="switch"
                                    aria-checked="false"
                                    onClick={() => HandleClickVideoPoint(0)}
                                    onKeyDown={null}
                                    className="fa fa-circle-o"
                                    tabIndex="-2"
                                />
                            )
                        )}
                        {VideoPrimoPianoList[1] !== undefined && (
                            VPIndex === 1 ? (
                                <i
                                    role="switch"
                                    aria-checked="true"
                                    onClick={() => HandleClickVideoPoint(1)}
                                    onKeyDown={null}
                                    className="fa fa-circle"
                                    tabIndex="-1"
                                />
                            ) : (
                                <i
                                    role="switch"
                                    aria-checked="false"
                                    onClick={() => HandleClickVideoPoint(1)}
                                    onKeyDown={null}
                                    className="fa fa-circle-o"
                                    tabIndex="-1"
                                />
                            )
                        )}
                        {VideoPrimoPianoList[2] !== undefined && (
                            VPIndex === 2 ? (
                                <i
                                    role="switch"
                                    aria-checked="true"
                                    onClick={() => HandleClickVideoPoint(2)}
                                    onKeyDown={null}
                                    className="fa fa-circle"
                                    tabIndex="0"
                                />
                            ) : (
                                <i
                                    role="switch"
                                    aria-checked="false"
                                    onClick={() => HandleClickVideoPoint(2)}
                                    onKeyDown={null}
                                    className="fa fa-circle-o"
                                    tabIndex="0"
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

PaperVideoBox.propTypes = {
    VideoPrimoPianoList: PropTypes.arrayOf(PropTypes.object).isRequired,
    HandleClickVideoPoint: PropTypes.func.isRequired,
    VPIndex: PropTypes.number,
};

PaperVideoBox.defaultProps = {
    VPIndex: 0,
};

export default PaperVideoBox;
