import React from 'react';
import reactDom from '../../../common/react/dom/index';
import ECommerceContainer from '../../../components/frontend/ECommerceContainer/ECommerceContainer';

const t = __globalJsData.jsonLanding;
let landingObj = {};
if (t && t !== '') {
	landingObj = JSON.parse(t);
}
const p = __globalJsData.prodottijson;
let prodottiObj = {};
if (p && p !== '') {
	prodottiObj = JSON.parse(p);
}
const hasAccess = !!(__globalJsData.hasAccess && __globalJsData.hasAccess === 1);

export default function init() {
	reactDom(<ECommerceContainer LandingObject={landingObj} Prodotti={prodottiObj} size={10} hasAccess={hasAccess} />, 'js-react-ecommerce-container');
}
