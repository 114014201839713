import React from 'react';
import axios from 'axios';

const areaTematiche = '5,213';

// eslint-disable-next-line react/prefer-stateless-function
export default class TopWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: [],
        };
    }

    componentDidMount() {
        axios.get(`/api/WidgetBPApi/getLastBPWidgetContent/?thematics=${areaTematiche}`).then(({ data }) => {
            this.setState({
                contents: [...data],
            });
        });
    }

    render() {
        const {
            contents,
        } = this.state;

        return (
            <>
                <div className="widgetBP bp_news">

                    <div className="row fullHeight margin_lr">

                        <div className="col-9 bgPrimary">

                            <div className="row">

                                <div className="col-12 titleWidget fontWidget">

                                    APPROFONDIMENTI SU BP E CONTROLLO DI GESTIONE

                                </div>

                            </div>

                            {contents.length > 0 && (

                                <>
                                    <div className="row">

                                        <div className="col-4 pb-3" id="BParticolo">

                                            <a className="anchorBP" href={`/${contents[0].typeDoc}/Details/${contents[0].id}`}>
                                                <div className="row">

                                                    <div className="col-4">

                                                        <img className="img-fluid newsImg" src={contents[0].image} alt="articolo" />

                                                    </div>

                                                    <div className="col-7 fontWidget textNewsWidget">{contents[0].title}</div>

                                                </div>
                                            </a>

                                        </div>
                                        <div className="col-4 pb-3" id="BPvideo">

                                            <a className="anchorBP" href={`/${contents[1].typeDoc}/Details/${contents[1].id}`}>
                                                <div className="row">

                                                    <div className="col-4">

                                                        <img className="img-fluid newsImg" src={contents[1].image} alt="video" />

                                                    </div>

                                                    <div className="col-7 fontWidget textNewsWidget">{contents[1].title}</div>

                                                </div>
                                            </a>

                                        </div>
                                        <div className="col-4 pb-3" id="BPnews">

                                            <a className="anchorBP" href={`/${contents[2].typeDoc}/Details/${contents[2].id}`}>
                                                <div className="row">

                                                    <div className="col-4">

                                                        <img className="img-fluid newsImg" src={contents[2].image} alt="news" />

                                                    </div>

                                                    <div className="col-7 fontWidget textNewsWidget">{contents[2].title}</div>

                                                </div>
                                            </a>

                                        </div>

                                    </div>

                                </>
                            )}
                        </div>

                        <div className="col-3 bgSecondary mailBackground">

                            <a className="button_email" href="mailto:info@directio.it">SCRIVICI QUI</a>

                        </div>

                    </div>

                    <center>
                        <div style={{ paddingTop: '10px' }}>
                            <a className="button_read_all" href="/Archivio/Tematiche?t=5,213&numSearch=true">LEGGI TUTTO</a>
                        </div>
                    </center>

                </div>

            </>
        );
    }
}
