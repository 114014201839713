/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import reactDom from '../../../common/react/dom/index';
import TreeMenu from '../../../components/frontend/shop/TreeMenu';
import ShopView from '../../../components/frontend/shop/ShopView';
import BannerCategoryContainer from '../../../components/common/BannerCategoryContainer';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { setMacroCategory, setCategory } from '../../../common/redux/actions';

export default function init() {
    reactDom(<ShopContainer />, 'js-react-shop');
}

function ShopContainer() {
    const dispatch = useDispatch();
    const [shopItems, setShopItems] = useState([]);
    const [filteredShopItems, setFilteredShopItems] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [startingCategory, setStartingCategory] = useState(false);
    const [menuCategories, setMenuCategories] = useState([]);
    const shopFilter = useSelector(state => state.shopFilter);

    const updateProductsByMacroCategory = (macroCategoryData) => {
        const filterOption = {
            type: 'macroCategory',
            category: macroCategoryData,
        };

        dispatch(setMacroCategory(filterOption));
    };

    const updateProductsByCategory = (categoryData) => {
        const filterOption = {
            type: 'category',
            category: categoryData,
        };

        dispatch(setCategory(filterOption));
    };

    const errorNotification = (error) => {
        notification.open({
            message: 'Errore:',
            description: error,
        });
    };

    const setTreeItem = (macroCategories, categories) => {
        let tempMenuCategories = [];
        macroCategories.forEach((macroCategory) => {
            tempMenuCategories.push({ id: macroCategory.id, name: macroCategory.value, subItems: categories.filter(x => x.parentId === macroCategory.id) });
        });
        setMenuCategories(tempMenuCategories);
    };

    const setCategories = (shopItemsProp) => {
        let macroCategories = [];
        let categories = [];

        shopItemsProp.forEach((item, index) => {
            if (macroCategories.filter(x => x.value === item.macroCategory).length === 0) {
                macroCategories.push({
                    id: index,
                    value: item.macroCategory,
                });
            }
        });

        shopItemsProp.forEach((item, index) => {
            if (categories.filter(x => x.value === item.category).length === 0) {
                categories.push({
                    id: index,
                    value: item.category,
                    parentId: macroCategories.filter(x => x.value === item.macroCategory)[0].id,
                });
            }
        });

        if (macroCategories[0] === null) {
            macroCategories = [];
        }

        if (categories[0] === null) {
            categories = [];
        }

        setTreeItem(macroCategories, categories);
    };

    const getShopItems = () => {
        showLoadingOverlay();
        axios.get('/api/ShopApi/getCatalog')
            .then(({ data }) => {
                hideLoadingOverlay();
                setShopItems(data);
                setFilteredShopItems(data);
                if (data?.length > 0) {
                    setCategories(data);
                }
                setIsReady(true);
            })
            .catch((error) => {
                hideLoadingOverlay();
                errorNotification(error.response.data);
            });
    };

    useEffect(() => {
        const category = new URLSearchParams(window.location.search).get('category');
        const macroCategory = new URLSearchParams(window.location.search).get('macroCategory');

        if (!isReady) {
            getShopItems();
        }

        if (shopFilter.type === 'macroCategory') {
            setFilteredShopItems(shopItems.filter(e => e.macroCategory === shopFilter.category));
        } else if (shopFilter.type === 'category') {
            setFilteredShopItems(shopItems.filter(e => e.category === shopFilter.category));
        } else if (shopFilter.type === '') {
            setFilteredShopItems(shopItems);
        }

        if (!startingCategory && shopItems.length > 0) {
            if (category !== null && macroCategory === null) {
                updateProductsByCategory(category);
                setStartingCategory(true);
            } else if (macroCategory !== null && category === null) {
                updateProductsByMacroCategory(macroCategory);
                setStartingCategory(true);
            }
        }
    }, [isReady, shopFilter]);


    return (
        <>

            <BannerCategoryContainer bannerName="CATALOGO" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_catalogo-header.png" bgColor="#c8f246" />
            <div style={{ height: '20px' }} />
            <div className="row margin_lr">

                <div className="col-lg-2 col-md-12 col-12" style={{ paddingLeft: '5px', borderRight: '1px solid gray' }}>
                    {(menuCategories !== undefined && menuCategories.length > 0)
                        && (
                            <TreeMenu itemList={menuCategories} />
                        )
                    }
                </div>
                <div className="col-lg-10 col-md-12 col-12" style={{ paddingLeft: '50px' }}>
                    {filteredShopItems.length > 0
                        && <ShopView shopItems={filteredShopItems} />
                    }
                </div>

            </div>
        </>
    );
}
