import React from 'react';
import PropTypes from 'prop-types';

const ContentFascia4Item = ({
 title, image, link, author, thematicArea,
}) => (
	<div>
		{/* <div className="top_title">ULTIM'ORA</div> */}
		<div className="flexbox">
			<img className="news_img" src={image} alt={title} />
			<div>{thematicArea}</div>
			<a className="articles_txt" href={link} title={title}>
				<h3 className="article_txt">{title}</h3>
			</a>
			{author !== null
				? (
					<div>{author}</div>
				)
			: null }
		</div>
	</div>
);

ContentFascia4Item.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	link: PropTypes.string,
	author: PropTypes.string,
	thematicArea: PropTypes.string,
};

ContentFascia4Item.defaultProps = {
	title: '',
	image: '',
	link: '',
	author: '',
	thematicArea: '',
};

export default ContentFascia4Item;
