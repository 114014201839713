// home
export const sendMessageEndPoint = '/api/ContactApi/saveMessage';
export const addUserInNewsletterEndPoint = 'https://servizi.directio.it/api/UtenteApi/NewsletterSubscribe';
export const newsInHomeEndPoint = '/api/NewsApi/getNewsInHome';

export const dataInHomeEndPoint = '/api/HomeApi/getLatestInHome';

export const latestVideoEndPoint = '/api/VideoApi/GetLatestVideo';

export const contentsByIdEndPoint = '/api/HomeApi/contentsByIds';
export const getFullDataInformativaPageEndPoint = '/api/HomeApi/getInformativaPageData';
export const getFullDatesExpired = 'https://buffetticontentsapi.azurewebsites.net/api/Scadenze/getHomePageScadenze/lavoro,fisco,ccnl';

export const articoloRelativesContentApiEndpoint = '/api/ArticlesApi/getMultiArticles';
export const newsRelativesContentApiEndpoint = '/api/NewsApi/getMultiNews';
export const bannerRelativesContentApiEndpoint = '/api/BannerApi/getMultiBanners';
export const videoRelativesContentApiEndpoint = '/api/VideoApi/getMultiVideos';
export const lastArticlesContentApiEndpoint = '/api/ArticlesApi/getLastArticlesByCategory';
export const lastNewsContentApiEndpoint = '/api/NewsApi/getLastNewsByCategory';
export const lastVideoContentApiEndpoint = '/api/VideoApi/getLastVideoByCategory';

export const searchTermEndPoint = '/api/SearchApi/searchTermDocs';
export const sponsorsContentApiEndpoint = '/api/SponsorsApi/getSponsors';
export const spotlightContentApiEndpoint = '/api/SpotlightApi/getSpotlight';
export const eventsContentApiEndpoint = '/api/EventsApi/getEvents';
export const authorsContentApiEndpoint = '/api/AuthorsApi/getAuthorsById';
export const getGuidaCompleta = '/api/GuideApi/getGuidaCompleta';
export const getParagrafoDetails = '/api/GuideApi/getParagrafoDetails';
export const searchGuideTermEndPoint = '/api/GuideApi/searchGuideTerm';
export const WidgetEndPoint = '/api/WidgetApi/Get';
export const GetParams = {};
