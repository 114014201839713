import React from 'react';
import PropTypes from 'prop-types';

const ContentItem = ({
 title, image, link, visibleImage, author, thematicArea,
}) => (
	<div className="flexbox">
		{visibleImage ? <img className="news_img" src={image} alt={title} /> : null }
		<div>{thematicArea}</div>
		<a className="articles_txt" href={link} title={title}>
			<h3 className="articles_txt">{title}</h3>
		</a>
		{author !== null
			? (
	<div>{author}</div>
	) : null }
	</div>
);

ContentItem.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	link: PropTypes.string,
	visibleImage: PropTypes.bool,
	author: PropTypes.string,
	thematicArea: PropTypes.string,
};

ContentItem.defaultProps = {
	title: '',
	image: '',
	link: '',
	visibleImage: false,
	author: '',
	thematicArea: '',
};

export default ContentItem;
