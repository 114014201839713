import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, notification } from 'antd';
import { Player, BigPlayButton } from 'video-react';

const { VideoCorrelati } = __globalJsData;

export default class VideoPlayerContainer extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
      videoList: [],
      visible: false,
		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

  componentDidMount() {
    this.onReloadRelativesVideos();
	}

  onReloadRelativesVideos() {
    this.setState({ videoList: VideoCorrelati });
  }

	openModal() {
		this.setState({
				visible: true,
		});
	}

	closeModal() {
			this.setState({
					visible: false,
			});
	}

	render() {
		const { visible, videoList } = this.state;
		return (
      videoList.map(data => (
			<a className="col-4" key={data.Id}>
				<span onClick={this.openModal}>
					<img className="img-fluid" src="https://directiositeassets.blob.core.windows.net/servizi/home/video.jpg" alt="Video" style={{ maxHeight: 200 }} />
				</span>
				<Modal
				visible={visible}
				title="Guida Business Plan"
				centered
				onCancel={this.closeModal}
				footer={null}
				width={1420}
				bodyStyle={{ minHeight: 300 }}
				destroyOnClose
				>
					<div>
					<Player
					src={data.Url}
					fluid
					>
						<BigPlayButton position="center" />
					</Player>
					</div>
				</Modal>
			</a>
      ))
		);
	}
}
