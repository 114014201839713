/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const d = '';

export default class GuideCapitoliList extends Component {
	componentDidMount() {
		this.printFunc();
	}

	printFunc = () => {
		const GetParams = [];
		const prmstr = window.location.search.substr(1);
		const prmarr = prmstr.split('&');
		const prmarrLen = prmarr.length;
		let tmparr = [];
		let i = 0;
		for (i = 0; i < prmarrLen; i++) {
			tmparr = prmarr[i].split('=');
			GetParams[tmparr[0]] = tmparr[1];
		}
		if (GetParams.print === '1') {
			window.print();
		}
	}

	render() {
		const {
			arrayResult,
			idEdition,
			idGuida,
			resultTitle,
		} = this.props;
		return (
			<div className="container">
				<div className="row guide-central-body">
					<div className="col-sm-12 col-print-full-width guida-content">
						<h1 className="heading">{resultTitle}</h1>
						<hr className="hr" />
						{ arrayResult.map(node => (
							<div key={node.id}>
								<div className="label-paragrafo">
									PARAGRAFO&nbsp;
									{node.order}
								</div>
								<h3 className="mid-heading"><a className="color-link" href={`/Guide/Paragrafo/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`}>{node.titolo}</a></h3>
								<div className="hidden-print">{node.descrizione}</div>
								<div className="visible-print" dangerouslySetInnerHTML={{ __html: node.testo }} />
								<div><a className="hidden-print color-link read-more" href={`/Guide/Paragrafo/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`}>Leggi il paragrafo</a></div>
								<hr className="hr" />
							</div>
                         ))}
					</div>
				</div>
			</div>
		);
	}
}

GuideCapitoliList.propTypes = {
	arrayResult: PropTypes.arrayOf(PropTypes.object),
	resultTitle: PropTypes.string,
	idEdition: PropTypes.number.isRequired,
	idGuida: PropTypes.number.isRequired,
};

GuideCapitoliList.defaultProps = {
	arrayResult: [],
	resultTitle: '',
};
