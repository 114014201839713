import React from 'react';
import reactDom from '../../../common/react/dom/index';

import ArchivioAreeTematiche from '../../../components/frontend/archivio/ArchivioAreeTematiche/index';
import ArchivioAreeTematicheId from '../../../components/frontend/archivio/ArchivioAreeTematicheId/index';

const { thematics, numSearch } = __globalJsData;
let arr = [];
if (thematics && thematics !== '' && thematics !== '[]') {
    arr = JSON.parse(thematics);
}

export default function init() {
    if (numSearch === 'True') {
        reactDom(<ArchivioAreeTematicheId Thematics={arr} />, 'js-react-archiviothematics');
    } else {
        reactDom(<ArchivioAreeTematiche Thematics={arr} />, 'js-react-archiviothematics');
    }
}
