import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { dataInHomeEndPoint, getFullDataInformativaPageEndPoint, contentsByIdEndPoint } from '../../../variables';

import ContentItem from '../ContentItem';
import ContentFascia4Item from '../ContentFascia4Item';
import VideoContainer from '../VideoContainer';
import PrimoPianoContainer from '../PrimoPianoContainer';
import DatesExpiredContainer from '../DatesExpiredContainer';

export default class ContentsContainer extends Component {
    static get propTypes() {
        return {
            size: PropTypes.number.isRequired,
        };
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            dataEvidence: [],
            idFasciaPrimoPiano: [],
            idFascia3: [],
           // news: [],
            newsFascia2: [],
            newsFascia3: [],
            start: 5,
           // size: 5,
        };

        this.onLoadNews = this.onLoadNews.bind(this);
        this.onLoadContentsById = this.onLoadContentsById.bind(this);
    }

    componentDidMount() {
        this.onLoadInformativaPage();
    }

    onLoadInformativaPage() {
        axios.get(`${getFullDataInformativaPageEndPoint}?id=1`).then(({ data }) => {
            const { dataEvidence, idFasciaPrimoPiano, idFascia3 } = data;

            if (idFascia3.length > 0) {
                this.onLoadContentsById(idFascia3);
            } else {
                this.onLoadNews();
            }

            this.setState({ dataEvidence, idFasciaPrimoPiano, loading: true });
          }).catch(() => {
            this.setState({ idFasciaPrimoPiano: [], loading: false });
        });
    }

    onLoadContentsById(idFascia3) {
        axios.post(`${contentsByIdEndPoint}`, idFascia3).then(({ data }) => {
            const dataList = data;
            const fascia2 = dataList.slice(0, 4);
            const fascia3 = dataList.slice(4, 10);
            this.setState({ newsFascia2: fascia2, newsFascia3: fascia3, loading: true });
          }).catch(() => {
            this.setState({ newsFascia2: [], newsFascia3: [], loading: false });
        });
    }

    onLoadNews() {
        const { start } = this.state;
        const { size } = this.props;

        axios.get(`${dataInHomeEndPoint}?start=${start}&size=${size}`).then(({ data }) => {
            const dataList = data.resultList;
            const fascia2 = dataList.slice(0, 4);
            const fascia3 = dataList.slice(4, 10);
            this.setState({ newsFascia2: fascia2, newsFascia3: fascia3, loading: true });
          }).catch(() => {
            this.setState({ newsFascia2: [], newsFascia3: [], loading: false });
        });
    }

    render() {
        const {
 newsFascia2, newsFascia3, loading, dataEvidence, idFasciaPrimoPiano,
} = this.state;

      return (
          <div>
           {loading
            ? (


           <div className="container">
            <div className="row center_news margin_lr">
                {/* Primo piano + scadenze */}
             <div className="col-lg-8">
<PrimoPianoContainer dataEvidence={dataEvidence} itemsId={idFasciaPrimoPiano} />

             </div>
             <div className="col-lg-4" style={{ marginLeft: 'auto' }}>
                <DatesExpiredContainer />
             </div>
            </div>
                {/* Fascia 2 */}
                <div className="center_news">
                    <div className="news_container">
                            {newsFascia2.map(data => (
                                <div key={data.id}>
                                    <ContentItem visibleImage {...data} />
                                </div>
                            ))}
                    </div>
                </div>


                {/* Fascia Video Cntainer3
                <div className="row">
                    <div className="col-xs-12"> */}
                        <VideoContainer size={4} />
                    {/* </div>
                </div> */}


                {/* Fascia 3 */}
                <div className="row center_news margin_lr">
                    <div className="news_container">
                        {newsFascia3.map(data => (
                            <div key={data.id}>
                                <ContentFascia4Item {...data} />
                            </div>
                         ))}
                    </div>
                </div>
           </div>
           )
        : null }
          </div>
      );
    }
  }
