import React from 'react';
import FormHelpdesk from '../../../common/FormHelpdesk';

// eslint-disable-next-line react/prefer-stateless-function
class Helpdesk extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="bg_assistenza_header row margin_lr">

                    <div className="col-2">
                        <img className="img-fluid header_icon" src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_help-desk.png" alt="Assistenza" />
                    </div>
                    <div className="col-10">
                        <h1>MODULO ASSISTENZA DIRECTIO</h1>
                    </div>

                </div>

                <br />

                <div className="row margin_lr">
                    <div className="col-md-4 col-12" />


                    <div className="col-md-4 col-12">
                        <FormHelpdesk />
                    </div>
                    <div className="col-md-4 col-12" />

                    <div className="col-md-3 col-12 btn_torna">
                        <a href="#">&#60; Torna indietro</a>
                    </div>

                </div>
            </div>
        );
    }
}


export default Helpdesk;
