import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, notification } from 'antd';
import { Player, BigPlayButton } from 'video-react';

export default class VideoPlayerContainer extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			visible: false,
			visible1: false,
			visible2: false,

		};

		this.openModal = this.openModal.bind(this);
		this.openModal1 = this.openModal1.bind(this);
		this.openModal2 = this.openModal2.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.closeModal1 = this.closeModal1.bind(this);
		this.closeModal2 = this.closeModal2.bind(this);
	}

	componentDidMount() {

	}

	openModal() {
		this.setState({
			visible: true,
		});
	}

	openModal1() {
		this.setState({
			visible1: true,
		});
	}

	openModal2() {
		this.setState({
			visible2: true,
		});
	}

	closeModal() {
		this.setState({
			visible: false,
		});
	}

	closeModal1() {
		this.setState({
			visible1: false,
		});
	}

	closeModal2() {
		this.setState({
			visible2: false,
		});
	}

	render() {
		const { visible, visible1, visible2 } = this.state;
		return (
			<a>
				<div className="row margin_lr justify-content-center">
					<div className="col-md-3 col-12 m-5" onClick={this.openModal}>
						{/* <div className="title_video_bp">
							<i style={{ verticalAlign: 'middle' }} className="material-icons">video_library</i>
							&nbsp;
							Presentazione Generale
						</div> */}
						<img className="playerButton" src="https://directiositeassets.blob.core.windows.net/servizi/home/playerButton.png" alt="player" />
						<img className="img-fluid" src="https://directiositeassets.blob.core.windows.net/servizi/home/video.jpg" alt="Video" />
					</div>
					<Modal
						visible={visible}
						title="Presentazione Generale"
						centered
						onCancel={this.closeModal}
						footer={null}
						width={1420}
						bodyStyle={{ minHeight: 300 }}
						destroyOnClose
					>
						<div>
							<Player
								// playsInline
								poster="https://directiositeassets.blob.core.windows.net/servizi/home/video.jpg"
								src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/DEMO_BP_BERARDINO_01.mp4"
								fluid
							>
								<BigPlayButton position="center" />
							</Player>
						</div>
					</Modal>

					<div className="col-md-3 col-12 m-5" onClick={this.openModal1}>
						{/* <div className="title_video_bp">
							<i style={{ verticalAlign: 'middle' }} className="material-icons">video_library</i>
							&nbsp;
							Azienda avviata o Start up?

						</div> */}
						<img className="playerButton" src="https://directiositeassets.blob.core.windows.net/servizi/home/playerButton.png" alt="player" />
						<img className="img-fluid" src="https://directiositeassets.blob.core.windows.net/servizi/home/video2.jpg" alt="Video" />
					</div>
					<Modal
						visible={visible1}
						title="Azienda avviata o Start up?"
						centered
						onCancel={this.closeModal1}
						footer={null}
						width={1420}
						bodyStyle={{ minHeight: 300 }}
						destroyOnClose
					>
						<div>
							<Player
								// playsInline
								poster="https://directiositeassets.blob.core.windows.net/servizi/home/video2.jpg"
								src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/TUTORIAL_02_STATO_PATRIMONIALE_INIZIALE.mp4"
								fluid
							>
								<BigPlayButton position="center" />
							</Player>
						</div>
					</Modal>

					<div className="col-md-3 col-12 m-5" onClick={this.openModal2}>
						{/* <div className="title_video_bp">
							<i style={{ verticalAlign: 'middle' }} className="material-icons">video_library</i>
							&nbsp;
							I Centri Economici
						</div> */}
						<img className="playerButton" src="https://directiositeassets.blob.core.windows.net/servizi/home/playerButton.png" alt="player" />
						<img className="img-fluid" src="https://directiositeassets.blob.core.windows.net/servizi/home/video3.jpg" alt="Video" />
					</div>
					<Modal
						visible={visible2}
						title="I Centri Economici"
						centered
						onCancel={this.closeModal2}
						footer={null}
						width={1420}
						bodyStyle={{ minHeight: 300 }}
						destroyOnClose
					>
						<div>
							<Player
								// playsInline
								poster="https://directiositeassets.blob.core.windows.net/servizi/home/video3.jpg"
								src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/TUTORIAL_CENTRI_ECONOMICI_BILANCIO_STORICO.mp4"
								fluid
							>
								<BigPlayButton position="center" />
							</Player>
						</div>
					</Modal>
				</div>

			</a>
		);
	}
}
