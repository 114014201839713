/* eslint-disable no-unused-expressions, indent, react/jsx-indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import GuideSearchResultList from '../GuideSearchResultList';

import { searchGuideTermEndPoint } from '../../../variables';

import LoadingSpinner from '../../../common/LoadingSpinner';

const { CancelToken } = axios;
// const source = CancelToken.source();
let cancel;

export default class GuideSearchResultListContainer extends Component {
	// returns an obj with the initial state
	constructor(props) {
		super(props);
		const { searchTerms } = this.props;
		this.state = {
			isLoading: false,
			results: [],
			term: searchTerms,
			total: 0,
		};
	}

	componentDidMount() {
		this.onLoadResult();
	}

	onLoadResult() {
		const {
			idGuida,
			searchTerms,
			isGlobal,
			idComunita,
		} = this.props;
		cancel && (() => cancel());
		let results = [];
		let res = {};
		axios.get(`${searchGuideTermEndPoint}/?idG=${idGuida}&SearchText=${searchTerms}&isGlobal=${isGlobal}&idComunita=${idComunita}`, { cancelToken: new CancelToken((c) => { cancel = c; }) })
			.then(({ data }) => {
				if (data.data != null) {
					res = data.data;
					results = res.resultList;
				}
				this.setState({ results, isLoading: false, total: res.total });
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		this.setState({ isLoading: true });
	}

	render() {
		const {
			editionTitle,
			idEdition,
			idGuida,
			isGlobal,
		} = this.props;
		const {
			term,
			total,
			results,
			isLoading,
		} = this.state;
		return (
			(isLoading
				? (
				<div>
					Ricerca in corso...&nbsp;
					<LoadingSpinner />
				</div>
				)
				: <GuideSearchResultList items={results} term={term} total={total} editionTitle={editionTitle} idEdition={idEdition} idGuida={idGuida} isGlobal={isGlobal} />)
		);
	}
}

GuideSearchResultListContainer.propTypes = {
	editionTitle: PropTypes.string,
	idEdition: PropTypes.number.isRequired,
	idComunita: PropTypes.number,
	idGuida: PropTypes.number.isRequired,
	searchTerms: PropTypes.string,
	isGlobal: PropTypes.string,
};

GuideSearchResultListContainer.defaultProps = {
	editionTitle: '',
	idComunita: -1,
	searchTerms: '',
	isGlobal: '',
};
