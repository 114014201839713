/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { contentsByIdEndPoint } from '../../../variables';

import ContentItem from '../ContentItem';

export default class PrimoPianoContainer extends Component {
    static get propTypes() {
        return {
            dataEvidence: PropTypes.array.isRequired,
            itemsId: PropTypes.array.isRequired,
        };
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            items: [],
            data: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.onLoadNews();
    }

    onLoadNews() {
        const { itemsId, dataEvidence } = this.props;

        axios.post(`${contentsByIdEndPoint}`, itemsId).then(({ data }) => {
            const dataList = data;
            const dataPP = dataEvidence[0];
            this.setState({ items: dataList, data: dataPP, loading: true });
          }).catch(() => {
            this.setState({ items: [], loading: false });
        });
    }

    render() {
        const { items, loading, data } = this.state;
      return (
          <div>
           <div className="col-xs-8">
           {data !== undefined
            ? (
                <div className="primo_p">
                    <div>
                    {/* <div className="col-lg-6"> */}
                        <img src={data.immagine} alt={data.titolo} title={data.titolo} style={{ width: '400px' }} />
                    </div>
                    <div>
                    {/* <div className="col-lg-6"> */}
                        <div style={{ marginLeft: '25px' }}>
                            <p>IN PRIMO PIANO</p>
                            <a href={data.url} title={data.titolo}><h3>{data.titolo}</h3></a>
                            <p dangerouslySetInnerHTML={{ __html: data.testo }} />
                        </div>
                    </div>
                </div>
)
                : null}
                <div />
                {loading
                ? (
<div>
{items.map(d => (
                        <div key={d.id}>
                            <ContentItem visibleImage={false} {...d} />
                        </div>
                    ))}
</div>
)
                : null }
           </div>
          </div>
      );
    }
  }
