import React, { Component } from 'react';
import { notification } from 'antd';

import axios from 'axios';
import { sendMessageEndPoint } from '../../variables';

export default class ContactUsContainer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            textMessage: '',
		};

		this.saveClick = this.saveClick.bind(this);
		this.handleChangeText = this.handleChangeText.bind(this);
    }

	handleChangeText(e) {
		if (e) {
			this.setState({ textMessage: e.target.value });
		}
	}

	saveClick() {
		const { textMessage } = this.state;
		let hasError = false;

		if (textMessage.length === 0) {
			hasError = true;
		}

		if (hasError) {
			notification.info({ message: 'Richiesta contatto', description: 'è necessario inserire il testo' });
			return;
		}
		axios.post(sendMessageEndPoint, { text: textMessage }).then(() => {
				notification.success({ message: 'Richiesta contatto', description: 'richiesta contatto inviato con successo' });
		}).catch((error) => {
				console.log(error);
				notification.error({ message: 'Richiesta contatto', description: 'errore invio contatti ' });
		});
	}

    render() {
        const { textMessage } = this.state;

      return (
        <section className="mb-4">
            <h2 className="banner_contact">CONTATTI</h2>
            <div className="margin_contact">


            <div className="text-center">
            <div className="container">
                    {/* <div className="item">
                        &nbsp;
                    </div> */}
                        <div className="item2">

                            <a href="https://goo.gl/maps/W8Bwuzpn6sGDYEZX9" target="_blank" rel="noreferrer">
                            <p className="contact">
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/map.png" alt="via" />
                            &nbsp;
                                Corso Inghilterra 47, 10138, Torino
                            </p>
                            </a>
                        </div>
                        <div className="item2">

                            <a href="https://goo.gl/maps/GKTvHGHdd97twdqM6" target="_blank" rel="noreferrer">

                            <p className="contact">
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/map.png" alt="via" />
                            &nbsp;
                            Via Fossata 123, 10147, Torino
                            </p>

                            </a>
                        </div>
                        <div className="item2">

                            <p className="contact">
                            <a href="mailto:info@directio.it">
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/email.png" alt="email" />
                            &nbsp;
                                info@directio.it
                            </a>
                            </p>
                        </div>
                        <div className="item2">

                            <p className="contact">
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/phone.png" alt="telefono" />
                            &nbsp;
                            + 39.011.5609007
                            </p>
                        </div>
                        <div className="item2">

                            <p className="contact">
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/fax.png" alt="fax" />
                            &nbsp;
                            + 39.011.5660603
                            </p>
                        </div>
            </div>
                    {/* <div className="col-12 col-md-1">
                        &nbsp;
                    </div> */}

                {/* <div className="text-center">
                    <ul className="list-unstyled mb-0">
                        <li>
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/map.png" alt="via" />
                            <a href="https://goo.gl/maps/W8Bwuzpn6sGDYEZX9" target="_blank" rel="noreferrer">
                            <p className="contact">Corso Inghilterra 47, 10138, Torino</p>
                            </a>
                        </li>
                        <li>
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/map.png" alt="via" />
                            <a href="https://goo.gl/maps/GKTvHGHdd97twdqM6" target="_blank" rel="noreferrer">
{' '}
<p className="contact">Via Fossata 123, 10147, Torino</p>
{' '}
                            </a>
                        </li>
                        <li>
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/email.png" alt="email" />
                            <p className="contact"><a href="mailto:info@directio.it">info@directio.it</a></p>
                        </li>
                        <li>
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/phone.png" alt="telefono" />
                            <p className="contact">+ 39.011.5609007</p>
                        </li>
                        <li>
                            <img className="img_contact" src="https://directiositeassets.blob.core.windows.net/servizi/home/fax.png" alt="fax" />
                            <p className="contact">+ 39.011.5660603</p>
                        </li>
                    </ul> */}
            </div>
                {/* <div className="col-lg-9 mb-md-0 mb-5">
                    <div className="row">
                        <textarea type="text" id="message" value={textMessage} onChange={this.handleChangeText} name="message" rows="6" className="form-contact" placeholder="Scrivi il tuo messaggio" />
                    </div>
					<button onClick={this.saveClick} className="btn_n" type="submit">INVIA</button>
                </div> */}
            </div>
        </section>
      );
    }
}
