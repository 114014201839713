/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderProcessBar from '../../../common/OrderProcessBar';
import BannerCategoryContainer from '../../../common/BannerCategoryContainer';
import { setPhase, clearCartItem } from '../../../../common/redux/actions';
import OrderCompletedPhase from '../../../common/OrderPhasesContainer/OrderCompletedPhase/OrderCompletedPhase';

// eslint-disable-next-line react/prefer-stateless-function
class CartCompletedView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userLoaded: false,
            cartCleared: false,
        };

        this.clearCart = this.clearCart.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userData.isLoaded && !prevState.userLoaded) {
            return {
                userLoaded: true,
            };
        }
        return null;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.setPhase(3);
    }

    clearCart() {
        const { actions, userData } = this.props;
        this.setState({ cartCleared: true });
        actions.clearCartItem(userData.id);
    }

    render() {
        const { userLoaded, cartCleared } = this.state;

        if (userLoaded && !cartCleared) {
            this.clearCart();
        }

        return (
            <>
                <BannerCategoryContainer bannerName="CARRELLO" bgColor="#C8F344" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_carrello-header.png" />

                <OrderProcessBar />
                <OrderCompletedPhase />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setPhase: bindActionCreators(setPhase, dispatch),
            clearCartItem: bindActionCreators(clearCartItem, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartCompletedView);

CartCompletedView.propTypes = {

    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};

CartCompletedView.defaultProps = {
    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: '',
            cognome: '',
            cf: '',
            uniCode: '',
            iva: '',
            pec: '',
            ragioneSociale: '',
            indirizzo: '',
            nCivico: '',
            cap: '',
            istatCode: '',
        },
        email: '',
        telefono: '',
        professione: '',
        tematicheInteresse: '',
        advConsent: false,
        username: '',
        isLogged: false,
        isLoaded: false,
    },
};
