import React from 'react';
import reactDom from '../../../common/react/dom/index';
import ReactCollapse from '../../../components/common/ReactCollapse';

const authors = __globalJsData.Authors;
const author = __globalJsData.Author;

export default function init() {
	reactDom(<ReactCollapse Authors={authors} />, 'js-react-collapse');
}
