export default {
    id: 0,
    clientType: 1,
    billingInfo: {

        nome: '',
        cognome: '',
        cf: '',
        uniCode: '',
        iva: '',
        pec: '',
        ragioneSociale: '',
        indirizzo: '',
        nCivico: '',
        cap: '',
        istatCode: '',
    },
    email: '',
    telefono: '',
    professione: '',
    tematicheInteresse: '',
    advConsent: false,
    username: '',
    isLogged: false,
    isLoaded: false,
};
