/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-first-prop-new-line */
import React from 'react';
import PropTypes from 'prop-types';

const NewsItem = ({
	id, title, dateIT, image,
}) => (
	<div className="mod-correlati--item row">
		<div className="col-4 img-correlata">
			<img className="img-fluid" src={image} alt="immagineNews" />
		</div>
		<div className="col-8 testo-correlato">
		<a href={`/News/Details/${id}`} title={title}>
			<div className="d-flex w-100 justify-content-between font-barlow">
				<div style={{ clear: 'both' }}>
					{/* {Thematics.map((thematic, i) => <span key={i} style={{ paddingTop: '3px!important' }} className="list-item-areacommerciale">{thematic}</span>)} */}
					<small className="text-muted">{dateIT}</small>
				</div>
			{/* <div style={{ clear: 'both' }}>
					{CategoriesAreas.map((thematic, i) => <span className="list-item-areatematica" style={{ display: 'block', paddingTop: '3px' }} key={i}>{thematic}</span>)}
				</div> */}
			</div>

			<p className="text_corelati font-zillaslab">{title}</p>
{/* { Category != null && Category.length > 0 && (
<div className="list-category">
	<span className="list-item-category">{Category}</span>
</div>
)} */}
		</a>
		</div>
	</div>
);

NewsItem.propTypes = {
	id: PropTypes.number,
	title: PropTypes.string,
	// CategoriesAreas: PropTypes.arrayOf(PropTypes.string),
	dateIT: PropTypes.string,
	image: PropTypes.string,
	// Thematics: PropTypes.arrayOf(PropTypes.string),
	// Category: PropTypes.string,
	// NiceUrl: PropTypes.string,
};

NewsItem.defaultProps = {
	id: 0,
	title: '',
	image: '',
	// CategoriesAreas: [],
	dateIT: '',
	// Thematics: [],
	// Category: '',
	// NiceUrl: '',
};

export default NewsItem;
