/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import axios from 'axios';
import Modal from 'react-awesome-modal';
import Skeleton from 'react-loading-skeleton';
import PersonIcon from '@material-ui/icons/Person';
import ECommerceCampaigns from './items/ECommerceCampaigns';
import ECommerceUltimora from './items/ECommerceUltimora';
import ECommerceCoreContents from './items/ECommerceCoreContents';
import ECommerceOtherItemsContainer from './items/ECommerceOtherItemsContainer';
import EcommerceCollapse from './items/ECommerceCollapse';

export default class ECommerceContainer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            landingObject: props.LandingObject,
            prodotti: props.Prodotti,
            news: [],
            newsLoading: true,
            ultimora: [],
            ultimoraLoading: true,
            vpindex: 0,
            videoPrimoPianoList: [],
            videoPrimoPianoLoading: true,
            articoliNewsPrimoPiano: [],
            articoliNewsPrimoPianoLoading: true,
            ApproPrimoPiano: [],
            ApproPrimoPianoLoading: true,
            otherArticoliNewsVideo: [],
            otherArticoliNewsVideoLoading: true,
            contenutiFiscoList: [],
            contenutiFiscoLoading: true,
            contenutiLavoroList: [],
            contenutiLavoroLoading: true,
            contenutiDirittoList: [],
            textIssue: '',
            showDrop: false,
            visible: false,
            isOpened: false,
        };

        this.handleClickVideoPoint = this.handleClickVideoPoint.bind(this);
        this.handleChangeTextIssues = this.handleChangeTextIssues.bind(this);
        this.loadContenutiTematicaFisco = this.loadContenutiTematicaFisco.bind(this);
        this.loadContenutiTematicaLavoro = this.loadContenutiTematicaLavoro.bind(this);
        this.loadContenutiTematicaDiritto = this.loadContenutiTematicaDiritto.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onClickFAD = this.onClickFAD.bind(this);
    }

    componentDidMount() {
        const { landingObject } = this.state;

        this.loadLastHours();

        if (landingObject.CampagneLandingIds && landingObject.CampagneLandingIds.length > 0) {
            this.loadNews(landingObject.CampagneLandingIds);
        }
        if (landingObject.NewsArticoliPrimoPianoCorrelati && landingObject.NewsArticoliPrimoPianoCorrelati.length > 0) {
            this.loadArticoliNewsPrimoPiano(landingObject.NewsArticoliPrimoPianoCorrelati);
        }
        if (landingObject.VideoPrimoPianoCorrelati && landingObject.VideoPrimoPianoCorrelati.length > 0) {
            this.loadVideoPrimoPiano(landingObject.VideoPrimoPianoCorrelati);
        }
        if (landingObject.NewsArticoliVideoOtherCorrelati && landingObject.NewsArticoliVideoOtherCorrelati.length > 0) {
            this.loadOtherArticoliNewsVideo(landingObject.NewsArticoliVideoOtherCorrelati);
        }
        if (landingObject.ApproPrimoPianoCorrelati && landingObject.ApproPrimoPianoCorrelati.length > 0) {
            this.loadApproPrimoPiano(landingObject.ApproPrimoPianoCorrelati);
        }
    }

    onClickFAD() {
        const { isOpened } = this.state;
        if (isOpened === false) {
            this.setState({ isOpened: true });
        } else {
            this.setState({ isOpened: false });
        }
    }

    displaySearch = () => {
        const x = document.getElementById('js-searchboxReact');
        if (x) {
            if (x.style.display === 'block') {
                x.style.display = 'none';
            } else {
                x.style.display = 'block';
            }
        }
    }

    loadNews(newsIds) {
        if (newsIds) {
            axios.get(`/api/NewsApi/getLastNewsByCategory/?id=${newsIds}`)
                .then(({ data: { resultList } }) => {
                    this.setState({ news: resultList.slice(0), newsLoading: false });
                })
                .catch((error) => {
                    let e = 'Errore caricamento contenuti';
                    if (error) {
                        e += ` ${error}`;
                    }
                    notification.info({
                        message: e,
                    });
                    this.setState({ newsLoading: false });
                });
            this.setState({ newsLoading: true });
        }
    }

    loadLastHours() {
        axios.post('/api/LandingApi/loadLastHours')
            .then(({ data: { resultList } }) => {
                const idsToExclude = resultList.map(a => a.id);
                this.setState({ ultimora: resultList, ultimoraLoading: false }, () => {
                    this.loadContenutiTematicaFisco(idsToExclude);
                    this.loadContenutiTematicaLavoro(idsToExclude);
                    this.loadContenutiTematicaDiritto(idsToExclude);
                });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ ultimoraLoading: false });
            });
        this.setState({ ultimoraLoading: true });
    }

    loadVideoPrimoPiano(videoIds) {
        if (videoIds) {
            axios.post('/api/LandingApi/loadVideoPrimoPiano', {
                videoIds,
            })
                .then(({ data: { resultList } }) => {
                    this.setState({ videoPrimoPianoList: resultList, videoPrimoPianoLoading: false });
                })
                .catch((error) => {
                    let e = 'Errore caricamento contenuti';
                    if (error) {
                        e += ` ${error}`;
                    }
                    notification.info({
                        message: e,
                    });
                    this.setState({ videoPrimoPianoLoading: false });
                });
            this.setState({ videoPrimoPianoLoading: true });
        }
    }

    loadArticoliNewsPrimoPiano(ids) {
        axios.post('/api/LandingApi/loadArticoliNewsPrimoPiano', {
            ids,
        })
            .then(({ data: { resultList } }) => {
                this.setState({ articoliNewsPrimoPiano: resultList, articoliNewsPrimoPianoLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ articoliNewsPrimoPianoLoading: false });
            });
        this.setState({ articoliNewsPrimoPianoLoading: true });
    }

    loadApproPrimoPiano(appros) {
        axios.post('/api/LandingApi/loadApprofondimentiLanding', {
            appros,
        })
            .then(({ data: { resultList } }) => {
                this.setState({ ApproPrimoPiano: resultList, ApproPrimoPianoLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ ApproPrimoPianoLoading: false });
            });
        this.setState({ ApproPrimoPianoLoading: true });
    }

    loadOtherArticoliNewsVideo(ids) {
        axios.post('/api/LandingApi/loadOtherArticoliNewsVideo', {
            ids,
        })
            .then(({ data: { resultList } }) => {
                this.setState({ otherArticoliNewsVideo: resultList, otherArticoliNewsVideoLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ otherArticoliNewsVideoLoading: false });
            });
        this.setState({ otherArticoliNewsVideoLoading: true });
    }

    loadContenutiTematicaFisco(idsToExclude) {
        axios.post('/api/LandingApi/loadContentsByThem', {
            idsToExclude,
            them: 'fisco',
        })
            .then(({ data: { resultList } }) => {
                this.setState({ contenutiFiscoList: resultList, contenutiFiscoLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ contenutiFiscoLoading: false });
            });
        this.setState({ contenutiFiscoLoading: true });
    }

    loadContenutiTematicaLavoro(idsToExclude) {
        axios.post('/api/LandingApi/loadContentsByThem', {
            idsToExclude,
            them: 'lavoro',
        })
            .then(({ data: { resultList } }) => {
                this.setState({ contenutiLavoroList: resultList, contenutiLavoroLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ contenutiLavoroLoading: false });
            });
        this.setState({ contenutiLavoroLoading: true });
    }

    loadContenutiTematicaDiritto(idsToExclude) {
        axios.post('/api/LandingApi/loadContentsByThem', {
            idsToExclude,
            them: 'diritto',
        })
            .then(({ data: { resultList } }) => {
                this.setState({ contenutiDirittoList: resultList, contenutiDirittoLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ contenutiDirittoLoading: false });
            });
        this.setState({ contenutiDirittoLoading: true });
    }

    handleClickVideoPoint(vpindex) {
        this.setState({ vpindex });
    }

    handleChangeTextIssues(evt) {
		this.setState({ textIssue: evt.target.value });
	}

    openModal() {
        this.setState({
            visible: true,
        });
    }

    closeModal() {
        this.setState({
            visible: false,
        });
    }

    render() {
        const {
            landingObject,
            prodotti,
            showDrop,
            ...state
        } = this.state;
        const { LandingObject, Prodotti } = this.props;
        const showDiv = showDrop ? '' : 'hidden';
        return (
            <div className="dp_paper_content">
                <div className="dp_paper_content_container">
                    {/* <div className="row dp_paper_content_header">
                        <div className="col-3">
                            <a href="#" onClick={() => { this.setState({ showDrop: !showDrop }); }} className="rss_anchor">
                                <i className="fa fa-rss rss_icon" />
                            </a>
                            <div className={showDiv}>
                                <a href="/api/RSSApi/getRSSNews" target="_blank">
                                    <i className="fa fa-rss rss_icon" />
                                    News RSS
                                </a>
                                <br />
                                <a href="/api/RSSApi/getRSSVideo" target="_blank">
                                    <i className="fa fa-rss rss_icon" />
                                    Video RSS
                                </a>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="brand_container text-center">
                                <img className="img-fluid" src="https://directiositeassets.blob.core.windows.net/servizi/Images/logo.png" alt="Directio" />
                                <span>{landingObject.DataHeaderQuotidiano}</span>
                                <small>Direttore responsabile Alessio Berardino - Reg. Trib. Torino - n.10 - 29/04/2013</small>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="search_subscribe">
                                <a href={null} onClick={() => this.displaySearch()} className="search_item d-none d-sm-block"><i className="fa fa-search" /></a>
                                <a href="/#subscribe" className="btn btn-primary subscribe_item">REGISTRATI</a>
                            </div>
                        </div>
                    </div> */}
                    <div className="row margin_lr" style={{ backgroundColor: '#7eb4d4' }}>
                        <img className="img-fluid" src="https://redazionesorage.blob.core.windows.net/static-files/Header-i-verticali-foto.jpg" alt="placeholder banner" style={{ paddingRight: 0 }} />
                    </div>
                    <div className="row margin_lr">
                    <div className="col-1" />
                        <div className="col-md-7 col-12">
                            <h3 className="title_ecommerce">
                                Tutte le novità 2021 e gli approfondimenti operativi per sviluppare al megliola tua attività di commercio online
                            </h3>
                            <div className="subtitle_ecommerce">
                                &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                        </div>
                        <div
                            className="col-md-4 col-12"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <a className="btn_n" style={{ padding: '10px 50px', marginBottom: '15px' }}>Acquista</a>
                        </div>
                    </div>
                    <div className="row dp_paper_core dp_paper_ultimora margin_lr" style={{ padding: '0px' }}>
                        <div className="col-12 dp_paper_ultimora_col12">
                            {!state.ultimoraLoading && state.ultimora && state.ultimora.length > 0 ? (
                                <ECommerceUltimora Ultimora={state.ultimora} />
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                    {/* <div className="row dp_paper_maintitle" style={{ paddingRight: '85px', paddingLeft: '85px' }}>
                        <div className="col-12 dp_paper_maintitle_col12 text-center">
                            {Object.entries(landingObject).length > 0 && landingObject.ShortDescription && landingObject.ShortDescription !== '' && (
                                <div dangerouslySetInnerHTML={{ __html: landingObject.ShortDescription }} />
                            )}
                        </div>
                    </div> */}

                    <div className="row dp_paper_core margin_lr" style={{ backgroundColor: '#c8f343' }}>
                        <h2 className="col-12" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '40px' }}>
                            Tutti i punti critici per avviare un e-commerce!
                        </h2>
                        <div style={{ padding: '40px 50px' }}>
                            <div className="row">
                            <div className="col-md-1 col-12" />
                                {!state.ApproPrimoPianoLoading && state.ApproPrimoPiano.length > 0 ? (
                                    <ECommerceCoreContents ApproPrimoPiano={state.ApproPrimoPiano.slice(0, 4)} openModal={this.openModal} />
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                            <div className="row">
                            <div className="col-1" />
                                {!state.ApproPrimoPianoLoading && state.ApproPrimoPiano.length > 0 ? (
                                    <ECommerceCoreContents ApproPrimoPiano={state.ApproPrimoPiano.slice(4)} openModal={this.openModal} />
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row dp_paper_core margin_lr">
                    <div className="col-md-1 col-12" />
                        <div className="col-md-3 col-12">
                        <h4 className="title_section3_ecommerce">FORMAZIONE SPECIALISTICA</h4>
                            <div style={{ alignItems: 'center' }} onClick={() => this.onClickFAD()}>
                                {LandingObject && Object.entries(LandingObject).length > 0 && (
                                    <ECommerceOtherItemsContainer RiferimentiProdotti={Prodotti} openModal={this.openModal} />
                                )}
                                {/* {!state.newsLoading && state.news.length > 0 ? (
                                    <div className="col-6">
                                        <h4>NEWSLETTER TEMATICA</h4>
                                        <ECommerceCampaigns Content={state.news} />
                                    </div>
                                ) : (
                                    <Skeleton />
                                )} */}
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                        {!state.newsLoading && state.news.length > 0 ? (
                                    <span>
                                        <h4 className="title_section3_ecommerce">NEWSLETTER TEMATICA</h4>
                                        <ECommerceCampaigns Content={state.news} />
                                    </span>
                                ) : (
                                    <Skeleton />
                                )}
                        </div>

                        <div className="col-md-4 col-12" style={{ backgroundColor: '#7eb4d4' }}>
                            <h4 className="title_assist_ecommerce">ASSISTENZA E SUPPORTO</h4>
                            <div style={{ paddingTop: 30 }}>
                                Hai un problema nella gestione del tuo e-commerce?
                                <br />
                                Chiedi supporto allo Studio Santacroce &amp; Partners
                            </div>
                            <div style={{ paddingTop: 30 }}>
                               <input type="text" rows="4" cols="50" className="textbox_style" placeholder="Descrivi il tuo caso" value={state.textIssue} onChange={this.handleChangeTextIssues} />
                            </div>
                            <div className="float-end" style={{ paddingTop: 30 }}>
                               <a className="btn_n">
                                   Invia
                               </a>
                            </div>
                        </div>
                        <div className="col-md-1 col-12" />
                        {/* <div className="row">
                            <EcommerceCollapse isOpened={state.isOpened} FADs={Prodotti.slice(1)} />
                        </div> */}
                    </div>
                    {/* mt-5    other_items_container" */}
                    {/* <div className="row dp_paper_core" style={{ marginTop: '0px !important' }}>

                        {!state.otherArticoliNewsVideoLoading && state.otherArticoliNewsVideo && state.otherArticoliNewsVideo.length > 0 ? (
                            state.otherArticoliNewsVideo.slice(0, 4).map(value => (
                                <ECommerceOtherItemsContainer Item={value} key={`${value.typeDoc}_${value.id}`} />
                            ))
                        ) : (
                            <Skeleton />
                        )}
                    </div> */}
                    {/* <div className=" dp_paper_core row mt-5 mt-5 mt-md-4 random_thematic_items_container">
                        <div className="col-12 col-lg-4 random_thematic_item lavoro">
                            <div className="rti_heading">
                                <strong>LAVORO</strong>
                                <a href="/Archivio/Tematiche?t=lavoro">Vedi tutti</a>
                            </div>
                            <div className="row rti_body">
                                {!state.contenutiLavoroLoading && state.contenutiLavoroList && state.contenutiLavoroList.length > 0 ? (
                                    state.contenutiLavoroList.map((value, i) => {
                                        let contentUrl = '';
                                        let contentOf = 'di ';
                                        if (value !== undefined) {
                                            switch (value.typeDoc) {
                                                case 'articolo':
                                                case 'articoli':
                                                    contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'news':
                                                    contentUrl = `/News/Details/${value.id}/${value.niceUrl}`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'video':
                                                    contentUrl = `/Video/Details/${value.id}/${value.niceUrl}`;
                                                    contentOf = 'di ';
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        return (
                                            <div key={`${value.id}_${value.typeDoc}`} className={i === 0 ? 'bordered' : null}>
                                                <div className="col-12 col-lg-6 rti_body_img">
                                                    <a href={contentUrl}><img src={value.image} alt="rti img" className="img-fluid" /></a>
                                                </div>
                                                <div className="col-12 col-lg-6 rti_body_txt">
                                                    {(value.thematicCategories || value.thematics) && (
                                                        value.thematicCategories.length > 0 ? (
                                                            value.thematicCategories.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                        ) : (
                                                            value.thematics.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                    ))}
                                                    <a href={contentUrl}><h4>{value.title}</h4></a>
                                                    <span className="rti_body_txt_author">
                                                        <PersonIcon />
                                                        <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                                        &nbsp;&nbsp;
                                                        <span className="article_of">{contentOf}</span>
                                                        {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                            <span key={index}>{`${author} `}</span>
                                                        ))}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 random_thematic_item diritto">
                            <div className="rti_heading">
                                <strong>DIRITTO</strong>
                                <a href="/Archivio/Tematiche?t=diritto">Vedi tutti</a>
                            </div>
                            <div className="row rti_body">
                                {!state.contenutiDirittoLoading && state.contenutiDirittoList && state.contenutiDirittoList.length > 0 ? (
                                    state.contenutiDirittoList.map((value, i) => {
                                        return (
                                            <div key={`${value.id}_${value.typeDoc}`} className={i === 0 ? 'bordered' : null}>
                                                <div className="col-12 col-lg-6 rti_body_img">
                                                    <a href={contentUrl}><img src={value.image} alt="rti img" className="img-fluid" /></a>
                                                </div>
                                                <div className="col-12 col-lg-6 rti_body_txt">
                                                    {(value.thematicCategories || value.thematics) && (
                                                        value.thematicCategories.length > 0 ? (
                                                            value.thematicCategories.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                        ) : (
                                                            value.thematics.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                    ))}
                                                    <a href={contentUrl}><h4>{value.title}</h4></a>
                                                    <span className="rti_body_txt_author">
                                                        <PersonIcon />
                                                        <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                                        &nbsp;&nbsp;
                                                        <span className="article_of">{contentOf}</span>
                                                        {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                            <span key={index}>{`${author} `}</span>
                                                        ))}
                                                    </span>

                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <Modal
					visible={state.visible}
					width="600"
					height="325"
					effect="fadeInUp"
					onClickAway={() => this.closeModal()}
                >
				{!state.hasAccess ? (
						<div>
								<h1 style={{ padding: '5px' }}>Indici & Aliquote</h1>
								<hr />
								<div style={{ padding: '5px' }}>
									<p>Gentile {state.userName === '' ? 'utente' : state.userName}</p>
									<p>Devi avere attivo uno tra i prodotti Consulenza Banche Dati Fisco, Lavoro o CCNL per poter fruire degli Indici & Aliquote</p>
								</div>
								<hr />
								<a href="/Shop/Catalogo/1?cat=10" target="_blank" rel="noopener noreferrer" className="btn btn-buffetti-default pull-right" style={{ marginRight: '15px' }}>SHOP</a>
								<a href={null} className="btn btn-buffetti-default pull-right" style={{ marginRight: '15px' }} onClick={() => this.closeModal()}>Chiudi</a>
						</div>
                )
				: (
						<div>
								<h1 style={{ padding: '5px' }}>Indici Aliquote</h1>
								<hr />
								<div style={{ padding: '5px' }}>
									<p>Gentile utente,</p>
									<p>Per poter fruire degli Indici Aliquote devi effettuare l&apos;accesso</p>
								</div>
								<hr />
								<a href="/Account/Login" className="btn btn-buffetti-default pull-right" style={{ marginRight: '15px' }}>ACCEDI</a>
								<a href={null} className="btn btn-buffetti-default pull-right" style={{ marginRight: '15px' }} onClick={() => this.closeModal()}>Chiudi</a>
						</div>
                )
				}
                </Modal> */}
            </div>
        );
    }
}


ECommerceContainer.propTypes = {
    LandingObject: PropTypes.shape({}),
    Prodotti: PropTypes.arrayOf(PropTypes.object),
    hasAccess: PropTypes.bool,
};

ECommerceContainer.defaultProps = {
    LandingObject: '',
    Prodotti: '',
    hasAccess: false,
};
