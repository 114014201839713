import React from 'react';
import PropTypes from 'prop-types';

const ImageHandler = ({ src }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <>
      <img
        className="imageBlurred p-1"
        alt="immagine in caricamento"
        src={src}
        style={{
            visibility: isLoaded ? 'hidden' : 'visible',
            display: isLoaded ? 'none' : 'block',
        }}
      />
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className="imageRendered p-1"
        style={{
            opacity: isLoaded ? 1 : 0,
            display: isLoaded ? 'block' : 'none',
        }}
        alt="immagine renderizzata"
        src={src}
      />
    </>
  );
};

ImageHandler.propTypes = {
 src: PropTypes.string,
};

ImageHandler.defaultProps = {
    src: '',
};

export default ImageHandler;
