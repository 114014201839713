/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import {
	Input,
	Button,
	Form,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ProductList from '../../ProductsList';
import {
	fwdPhase,
	clearCartItem,
	getDiscountData,
	setModalVisible,
} from '../../../../common/redux/actions';

class OrderFirstPhase extends React.Component {
	constructor(props) {
		super(props);

		this.applyDiscount = this.applyDiscount.bind(this);
	}

	applyDiscount(value) {
		const { actions } = this.props;
		if (value.discount !== undefined && value.discount !== '') {
			actions.getDiscountData(value.discount);
		} else {
			actions.fwdPhase();
		}
	}

	render() {
		const { cartItemList, actions } = this.props;

		const { userData } = this.props;

		let cartEmpty = false;
		let totalPrice = 0;
		let totalIvaPrice = 0;

		if (cartItemList?.length === 0) cartEmpty = true;
		if (cartItemList?.length > 0) {
			cartItemList.forEach((item) => {
				totalPrice += item.price;
				totalIvaPrice += ((item.price * item.ivaPercentage) / 100);
			});

			totalIvaPrice += totalPrice;
		}

		return (
			<>
				<Form onFinish={this.applyDiscount}>
					{!cartEmpty
						? (
							<>
								<div id="productNumber" className="row w-100 margin_lr">
									<div className="col-md-2 col-12" />
									<div className="col-md-4 col-6 top_bar_cart">

										<span>
											Hai
											{` ${cartItemList.length} ` ?? '0'}
											prodotti nel carrello
										</span>

									</div>
									<div className="col-md-4 col-6 top_bar_cart">

										<Button onClick={() => actions.clearCartItem(userData.id)} className="btn_w float-right" value="default">SVUOTA</Button>

									</div>
									<div className="col-md-2 col-12" />
								</div>
								<div id="productList">
									<ProductList cartItemList={cartItemList} />
								</div>
								<div id="productTotal">
									<div className="row margin_lr">
										<div className="col-md-2 col-12" />
										<div className="col-md-2 col-12 bottom_bar_cart">
											<Form.Item
												name="discount"
											>
												<Input type="text" placeholder="Codice Sconto" />
											</Form.Item>
										</div>
										<div className="col-md-2 col-12 bottom_bar_cart mobile_hide_block" />
										<div className="col-md-2 col-12 bottom_bar_cart" style={{ fontSize: '16px' }}>
											<span>TOTALE: {(totalPrice).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</span>
											<p>TOTALE +IVA: {(totalIvaPrice).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
										</div>
										<div className="col-md-2 col-12 text-right bottom_bar_cart">
											<Form.Item>
												{!userData.isLogged ? (
													<>
													<Button onClick={() => actions.setModalVisible()} className="btn_n">LOGIN</Button>
													<br />
													<a href="/account/register" className="" style={{ color: 'black', marginRight: '14px' }}>Registrati ora</a>
													</>

												) : (
													<Button disabled={cartItemList?.length === 0} className="btn_n" htmlType="submit" value="default">PROCEDI</Button>
												)}
											</Form.Item>
										</div>
										<div className="col-2 bottom" />
									</div>
								</div>
							</>
						) : (


							<center>
								<div className="row w-100 margin_lr">
									<div className="col-md-2 col-12" />
									<div className="col-md-4 col-6 top_bar_cart">

										<span>
											Non ci sono prodotti nel tuo carrello!
										</span>

									</div>
									<div className="col-md-4 col-6 top_bar_cart" />
									<div className="col-md-2 col-12" />
								</div>
								<div style={{ padding: '50px', paddingBottom: '90px' }}>
									<img className="img-fluid" src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_catalogo-header.png" alt="Assistenza" />
									<br />
									<span className="no_product_txt">Vai al catalogo per effettuare un acquisto</span>
									<br />
									<Button className="btn_w btn_c" href="/shop" value="default">CATALOGO</Button>
								</div>
								<div className="col-md-3 col-12 btn_torna text-center">
									<a href="/">&#60; Torna indietro</a>
								</div>
							</center>
						)
					}
				</Form>
			</>
		);
	}
}


function mapStateToProps(state) {
	return {
		userData: state.userData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			fwdPhase: bindActionCreators(fwdPhase, dispatch),
			clearCartItem: bindActionCreators(clearCartItem, dispatch),
			getDiscountData: bindActionCreators(getDiscountData, dispatch),
			setModalVisible: bindActionCreators(setModalVisible, dispatch),
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFirstPhase);

OrderFirstPhase.propTypes = {

	cartItemList: PropTypes.arrayOf(PropTypes.object),
	userData: PropTypes.shape({
		id: PropTypes.number,
		clientType: PropTypes.number,
		email: PropTypes.string,
		billingInfo: PropTypes.shape({

			nome: PropTypes.string,
			cognome: PropTypes.string,
			cf: PropTypes.string,
			uniCode: PropTypes.string,
			iva: PropTypes.string,
			pec: PropTypes.string,
			ragioneSociale: PropTypes.string,
			indirizzo: PropTypes.string,
			nCivico: PropTypes.string,
			cap: PropTypes.string,
			istatCode: PropTypes.string,
		}),
		telefono: PropTypes.string,
		professione: PropTypes.string,
		tematicheInteresse: PropTypes.string,
		advConsent: PropTypes.bool,
		username: PropTypes.string,
		isLogged: PropTypes.bool,
	}),

};
OrderFirstPhase.defaultProps = {

	cartItemList: [],
	userData: {
		id: 0,
		clientType: 1,
		billingInfo: {

			nome: '',
			cognome: '',
			cf: '',
			uniCode: '',
			iva: '',
			pec: '',
			ragioneSociale: '',
			indirizzo: '',
			nCivico: '',
			cap: '',
			istatCode: '',
		},
		email: '',
		telefono: '',
		professione: '',
		tematicheInteresse: '',
		advConsent: false,
		username: '',
		isLogged: false,
		isLoaded: false,
	},
};
