/* eslint-disable no-underscore-dangle */
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';
import SearchBoxContainer from '../components/common/SearchBoxContainer';

export default function setupSearchBox() {
	// do render only if the dom handle is on page
	const searchbox = document.getElementById('js-searchboxReact');
	if (searchbox) {
		ReactDOM.render(<SearchBoxContainer />, searchbox);
	}
}
