/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeCartItem } from '../../../common/redux/actions';

// eslint-disable-next-line react/prefer-stateless-function
class ProductsList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { item, actions } = this.props;
        const { userData } = this.props;

        return (
            <>
                <div className="row margin_lr">
                    <div className="col-md-2 col-12" />
                    <div className="col-md-4 col-5 row">

                        <div className="col-md-4 col-12">

                            <img className="img_product_cart" src={item.imgUrl} alt="productRowImg" />


                        </div>

                        <div className="col-md-6 col-12 product_description_cart">

                            <span><strong>{item.title}</strong></span>

                        </div>

                    </div>

                    <div className="col-md-4 col-7 row">

                        <div className="col-md-4 col-2" />
                        <div className="col-md-4 col-5 product_price_cart">

                            <span>Prezzo</span>
                            <br />
                            <span>{(item.price).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</span>
                            <br />
                            <span>+IVA {item.ivaPercentage}%</span>

                        </div>

                        <div className="col-md-4 col-5 text-right">

                            {userData.id === 0 ? (

                                <a onClick={() => actions.removeCartItem(item.productId, userData.id)}>
                                    <img height={35} width={35} src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_trash.png" alt="Remove" />
                                    rimuovi
                                </a>
                            ) : (
                                <a onClick={() => actions.removeCartItem(item.id, userData.id)}>
                                    <img height={35} width={35} src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_trash.png" alt="Remove" />
                                    rimuovi
                                </a>
                            )}

                        </div>
                    </div>
                    <div className="col-2" />

                </div>
                <hr />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            removeCartItem: bindActionCreators(removeCartItem, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);

ProductsList.propTypes = {

    item: PropTypes.shape({

        id: PropTypes.number,
        title: PropTypes.string,
        buttonText: PropTypes.string,
        descriptionHtml: PropTypes.string,
        imgUrl: PropTypes.string,
        linkShop: PropTypes.string,

    }),
    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};
ProductsList.defaultProps = {

    item: null,
    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: '',
            cognome: '',
            cf: '',
            uniCode: '',
            iva: '',
            pec: '',
            ragioneSociale: '',
            indirizzo: '',
            nCivico: '',
            cap: '',
            istatCode: '',
        },
        email: '',
        telefono: '',
        professione: '',
        tematicheInteresse: '',
        advConsent: false,
        username: '',
        isLogged: false,
        isLoaded: false,
    },

};
