
import React from 'react'; // eslint-disable-line no-unused-vars
import reactDom from '../../../common/react/dom/index';
import GuideDetailsContainer from '../../../components/frontend/guide/GuideDetailsContainer';

const $ = require('jquery');

const typology = __globalJsData.tipology;
const element = __globalJsData.id;

export default function configGuidaParagrafoPage() {
	reactDom(<GuideDetailsContainer Tipology={typology} ElementId={element} />, 'js-detail-guide');

	$('.js-btn-print-par').on('click', (e) => {
		debugger;
		e.preventDefault();
		window.print();
	});
}
