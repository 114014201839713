/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';

// functional component(pure component) using destructuring { }, implicit return and default value
const GuideSearchResultItem = ({ item, idEdition, idGuida }) => {
	let body = item.bodyRichText;
	let { title } = item;
	if (item.highlight) {
		for (const [index, node] of item.highlight.entries()) {
			if (item.highlight[index].field === 'Titolo') {
				title = node.hint[0];
			}
			if (item.highlight[index].field === 'Abstract' || item.highlight[index].field === 'Contenuto') {
				body = node.hint[0];
			}
		}
	}
	return (
		<div className="item-search-result">
			<div className="row">
				<div className="col-xs-12">
					<a href={`/Guide/Paragrafo/?id=${item.id}&eId=${idEdition}&gId=${idGuida}`} className="item-search-result-title-par" dangerouslySetInnerHTML={{ __html: title }} />
					{/* <div className="item-search-result-body" dangerouslySetInnerHTML={{ __html: body }} /> */}
				</div>
			</div>
		</div>
	);
};

GuideSearchResultItem.propTypes = {
	item: PropTypes.shape(),
	idEdition: PropTypes.number,
	idGuida: PropTypes.number,
};

GuideSearchResultItem.defaultProps = {
	item: {},
	idEdition: -1,
	idGuida: -1,
};

export default GuideSearchResultItem;
