import React from 'react';
import PropTypes from 'prop-types';

const NewsDetail = ({
 title, image, link,
}) => (
	<div className="flexbox">
		<a className="articles_txt" href={link} title={title}>
			<div>
				<img className="articles_img" src={image} alt={title} />
			</div>

			<h3 className="articles_txt">{title}</h3>
		</a>
	</div>
);

NewsDetail.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	link: PropTypes.string,
};

NewsDetail.defaultProps = {
	title: '',
	image: '',
	link: '',
};

export default NewsDetail;
