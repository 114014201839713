/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import {
    Form,
    Input,
} from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Banner from '../../../common/BannerCategoryContainer';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../../common/loadingOverlay';
import { errorNotification } from '../../../../common/Notification';

class EditPasswordContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {

            userLoaded: false,

            model: {
                username: null,
                password: null,
                confirmPassword: null,
            },
        };

        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateConfirmPassword = this.updateConfirmPassword.bind(this);

        this.submit = this.submit.bind(this);

        this.checkUserLogged = this.checkUserLogged.bind(this);
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userData.isLoaded && !prevState.userLoaded) {
            return {
                userLoaded: true,
            };
        }
        return null;
    }

    updateUsername(value) {
        let { model } = this.state;
        model.username = value.currentTarget.value;
        this.setState({ model });
    }

    updatePassword(value) {
        let { model } = this.state;
        model.password = value.currentTarget.value;
        this.setState({ model });
    }

    updateConfirmPassword(value) {
        let { model } = this.state;
        model.confirmPassword = value.currentTarget.value;
        this.setState({ model });
    }

    submit() {
        const { model } = this.state;

        if (model.username === null || model.password === null || model.confirmPassword === null) {
            errorNotification('Inserire tutti i campi.');
        } else {
            showLoadingOverlay();
            axios.post('/Account/EditPassword', {

                ...model,

            }).then(() => {
                hideLoadingOverlay();
                window.location.href = '/';
            }).catch((error) => {
                hideLoadingOverlay();
                errorNotification(error);
            });
        }
    }

    checkUserLogged() {
        const { userData } = this.props;

        if (!userData.isLogged) {
            window.location.href = '/';
        }
    }

    render() {
        const { model, userLoaded } = this.state;

        if (userLoaded) {
            this.checkUserLogged();
        }

        return (

            <>
                <Form onFinish={this.submit}>
                    <Banner bannerName="MODIFICA LA PASSWORD" bgColor="#FFFFFF" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-header_modifica_password.png" />
                    <div className="row margin_lr">
                        <div className="col-md-2 padding_lr" />
                        <div id="title" className="p-2 bg-DF col-md-8 col-12"><h4><b>Dati di accesso</b></h4></div>
                        <div className="col-md-2 padding_lr" />
                    </div>
                    <div className="row margin_lr">
                        <div className="col-md-2 padding_lr" />
                        <div className="col-md-8 padding_lr row">
                            <div className="col-md-4 col-12">
                                <label className="col-12">Nome utente*</label>
                                <Input value={model.username} name="username" id="username" type="text" onChange={this.updateUsername} placeholder="Nome utente" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">Password*(deve contenere lettere e cifre)</label>
                                <Input value={model.password} name="password" id="password" type="password" onChange={this.updatePassword} placeholder="Password" />
                            </div>

                            <div className="col-md-4 col-12">
                                <label className="col-12">Conferma Password*</label>
                                <Input value={model.confirmPassword} name="confirmPassword" id="confirmPassword" type="password" onChange={this.updateConfirmPassword} placeholder="Conferma Password" />
                            </div>
                        </div>
                        <div className="col-md-2 padding_lr" />
                    </div>
                    <div className="col-md-10 col-8">
                        <div id="formButtons" className="d-flex w-100 justify-content-end">

                            <button type="submit" className="btn_acquista" style={{ marginTop: '1vw', padding: '5px 25px', marginRight: '20px' }}>SALVA</button>

                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

export default connect(mapStateToProps)(EditPasswordContainer);

EditPasswordContainer.propTypes = {

    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        nome: PropTypes.string,
        cognome: PropTypes.string,
        email: PropTypes.string,
        cf: PropTypes.string,
        uniCode: PropTypes.string,
        iva: PropTypes.string,
        ragioneSociale: PropTypes.string,
        indirizzo: PropTypes.string,
        nCivico: PropTypes.string,
        cap: PropTypes.number,
        istatCode: PropTypes.string,
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};

EditPasswordContainer.defaultProps = {

    userData: {
        id: 0,
        clientType: 1,
        nome: null,
        cognome: null,
        email: null,
        cf: null,
        uniCode: null,
        iva: null,
        ragioneSociale: null,
        indirizzo: null,
        nCivico: null,
        cap: null,
        istatCode: null,
        telefono: null,
        professione: null,
        tematicheInteresse: null,
        advConsent: false,
        username: null,
        isLogged: false,
    },
};
