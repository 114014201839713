import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { dataInHomeEndPoint } from '../../../variables';

import NewsDetail from '../NewsDetail';

// const Articles = [
//     {
//         image: 'http://media.drimg.it/portal/Immagini/Gazzetta.gif',
//         title: 'CONFERMATO IL TAGLIO IRAP',
//         id: 10,
//     },
//     {
//       image: 'http://media.drimg.it/portal/Immagini/20190913-INPS.png',
//       title: 'CIGD le novità in materia',
//       id: 11,
//     },
//     {
//         image: 'http://media.drimg.it/portal/Immagini/Agenzia.jpg',
//         title: 'Bonus Affitti 2020',
//         id: 12,
//     },
//     {
//         image: 'http://media.drimg.it/portal/Immagini/Gazzetta.gif',
//         title: 'IL DECRETO RILANCIO E LEGGE',
//         id: 13,
//     },
//     {
//         image: 'http://media.drimg.it/portal/Immagini/enpam-logo.png',
//         title: 'ENPAM RINVIA I TERMINI PER LA DICHIARAZIONE CONTRIBUTIVA',
//         id: 14,
//     },
//     {
//         image: 'http://media.drimg.it/portal/Immagini/Garanteprivacy.jpg',
//         title: 'ATTIVITÀ COMMERCIALI E RICREATIVE',
//         id: 15,
//     },
// ];

export default class NewsHomeContainer extends Component {
    static get propTypes() {
        return {
            size: PropTypes.number.isRequired,
        };
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            news: [],
            start: 0,
           // size: 5,
        };
    }

    componentDidMount() {
        this.onLoadNews();
    }

    onLoadNews() {
        const { start } = this.state;
        const { size } = this.props;

        axios.get(`${dataInHomeEndPoint}?start=${start}&size=${size}`)
        .then(({ data }) => {
            const result = data.resultList.splice(0, 4);
            this.setState({ news: result, loading: true });
          }).catch(() => {
            this.setState({ news: [], loading: false });
        });
    }

    render() {
        const { news, loading } = this.state;

      return (
        <div>
           {loading
            ? (
                <div>
                    <div className="top_title">ULTIM&apos;ORA</div>
                    <div className="articles_container">
                            {news.map(data => (
                                <div key={data.id}>
                                    <NewsDetail {...data} />
                                </div>
                            ))}
                    </div>
                    <center>
                        <div style={{ marginBottom: '-26px' }}>
                        {' '}
                        <a className="button_read_all" href="/Services/Paper/4">LEGGI TUTTO</a>
                        </div>
                    </center>
                </div>
            )
            : null }
        </div>
      );
    }
  }
