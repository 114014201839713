/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Banner from '../../../common/BannerCategoryContainer';
import ProfileForm from '../../../common/ProfileFormContainer';
import { setModalVisible } from '../../../../common/redux/actions';


class RegisterContainer extends Component {
    constructor(props) {
        super(props);

        this.showModal = this.showModal.bind(this);
    }

    showModal() {
        const { actions } = this.props;

        actions.setModalVisible();
    }

    render() {
        return (
            <>

                <Banner bannerName="REGISTRATI" bgColor="#c8f246" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_user-header.png" />
                <div className="row margin_lr">
                    <div className="col-md-2 col-12" />
                    <div className="col-4 d-flex flex-column justify-content-center align-items-start">
                        <span><strong>CREA UN NUOVO UTENTE</strong></span>
                        <span>Compila i tuoi dati e accedi ai servizi Directio</span>
                    </div>

                    <div className="col-4 d-flex flex-column justify-content-center align-items-end">

                        <div className="w-50 d-flex justify-content-center align-items-center">
                            <span>Se invece hai già un account</span>
                        </div>

                        <div className="w-50 d-flex justify-content-center align-items-center">
                            <a onClick={this.showModal} className="btn_acquista">ACCEDI</a>
                        </div>

                    </div>
                    <div className="col-md-2 col-12" />
                </div>

                <ProfileForm formType="Register" />

                <div className="col-md-3 col-12 btn_torna">
                    <a href="/">&#60; Torna indietro</a>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            setModalVisible: bindActionCreators(setModalVisible, dispatch),
        },
    };
}

export default connect(null, mapDispatchToProps)(RegisterContainer);
