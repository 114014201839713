import React from 'react';
import PropTypes from 'prop-types';


// functional components(pure components) with destructuring, implicit return and default value
const VideoItemSmall = ({
 id, title, image, dateIT,
}) => (
	<div className="mod-correlati--item row">
		<div className="col-4 img-correlata">
		{(image !== null || image !== '')
				? <img src={`${image}`} alt={title} className="img-responsive img-fluid" />
					: null }
		</div>
		<div className="col-8 testo-correlato">
<a href={`/Video/Details/${id}`} title={title}>
		<div className="d-flex w-100 justify-content-between row font-barlow">
			<div className="col-lg-12">
				<small className="text-muted">{dateIT}</small>
				<p className="text_corelati font-zillaslab">
					{title}
				</p>
			</div>
		</div>
		<br />
</a>
		</div>
	</div>

);

VideoItemSmall.propTypes = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string,
	dateIT: PropTypes.string,
	image: PropTypes.string,
};

VideoItemSmall.defaultProps = {
	dateIT: '',
	title: '',
	image: '',
};

export default VideoItemSmall;
