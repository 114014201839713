/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const ECommerceCoreContents = ({ ApproPrimoPiano, openModal }) => (
        ApproPrimoPiano.map(data => (
        <div key={data.id} className="col-md-2 col-12 box_punti">
            <a href={`/Approfondimenti/Details/${data.id}`} onClick={() => openModal()}>
                {/* <div className="">
                    <div className="firstappareance_item_image">
                        <img className="img-fluid" alt="in evidenza" src={data.image} />
                    </div>
                </div> */}
                <div className="">
                    <div className="firstappareance_item_content">
                        <h4 className="title_ecommerce">{data.title}</h4>
                        <span className="subtitle_ecommerce" dangerouslySetInnerHTML={{ __html: `${data.shortDescription.substring(0, 140)}` }} />
                    </div>
                </div>
                <br />
                <center><div className="btn_n ">VAI</div></center>
            </a>
        </div>
    ))
);

ECommerceCoreContents.propTypes = {
    ApproPrimoPiano: PropTypes.arrayOf(PropTypes.object).isRequired,
    openModal: PropTypes.func.isRequired,
};

export default ECommerceCoreContents;
