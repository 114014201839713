/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
/* eslint-disable-next-line react/prefer-stateless-function */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { addCartItem } from '../../../common/redux/actions';
import OrderProcessBar from '../../common/OrderProcessBar';
import OrderProcess from '../../common/OrderProcess';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { errorNotification } from '../../../common/Notification';
import BannerCategoryContainer from '../../common/BannerCategoryContainer';

class CartView extends React.Component {
    constructor(props) {
        super(props);
        this.addItemToCart = this.addItemToCart.bind(this);
    }

    componentDidMount() {
        const { userData } = this.props;

        const productID = new URLSearchParams(window.location.search).get('id');
        const productOrigin = new URLSearchParams(window.location.search).get('productOrigin');

        if (productID && productOrigin) {
            showLoadingOverlay();
            axios.get('/api/ShopApi/getProductDetails', {
                params: {
                    productId: productID,
                    clientOrigin: productOrigin,
                },
            }).then(({ data }) => {
                hideLoadingOverlay();
                this.addItemToCart(data, userData.id);
            }).catch((error) => {
                hideLoadingOverlay();
                errorNotification(error.response.data);
            });
        }
    }

    addItemToCart(item, userId) {
        const { actions } = this.props;
        actions.addCartItem(item, userId);
    }

    render() {
        const { cartItemList } = this.props;

        return (
            <>

                <BannerCategoryContainer bannerName="CARRELLO" bgColor="#C8F344" icon="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_carrello-header.png" />

                {cartItemList?.itemList?.length > 0 && (
                    <OrderProcessBar />
                )}

                <OrderProcess />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
        cartItemList: state.cartItemList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            addCartItem: bindActionCreators(addCartItem, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartView);

CartView.propTypes = {

    cartItemList: PropTypes.shape({
        itemList: PropTypes.arrayOf(PropTypes.object),
        isLoaded: PropTypes.bool,
    }),

};
CartView.defaultProps = {

    cartItemList: {
        itemList: [],
        isLoaded: false,
    },
};
