/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import axios from 'axios';

const url = '/Search';

// eslint-disable-next-line react/no-deprecated
export default class SearchBoxContainer extends Component {
	static propTypes = {
		initialTerm: PropTypes.string,
		isSearchPage: PropTypes.bool,
	}

static defaultProps = {
	initialTerm: '',
	isSearchPage: false,
	// totalList: 0,
};

	// getInitialState() {
	// 	return {
	// 		// eslint-disable-next-line react/destructuring-assignment
	// 		term: this.props.initialTerm,
	// 	}
	// }
	constructor(props) {
		super(props);
		const { initialTerm } = this.props;
		this.state = {
			term: initialTerm,
			includeArticle: true,
			includeNews: true,
			includeVideo: true,
			dateStart: '',
			dateEnd: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeIncludeArticle = this.handleChangeIncludeArticle.bind(this);
		this.handleChangeIncludeNews = this.handleChangeIncludeNews.bind(this);
		this.handleChangeIncludeVideo = this.handleChangeIncludeVideo.bind(this);
		this.handleChangeDateStart = this.handleChangeDateStart.bind(this);
		this.handleChangeDateEnd = this.handleChangeDateEnd.bind(this);
		this.submit = this.submit.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onKeyPress(evt) {
		if (evt.charCode === 13) {
			this.submit();
		}
	}

	onSubmit(evt) {
		evt.preventDefault();
		this.submit();
	}

	onReset() {
		// when user click on the X icon -> empty search field and close the dropdown if it open
		this.setState({ term: '' });
	}

	handleChange(evt) {
		this.setState({ term: evt.target.value });
	}

	handleChangeIncludeArticle(evt) {
		this.setState({ includeArticle: evt.target.checked });
	}

	handleChangeIncludeNews(evt) {
		this.setState({ includeNews: evt.target.checked });
	}

	handleChangeIncludeVideo(evt) {
		this.setState({ includeVideo: evt.target.checked });
	}

	handleChangeDateStart(evt) {
		this.setState({ dateStart: evt.target.value });
	}

	handleChangeDateEnd(evt) {
		this.setState({ dateEnd: evt.target.value });
	}

	submit() {
		const { ...state } = this.state;
		// window.location.href = `${url}/?q=${term}`;
		axios.post('/Search/Index', {
			term: state.term,
			includeArticle: state.includeArticle,
			includeVideo: state.includeVideo,
			includeNews: state.includeNews,
			dateEnd: state.dateEnd,
			dateStart: state.dateStart,
		})
		.then(({ data: { data } }) => {
			window.location.href = '';
		})
		.catch((error) => {
			console.log(error);
		});
	}

	render() {
		const {
			term, includeArticle, includeNews, includeVideo, dateStart, dateEnd,
		} = this.state;
		return (
			<form method="POST" action={`/Search/Index?term=${term}&includeArticle=${includeArticle}&includeNews=${includeNews}&includeVideo=${includeVideo}&dateStart=${dateStart}&dateEnd=${dateEnd}&size=${10}&start=${0}`} id="search">
				<div className="row">
					<div className="col-lg-12 item_search">
							<input type="text" className="form-control" placeholder="cerca" aria-label="" value={term} aria-describedby="basic-addon2" onChange={this.handleChange} />
					</div>
					<div className="col-lg-3 col-lg-12 checkbox_search">
						<Checkbox
							checked={includeArticle}
							onChange={e => this.handleChangeIncludeArticle(e)}
						>
							<p className="checkbox_label">editoriali</p>
						</Checkbox>
						<Checkbox
							checked={includeNews}
							onChange={e => this.handleChangeIncludeNews(e)}
						>
							<p className="checkbox_label">news</p>
						</Checkbox>
						<Checkbox
							checked={includeVideo}
							onChange={e => this.handleChangeIncludeVideo(e)}
						>
							<p className="checkbox_label">video</p>
						</Checkbox>
					</div>
					<div className="col-lg-3 col-lg-6 range_search">
						<input type="date" className="form-control" placeholder="" aria-label="" value={dateStart} aria-describedby="basic-addon2" onChange={this.handleChangeDateStart} min="0000-01-01" max="9999-12-31" />
					</div>
					<div className="col-lg-3 col-lg-6 range_search">
						<input type="date" className="form-control" placeholder="" aria-label="" value={dateEnd} aria-describedby="basic-addon2" onChange={this.handleChangeDateEnd} min="0000-01-01" max="9999-12-31" />
					</div>
					<div className="col-lg-3 col-lg-12 range_search">
						<span className="input-group-append">
							{/* { term.length > 0
								// eslint-disable-next-line jsx-a11y/no-static-element-interactions
								? <span id="basic-addon2" className="btn_search" onClick={this.onSubmit}>CERCA</span>
							: null } */}
							<input type="hidden" id="term" value={term} />
							<input type="hidden" id="includeArticle" value={includeArticle} />
							<input type="hidden" id="includeNews" value={includeNews} />
							<input type="hidden" id="includeVideo" value={includeVideo} />
							<input type="hidden" id="dateStart" value={dateStart} />
							<input type="hidden" id="dateEnd" value={dateEnd} />

							<input type="submit" id="searchBtn" value="CERCA" className="btn_search" />
						</span>
					</div>
				</div>
			</form>
		);
	}
}
