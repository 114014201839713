/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import OrderFirstPhase from '../OrderPhasesContainer/OrderFirstPhase';
import OrderSecondPhase from '../OrderPhasesContainer/OrderSecondPhase';

import { bkwPhase, resetDiscountData } from '../../../common/redux/actions';


// eslint-disable-next-line react/prefer-stateless-function
class OrderProcess extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);

		this.returnBackPhase = this.returnBackPhase.bind(this);
	}

	returnBackPhase() {
		const { actions } = this.props;

		actions.resetDiscountData();
		actions.bkwPhase();
	}

	render() {
		const { cartPhase, cartItemList } = this.props;

		const BackButton = () => (
			<div className="col-md-3 col-12 btn_torna">
				<a onClick={() => this.returnBackPhase()}>&#60; Torna indietro</a>
			</div>
		);

		switch (cartPhase) {
			case 1: return (
				<>
					<OrderFirstPhase cartItemList={cartItemList.itemList} />
				</>
			);
			case 2: return (
				<>
					<OrderSecondPhase cartItemList={cartItemList.itemList} />
					<BackButton />
				</>
			);
			default:
				break;
		}
	}
}

function mapStateToProps(state) {
	return {
		cartPhase: state.cartPhase,
		cartItemList: state.cartItemList,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			bkwPhase: bindActionCreators(bkwPhase, dispatch),
			resetDiscountData: bindActionCreators(resetDiscountData, dispatch),
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProcess);

OrderProcess.propTypes = {

	cartPhase: PropTypes.number,
	cartItemList: PropTypes.shape({
		itemList: PropTypes.arrayOf(PropTypes.object),
		isLoaded: PropTypes.bool,
	}),

};
OrderProcess.defaultProps = {

	cartPhase: 1,
	cartItemList: {
		itemList: [],
		isLoaded: false,
	},
};
