import React from 'react';
import PropTypes from 'prop-types';

const PaperCampaigns = ({ Campaigns }) => (
    Campaigns.map(value => (
        <div key={value.id}>
            <a href={`/Archivio/Etichette/${value.id}?n=${value.title}`} rel="noreferrer" className="dp_paper_campaigns_col12_anchor">
                <span>{value.title}</span>
            </a>
        </div>
    ))
);

PaperCampaigns.propTypes = {
    Campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PaperCampaigns;
