/* eslint-disable import/newline-after-import */
import React from 'react'; // eslint-disable-line no-unused-vars
import reactDom from '../../../common/react/dom/index';
import GuideCompleteContainer from '../../../components/frontend/guide/GuideCompleteContainer';

// import Rx from 'rx-lite';
// import * as vars from 'variables';
const $ = require('jquery');
require('jstree'); // jstree

const openAll = true;

// import Updates from '../components/updates';
// import { logException } from 'utils/log/logError';

export default function init() {
	reactDom(<GuideCompleteContainer />, 'indice-tree');

	$(document).ready(() => {
		let activeBox = '#indice-tab';
		// // Aggiornamenti
		// // get edizione id
		// // const edizioneId = $('#main-content').data('edizione');
		function showHideTab(targetBox) {
			// Nascondo quello attivo
			$(activeBox).hide();
			$(`${activeBox}-link`).parent().removeClass('active');
			// Visualizzo il tab
			activeBox = targetBox;
			$(activeBox).show();
			$(`${activeBox}-link`).parent().addClass('active');
		}
		// Voce del menù attiva
		const $indiceTree = $('.js-indice-tree');

		// indice:open all
		$('.plus-icon').click((evt) => {
			evt.preventDefault();
			$indiceTree.jstree('open_all');
		});
		// indice:close all
		$('.minus-icon').click((evt) => {
			evt.preventDefault();
			$indiceTree.jstree('close_all');
		});

		$('#myTab a').click(function showTab(evt) {
			evt.preventDefault();
			$(this).tab('show');
		});

		$('.js-menu-nav-tab').on('click', function navTab(evt) {
			evt.preventDefault();
			const targetBox = this.href.replace(/.*(?=#[^\s]*$)/, ''); // #help-guida-box'
			showHideTab(targetBox);
		});

		// Intercettare link entrante
		// Add empty string to force copy by value and not ref
		const hashTab = document.location.href.split('#')[1];
		if (hashTab) {
			showHideTab(`#${hashTab}`);
		}
	});
}
