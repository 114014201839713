import React from 'react';

const { titolo, messaggio } = __globalJsData;

// eslint-disable-next-line react/prefer-stateless-function
class OrderFailedPhase extends React.Component {
	render() {
		return (
			<div className="row margin_lr">
			<div className="col-md-2 col-12" />
				<div className="col-md-8 col-12 d-flex w-100 bottom_bar_cart">
					<p>{titolo}</p>
				</div>
				<div className="text-center w-100 success_msg">
					<img className="m-3" height="50" width="50" src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/Icone-catalogo-area-utente_pagamento-deny.png" alt="okLogo" />
					<br />
					<span>{messaggio}</span>
					<br />
					<span>Se hai bisogno di assistenza scrivici a </span>
					<a href="mailto:info@directio.it">info@directio.it</a>
				</div>
				<div className="col-md-2 col-12" />
			</div>
		);
	}
}


export default OrderFailedPhase;
