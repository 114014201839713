/* eslint-disable import/newline-after-import */
/* eslint-disable import/first */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions, indent, react/jsx-indent */
import React, { Component } from 'react';
// import reactDom from 'common/react/dom/index';
import axios from 'axios';
import $ from 'jquery';
require('jstree'); // jstree

import { getGuidaCompleta } from '../../../variables';
import LoadingSpinner from '../../../common/LoadingSpinner';

const { CancelToken } = axios;
// const source = CancelToken.source();
let cancel;

const {
idGuida,
idEdition,
isEnabled,
openAll,
} = __globalJsData;
const OpenAll = !!(openAll && openAll === 1);

export default class GuideCompleteContainer extends Component {
	static propTypes = {
	}

	constructor(props) {
		super(props);
		this.state = {
			idGuida,
			isGuidaLoading: false,
			guidaCompleta: {},
			isEnabled: true,
		};
	}

	componentWillMount() {
		if (isEnabled === 'False') {
			this.setState({ isEnabled: false });
		}
		this.onLoadTree(idGuida);
	}

	componentDidUpdate() {
		this.onLoadJquery();
	}

	onLoadTree = (id) => {
		cancel && (() => cancel());
		if (id != null) {
			let guidaCompleta = {};
			this.setState({ isGuidaLoading: true });
			axios.get(`${getGuidaCompleta}/?id=${id}`, { cancelToken: new CancelToken((c) => { cancel = c; }) }).then(({ data }) => {
				if (data.data != null) {
					guidaCompleta = data.data;
				}
				this.setState({ guidaCompleta, isGuidaLoading: false }, () => {
					if (OpenAll) {
						$('.plus-icon').trigger('click');
					}
				});
			}).catch((error) => {
				if (axios.isCancel(error)) {
					// let crc = this.state.canceledRequestsCount;
					// this.setState({ canceledRequestsCount: crc + 1 });
					console.log('onReloadReferences: cancel request');
				}
			});
		} else {
			this.setState({ guidaCompleta: {}, isGuidaLoading: false });
		}
	}

	onLoadJquery = () => {
		const $indiceTree = $('.js-indice-tree');
		// .$el = $(this.el);
		$.jstree.defaults.core.animation = 500;
		$indiceTree.jstree({
			core: {
				themes: {
					theme: 'default',
					dots: false,
					icons: false,
				},
			},
		}).bind('select_node.jstree', (event, data) => {
			location.href = data.node.a_attr.href; // data.rslt.obj.children('a').attr('href');
		}).bind('ready.jstree', () => {
			$('.parte-item').each(function openNode() {
				$indiceTree.jstree('open_node', $(this));
			});
		});
	}

	renderTreeNodes(nodes) {
		return (
			<ul>
				{ nodes.map(node => (
					<li key={node.id} className={`${node.tipo}-item`}>
						{isEnabled ? (
							<a href={`/Guide/${node.tipo}/?id=${node.id}&eId=${idEdition}&gId=${idGuida}`} className="indice-link-item">
								<span className="indice-parte-heading">
									{node.tipo === 'capitolo' ? (
										<span className="titolo-heading">
										Capitolo&nbsp;
										{node.order}
										.&nbsp;
										</span>
									) : null }
									{node.tipo === 'parte' ? (
										<span className="titolo-heading">
											{node.numero}
											&nbsp;PARTE.&nbsp;
										</span>
										) : null }
									{node.tipo === 'paragrafo' ? (
										<span className="titolo-heading">
											Paragrafo&nbsp;
											{node.order}
											.&nbsp;
										</span>
											) : null }
									<span className={`titolo-${node.tipo}`}>{node.titolo}</span>
								</span>
							</a>
							) : (
							<a className="indice-link-item disabled">
								<span className="indice-parte-heading">
									{node.tipo === 'capitolo' ? (
										<span className="titolo-heading">
											Capitolo
											{node.order}
											.&nbsp;
										</span>
									) : null }
									{node.tipo === 'parte' ? (
										<span className="titolo-heading">
											{node.numero}
											PARTE.&nbsp;
										</span>
									) : null }
									{node.tipo === 'paragrafo' ? (
										<span className="titolo-heading">
											Paragrafo
											{node.order}
											.&nbsp;
										</span>
									) : null }
									<span className={`titolo-${node.tipo}`}>{node.titolo}</span>
								</span>
							</a>
							)
						}
						{node.nodes ? this.renderTreeNodes(node.nodes) : null }
					</li>
				))}
			</ul>
		);
	}

	render() {
		const {
			guidaCompleta,
			isGuidaLoading,
		} = this.state;
		return (
			<div className="js-indice-tree" ref={this.el}>
				{ guidaCompleta && !isGuidaLoading
				? this.renderTreeNodes(guidaCompleta.nodes) : <LoadingSpinner /> }
			</div>
		);
	}
}
