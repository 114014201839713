import React from 'react';


// eslint-disable-next-line react/prefer-stateless-function
class OrderCompletedPhase extends React.Component {
	render() {
		return (
			<div className="row margin_lr">
			<div className="col-md-2 col-12" />
				<div className="col-md-8 col-12 d-flex w-100 bottom_bar_cart">
					<p>Pagamento avvenuto con successo</p>
				</div>
				<div className="text-center w-100 success_msg">
					<img className="m-3" height="50" width="50" src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/check.png" alt="okLogo" />
					<br />
					<span><strong>Gentile utente, il tuo ordine è andato a buon fine.</strong></span>
					<br />
					<span>Riceverai a breve una mail di conferma dell&apos; avvenuta attivazione dei servizi acquistati.</span>
					<br />
					<span>Se hai bisogno di assistenza scrivici a </span>
					<a href="mailto:info@directio.it">info@directio.it</a>
					<br />
					<span>A presto su </span>
					<a href="https://directio.it/">directio.it</a>
				</div>
				<div className="col-md-2 col-12" />
			</div>
		);
	}
}


export default OrderCompletedPhase;
