import axios from 'axios';
import { showLoadingOverlay, hideLoadingOverlay } from '../../loadingOverlay';
import { errorNotification, successNotification } from '../../Notification';


// CARTPHASE ACTIONS

export function fwdPhase() {
    return { type: 'FWDPHASE' };
}

export function bkwPhase() {
    return { type: 'BKWPHASE' };
}

export function setPhase(phase) {
    return { type: 'SETPHASE', phase };
}

// CART OPERATIONS ACTIONS

export function cartGetSuccess(cartData) {
    return { type: 'CARTGETSUCCESS', cartData };
}

export function cartAddSuccess(cartData) {
    successNotification('CARRELLO', 'Prodotto aggiunto al carrello.');
    return { type: 'CARTADDSUCCESS', cartData };
}

export function cartRemoveSuccess(cartData) {
    successNotification('CARRELLO', 'Prodotto rimosso dal carrello.');
    return { type: 'CARTREMOVESUCCESS', cartData };
}

export function cartClearSuccess(cartData) {
    successNotification('CARRELLO', 'Prodotti rimossi dal carrello.');
    return { type: 'CARTCLEARSUCCESS', cartData };
}

export function getCartItem(userId) {
    return (dispatch) => {
        axios.get('/api/CartApi/getCart',
            {
                params: {
                    userId,
                },
            })
            .then(({ data }) => {
                if (data && data.length > 0) {
                    const dataOnline = {
                        itemList: data,
                        isLoaded: true,
                    };
                    dispatch(cartGetSuccess(dataOnline));
                } else {
                    let dataOffline = {
                        itemList: [],
                        isLoaded: true,
                    };
                    if (JSON.parse(localStorage.getItem('cartItems')) !== undefined && JSON.parse(localStorage.getItem('cartItems')) !== null) {
                        dataOffline = JSON.parse(localStorage.getItem('cartItems'));
                    }

                    if (userId !== 0) {
                        dataOffline.itemList.forEach((item) => {
                            axios.post('/api/CartApi/addItemToCart',
                                {
                                    dataItem: item,
                                    userId,

                                }).catch((error) => {
                                    errorNotification(error.response.data.Message);
                                });
                        });
                    }

                    dispatch(cartGetSuccess(dataOffline));
                }
            });
    };
}

export function addCartItem(dataItem, userId) {
    return (dispatch) => {
        if (userId === 0) {
            const storageState = JSON.parse(localStorage.getItem('cartItems'));
            const alreadyAdded = storageState.itemList.filter(e => e.productId === dataItem.productId).length > 0;
            if (!alreadyAdded) {
                const newStorage = storageState.itemList.concat(dataItem);
                const dataOnline = {
                    itemList: newStorage,
                    isLoaded: true,
                };
                dispatch(cartAddSuccess(dataOnline));
                window.location.href = '/cart';
            } else {
                errorNotification('Prodotto già inserito nel carrello');
            }
        } else {
            axios.post('/api/CartApi/addItemToCart',
                {
                    dataItem,
                    userId,

                }).then(({ data }) => {
                    if (data) {
                        const dataOnline = {
                            itemList: data,
                            isLoaded: true,
                        };
                        dispatch(cartAddSuccess(dataOnline));
                        window.location.href = '/cart';
                    }
                })
                .catch((error) => {
                    errorNotification(error.response.data.Message);
                });
        }
    };
}

export function removeCartItem(cartItemId, userId) {
    return (dispatch) => {
        if (userId === 0) {
            const storageState = JSON.parse(localStorage.getItem('cartItems'));
            const newStorage = storageState.itemList.filter(e => e.productId !== cartItemId);
            const dataOnline = {
                itemList: newStorage,
                isLoaded: true,
            };
            dispatch(cartRemoveSuccess(dataOnline));
        } else {
            axios.post('/api/CartApi/deleteItemFromCart',
                {
                    cartItemId,
                    userId,

                }).then(({ data }) => {
                    if (data) {
                        const dataOnline = {
                            itemList: data,
                            isLoaded: true,
                        };
                        dispatch(cartRemoveSuccess(dataOnline));
                    }
                })
                .catch((error) => {
                    errorNotification(error.response.data.Message);
                });
        }
    };
}

export function clearCartItem(userId) {
    return (dispatch) => {
        axios.post('/api/CartApi/deleteAllFromCart',
            {
                userId,

            }).then(({ data }) => {
                if (data) {
                    const dataOnline = {
                        itemList: data,
                        isLoaded: true,
                    };
                    dispatch(cartClearSuccess(dataOnline));
                }
            })
            .catch((error) => {
                errorNotification(error.response.data.Message);
            });
    };
}

// USER DATA ACTIONS

export function getUserDataSuccess(userData) {
    return { type: 'GETUSERDATASUCCESS', userData };
}

export function getUserData() {
    return (dispatch) => {
        axios.get('/api/AccountApi/getUserData')
            .then(({ data }) => {
                if (data) {
                    dispatch(getUserDataSuccess(data));
                }
            });
    };
}

// DISCOUNT ACTIONS

export function resetDiscountDataSuccess() {
    return { type: 'DISCOUNTRESETSUCCESS' };
}

export function setDiscountDataSuccess(discountData) {
    return { type: 'DISCOUNTSETSUCCESS', discountData };
}

export function getDiscountData(dCode) {
    return (dispatch) => {
        showLoadingOverlay();
        axios.get('/api/DiscountApi/getDiscountCodeValidity', {
            params: {
                discountCode: dCode,
            },
        }).then(({ data }) => {
            if (data) {
                hideLoadingOverlay();
                dispatch(setDiscountDataSuccess(data));
                dispatch(fwdPhase());
            }
        }).catch((error) => {
            hideLoadingOverlay();
            errorNotification(error.response.data);
        });
    };
}

export function resetDiscountData() {
    return (dispatch) => {
        dispatch(resetDiscountDataSuccess());
    };
}

// LOGINMODALVISIBLEREDUCER ACTIONS

export function setModalVisible() {
    return { type: 'SETVISIBLE' };
}

export function setModalInvisible() {
    return { type: 'SETINVISIBLE' };
}

// SHOP FILTER ACTIONS

export function setMacroCategory(data) {
    return { type: 'SETMACRO', data };
}

export function setCategory(data) {
    return { type: 'SETCATEGORY', data };
}
