import React from 'react'; // eslint-disable-line no-unused-vars
import reactDom from '../../../common/react/dom/index';
import GuideDetailsContainer from '../../../components/frontend/guide/GuideDetailsContainer';

const typology = __globalJsData.tipology;
const element = __globalJsData.id;

export default function init() {
	reactDom(<GuideDetailsContainer Tipology={typology} ElementId={element} />, 'js-detail-guide');
}
