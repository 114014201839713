import initialState from '../initialStates/shopFilter/initialState';

const shopFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SETMACRO':
            return action.data;

        case 'SETCATEGORY':
            return action.data;

        default:
            return state;
    }
};

export default shopFilterReducer;
