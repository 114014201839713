/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import SezioniServiziContainer from '../../common/SezioniServiziContainer';
import ProfileModifierContainer from '../../common/ProfileModifierContainer';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { errorNotification } from '../../../common/Notification';

// eslint-disable-next-line react/prefer-stateless-function
class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userLoaded: false,
            serviziLoaded: false,
            servizi: [],
        };

        this.checkUserLogged = this.checkUserLogged.bind(this);
        this.getServices = this.getServices.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userData.isLoaded && !prevState.userLoaded) {
            return {
                userLoaded: true,
            };
        }
        return null;
    }

    componentDidUpdate() {
        const { userLoaded, serviziLoaded } = this.state;
        if (userLoaded && !serviziLoaded) {
            this.checkUserLogged();
        }
    }

    getServices() {
        showLoadingOverlay();
        axios.get('/api/UserAreaApi/getBoughtItems')
            .then(({ data }) => {
                if (data) {
                    hideLoadingOverlay();
                    this.setState({ servizi: data.servizi, serviziLoaded: true });
                }
            }).catch((error) => {
                hideLoadingOverlay();
                errorNotification(error.response.data);
            });
    }

    checkUserLogged() {
        const { userData } = this.props;

        if (!userData.isLogged) {
            window.location.href = '/';
        } else {
            this.getServices();
        }
    }

    render() {
        const { userLoaded, serviziLoaded, servizi } = this.state;

        // ti ammazzo se vedo ancora questa SPAZZATURA nei metodi render
        // if (userLoaded && !serviziLoaded) {
        //     this.checkUserLogged();
        // }

        return (
            <>
                <ProfileModifierContainer />
                <div className="row margin_lr">
                    {servizi.length > 0
                        ? (
                            <>
                                <div className="col-md-2" />
                                <div className="col-md-8 col-12">
                                    <div>
                                        <h1 className="profile_services_header">I TUOI SERVIZI</h1>
                                        <p>Qui puoi visualizzare la lista dei tuoi servizi attualmente attivati e accedervi direttamente.</p>
                                    </div>
                                    <SezioniServiziContainer profileServices={servizi} />
                                </div>
                                <div className="col-md-2 col-12" />
                            </>
                        ) : (
                            <>
                                <div style={{ height: '10px' }} />
                                <div className="col-2" />
                                <div className="col-8 text-center img_no_product" id="noServices">
                                    <center>
                                        <img className="img-fluid" style={{ width: '50%' }} src="https://frontenddirectiostorage.blob.core.windows.net/directio-frontend/Images/buildings_profile_empty.png" alt="profilePic" />
                                        <br />
                                        <span><b>Non hai ancora attivato un servizio</b></span>
                                        <br />
                                        <span>Esplora i servizi Directio per te o per il tuo Studio o la tua azienda</span>
                                        <br />
                                        <a className="btn_n m-5" href="/shop">Vai al catalogo</a>
                                    </center>
                                </div>
                                <div className="col-2" />
                            </>

                        )}

                </div>

                <div className="mt-5" />
                <div className="row margin_lr">
                    <div className="col-md-2 col-12" />
                    <div className="col-4 d-inline-flex align-items-center">

                        <a href="/">
                            &#60;&nbsp;Torna indietro
                        </a>

                    </div>
                    <div className="col-4 d-inline-flex align-items-center justify-content-end">

                        {/* <a href="https://www.wrike.com/frontend/requestforms/index.html?token=eyJhY2NvdW50SWQiOjIxNjcyNzksInRhc2tGb3JtSWQiOjE0NDY5NX0JNDc1NDU1NDYzMzI3MQkyOTVkNTZjMDNhZTZhMmM5YzRkYjY0Y2Y0OTEyYmFlOTM1NzgxYTYzYzA1YTZjZTYwNjUwOWE0MjgwYTdjMTZl">
                            <i className="fa-regular fa-comments-question" />
                            Scrivi all&apos;Help Desk
                        </a> */}

                    </div>
                    <div className="col-2" />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {

    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};
Profile.defaultProps = {

    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: '',
            cognome: '',
            cf: '',
            uniCode: '',
            iva: '',
            pec: '',
            ragioneSociale: '',
            indirizzo: '',
            nCivico: '',
            cap: '',
            istatCode: '',
        },
        email: '',
        telefono: '',
        professione: '',
        tematicheInteresse: '',
        advConsent: false,
        username: '',
        isLogged: false,
        isLoaded: false,
    },
};
