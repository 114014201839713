import React from 'react';
import PropTypes from 'prop-types';

const VideoItem = ({
 id, title, image,
}) => (
	<div>
		<a href={`/Video/Details/${id}`} title={title}>
			<img src={image} alt={title} />
			<h3>{title}</h3>
		</a>
	</div>
);

VideoItem.propTypes = {
	id: PropTypes.number,
	title: PropTypes.string,
	image: PropTypes.string,
};

VideoItem.defaultProps = {
	id: 0,
	title: '',
	image: '',
};

export default VideoItem;
