import axios from 'axios';
import { errorNotification } from '../Notification';

export default function downloadReport(tableData) {
  axios
    .post('https://csvdownloader.azurewebsites.net/api/CreateCSV', {
      ...tableData,
    })
    .then((response) => {
      const today = new Date();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${today.getDate()}-${
          today.getMonth() + 1
        }-${today.getFullYear()}-report.csv`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      errorNotification(error);
    });
}
