import React from 'react';
import reactDom from '../../common/react/dom/index';
import NewsHomeContainer from '../../components/frontend/homepage/NewsHomeContainer';
import AddIntoNewsletterContainer from '../../components/common/AddIntoNewsletterContainer';
import ContactUsContainer from '../../components/common/ContactUsContainer';

export default function init() {
	reactDom(<NewsHomeContainer size={5} />, 'js-react-list');
	reactDom(<AddIntoNewsletterContainer />, 'js-react-newsletter');
	reactDom(<ContactUsContainer />, 'js-react-contact');
}
