import React from 'react';
import reactDom from './react/dom/index';
import LoginContainer from '../components/common/LoginContainer';
import MenuContainer from '../components/common/MenuContainer';

export default function init() {
	reactDom(
		<>
			<MenuContainer />
		</>,
		'js-react-menu',
	);
	reactDom(
		<>
			<LoginContainer />
		</>,
		'js-react-login',
	);
}
