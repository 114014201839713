/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Collapse } from 'react-collapse';
import sanitizeHtml from 'sanitize-html';
// import penLogo from 'https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg';
// import { presets } from 'react-motion';

import { authorsContentApiEndpoint } from '../../variables';

const date = __globalJsData.Date;

export default class ReactCollapse extends Component {
    static propTypes = {
        Authors: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        Authors: [],
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            authors: [],
            idOpened: 0,
            day: date,
            d: -1,
            enumMonth: '',
            y: -1,
        };
    }

    componentDidMount() {
        this.onLoadAuthors();
        const { day } = this.state;
        this.splitDateFunction(day);
    }

    onLoadAuthors() {
        const { Authors } = this.props;
        axios.post(`${authorsContentApiEndpoint}`, {
            Authors,
        })
            .then(({ data }) => {
                this.setState({ authors: data });
            }).catch(() => {
                this.setState({ authors: [] });
            });
    }

    onClickAuthName(id) {
        const { idOpened } = this.state;
        if (idOpened === 0 || idOpened !== id) {
            this.setState({ idOpened: id });
        } else {
            this.setState({ idOpened: 0 });
        }
    }

    splitDateFunction(dateToSplit) {
        this.setState({ d: dateToSplit.split('/')[0], y: dateToSplit.split('/')[2] });
        this.dummyEnumMonth(dateToSplit.split('/')[1]);
    }

    dummyEnumMonth(month) {
        switch (month) {
            case '01':
            case '1': this.setState({ enumMonth: 'Gennaio' });
                break;
            case '02':
            case '2': this.setState({ enumMonth: 'Febbraio' });
                break;
            case '03':
            case '3': this.setState({ enumMonth: 'Marzo' });
                break;
            case '04':
            case '4': this.setState({ enumMonth: 'Aprile' });
                break;
            case '05':
            case '5': this.setState({ enumMonth: 'Maggio' });
                break;
            case '06':
            case '6': this.setState({ enumMonth: 'Giugno' });
                break;
            case '07':
            case '7': this.setState({ enumMonth: 'Luglio' });
                break;
            case '08':
            case '8': this.setState({ enumMonth: 'Agosto' });
                break;
            case '09':
            case '9': this.setState({ enumMonth: 'Settembre' });
                break;
            case '10': this.setState({ enumMonth: 'Ottobre' });
                break;
            case '11': this.setState({ enumMonth: 'Novembre' });
                break;
            case '12': this.setState({ enumMonth: 'Dicembre' });
                break;
            default:
                break;
        }
    }

    render() {
        const {
            authors,
            idOpened,
        } = this.state;

        let {
            d,
            enumMonth,
            y,
        } = this.state;

        if (d === 'undefined' || d == null) d = '';
        else if (enumMonth === 'undefined' || enumMonth == null) enumMonth = '';
        else if (y === 'undefined' || y == null) y = '';

        const intro = 'di ';
        return (
            <div>
                <span>
                    <span className="greyCustomText">{`${d ?? ''} ${enumMonth ?? ''} ${y ?? ''}`}</span>
                    {/* , */}
                    &nbsp;
                    <br />
                    <br />
                    <br />
                    <img className="icon_pen" height="20" width="20" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                    <span className="greyCustomText">{intro}</span>
                </span>
                {authors && authors.length > 0
                    ? authors.map((data, i) => {
                        const isOpened = data.id === idOpened;
                        return (
                            (!data.shortDescription || data.shortDescription === '' || data.shortDescription === undefined || data.shortDescription === null) && (!data.image || data.image === '' || data.image === undefined || data.image === null) && (!data.description || data.description === '' || data.description === undefined || data.description === null)
                                ? (
                                    <span className="authorColor" key={data.id}>
                                        {(i ? ', ' : '') + data.onlyName}
                                        &nbsp;
                                        {data.cognome}
                                    </span>
                                ) : (
                                    <a key={data.id} className="authorColor auth-anchor" role="link" checked={isOpened} tabIndex="-1" onClick={() => this.onClickAuthName(data.id)}>
                                        {(i ? ', ' : '') + data.onlyName}
                                        &nbsp;
                                        {data.cognome}
                                    </a>
                                )
                        );
                    })
                    : null}
                {authors && authors.length > 0
                    ? authors.map((data) => {
                        const isOpened = data.id === idOpened;
                        return (
                            (!data.image || data.image === '' || data.image === undefined || data.image === null)
                                ? (
                                    <div key={data.id} className="auth-box-full row" style={{ marginRight: '15px' }}>
                                        <Collapse isOpened={isOpened}>
                                            <p style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                {data.onlyName}
                                                &nbsp;
                                                {data.cognome}
                                            </p>
                                            <p dangerouslySetInnerHTML={{ __html: sanitizeHtml((data.description && data.description !== '' && data.description !== undefined && data.description !== null) ? data.description : data.shortDescription, { allowedTags: ['a', 'b', 'i', 'u'], allowedAttributes: { 'a': ['href', 'target'] } }) }} />
                                        </Collapse>
                                    </div>
                                ) : (
                                    <div className="auth-box-full row " style={{ marginRight: '15px' }} key={data.id}>
                                        <Collapse isOpened={isOpened}>
                                            <div style={{
                                                display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '7px',
                                            }}
                                            >
                                                <img src={data.image} alt="" className="img-responsive" role="presentation" />
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5%' }}>
                                                    <p style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        {data.onlyName}
                                                        &nbsp;
                                                        {data.cognome}
                                                    </p>
                                                    <p dangerouslySetInnerHTML={{ __html: sanitizeHtml((data.description && data.description !== '' && data.description !== undefined && data.description !== null) ? data.description : data.shortDescription, { allowedTags: ['a', 'b', 'i', 'u'], allowedAttributes: { 'a': ['href', 'target'] } }) }} />
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                )
                        );
                    })
                    : null}
            </div>
        );
    }
}
