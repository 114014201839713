/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import PaperCampaigns from './items/PaperCampaigns';
import PaperUltimora from './items/PaperUltimora';
import PaperCoreContents from './items/PaperCoreContents';
import PaperVideoBox from './items/PaperVideoBox';
import PaperOtherItemsContainer from './items/PaperOtherItemsContainer';

export default class PaperContainer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            landingObject: props.LandingObject,
            campaigns: [],
            campaignsLoading: true,
            ultimora: [],
            ultimoraLoading: true,
            vpindex: 0,
            videoPrimoPianoList: [],
            videoPrimoPianoLoading: true,
            articoliNewsPrimoPiano: [],
            articoliNewsPrimoPianoLoading: true,
            otherArticoliNewsVideo: [],
            otherArticoliNewsVideoLoading: true,
            contenutiFiscoList: [],
            contenutiFiscoLoading: true,
            contenutiLavoroList: [],
            contenutiLavoroLoading: true,
            contenutiDirittoList: [],
            contenutiDirittoLoading: true,
            showDrop: false,
            idsToExclude: [],
        };

        this.handleClickVideoPoint = this.handleClickVideoPoint.bind(this);
        this.loadContenutiTematicaFisco = this.loadContenutiTematicaFisco.bind(this);
        this.loadContenutiTematicaLavoro = this.loadContenutiTematicaLavoro.bind(this);
        this.loadContenutiTematicaDiritto = this.loadContenutiTematicaDiritto.bind(this);
    }

    componentDidMount() {
        const { landingObject } = this.state;

        if (landingObject.CampagneLandingIds && landingObject.CampagneLandingIds.length > 0) {
            this.loadCampaigns(landingObject.CampagneLandingIds);
        }
        if (landingObject.NewsArticoliPrimoPianoCorrelati && landingObject.NewsArticoliPrimoPianoCorrelati.length > 0) {
            this.loadArticoliNewsPrimoPiano(landingObject.NewsArticoliPrimoPianoCorrelati);
        }
        // if (landingObject.VideoPrimoPianoCorrelati && landingObject.VideoPrimoPianoCorrelati.length > 0) {
        //     this.loadVideoPrimoPiano(landingObject.VideoPrimoPianoCorrelati);
        // }
        // if (landingObject.NewsArticoliVideoOtherCorrelati && landingObject.NewsArticoliVideoOtherCorrelati.length > 0) {
        //     this.loadOtherArticoliNewsVideo(landingObject.NewsArticoliVideoOtherCorrelati);
        // }
        // this.loadLastHours();

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('os');
        if (myParam) {
            this.displaySearch();
        }
    }

    displaySearch = () => {
        const x = document.getElementById('js-searchboxReact');
        if (x) {
            if (x.style.display === 'block') {
                x.style.display = 'none';
            } else {
                x.style.display = 'block';
            }
        }
    }

    loadCampaigns(campagneIds) {
        if (campagneIds) {
            axios.post('/api/PaperApi/loadCampaigns', {
                campagneIds,
            })
            .then(({ data: { resultList } }) => {
                this.setState({ campaigns: resultList, campaignsLoading: false });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ campaignsLoading: false });
            });
            this.setState({ campaignsLoading: true });
        }
    }

    loadLastHours() {
            const { idsToExclude } = this.state;
            axios.post('/api/PaperApi/loadLastHours', {
                idsToExclude,
            })
            .then(({ data: { resultList } }) => {
                const idsEx = resultList.map(a => a.id);
                const finalIds = idsEx.concat(idsToExclude);
                this.setState({ ultimora: resultList, ultimoraLoading: false, idsToExclude: finalIds }, () => {
                    this.loadContenutiTematicaFisco(finalIds);
                    this.loadContenutiTematicaLavoro(finalIds);
                    this.loadContenutiTematicaDiritto(finalIds);
                });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ ultimoraLoading: false });
            });
            this.setState({ ultimoraLoading: true });
    }

    loadVideoPrimoPiano(videoIds) {
        const { landingObject } = this.state;
        if (videoIds) {
            axios.post('/api/PaperApi/loadVideoPrimoPiano', {
                videoIds,
            })
            .then(({ data: { resultList } }) => {
                this.setState({ videoPrimoPianoList: resultList, videoPrimoPianoLoading: false }, () => {
                    if (landingObject.NewsArticoliVideoOtherCorrelati && landingObject.NewsArticoliVideoOtherCorrelati.length > 0) {
                        this.loadOtherArticoliNewsVideo(landingObject.NewsArticoliVideoOtherCorrelati);
                    } else {
                        this.loadLastHours();
                    }
                });
            })
            .catch((error) => {
                let e = 'Errore caricamento contenuti';
                if (error) {
                    e += ` ${error}`;
                }
                notification.info({
                    message: e,
                });
                this.setState({ videoPrimoPianoLoading: false });
            });
            this.setState({ videoPrimoPianoLoading: true });
        }
    }

    loadArticoliNewsPrimoPiano(ids) {
        const { idsToExclude, landingObject } = this.state;
        axios.post('/api/PaperApi/loadArticoliNewsPrimoPiano', {
            ids,
        })
        .then(({ data: { resultList } }) => {
            const idsEx = resultList.map(a => a.id);
            const finalIds = idsEx.concat(idsToExclude);
            this.setState({ articoliNewsPrimoPiano: resultList, articoliNewsPrimoPianoLoading: false, idsToExclude: finalIds }, () => {
                if (landingObject.VideoPrimoPianoCorrelati && landingObject.VideoPrimoPianoCorrelati.length > 0) {
                    this.loadVideoPrimoPiano(landingObject.VideoPrimoPianoCorrelati);
                } else if (landingObject.NewsArticoliVideoOtherCorrelati && landingObject.NewsArticoliVideoOtherCorrelati.length > 0) {
                    this.loadOtherArticoliNewsVideo(landingObject.NewsArticoliVideoOtherCorrelati);
                } else {
                    this.loadLastHours();
                }
            });
        })
        .catch((error) => {
            let e = 'Errore caricamento contenuti';
            if (error) {
                e += ` ${error}`;
            }
            notification.info({
                message: e,
            });
            this.setState({ articoliNewsPrimoPianoLoading: false });
        });

        this.setState({ articoliNewsPrimoPianoLoading: true });
    }

    loadOtherArticoliNewsVideo(ids) {
        const { idsToExclude } = this.state;
        axios.post('/api/PaperApi/loadOtherArticoliNewsVideo', {
            ids,
            idsToExclude,
        })
        .then(({ data: { resultList } }) => {
            const idEx = resultList.map(r => r.id);
            const finalIds = idEx.concat(idsToExclude);
            this.setState({ otherArticoliNewsVideo: resultList, otherArticoliNewsVideoLoading: false, idsToExclude: finalIds }, () => {
                this.loadLastHours();
            });
        })
        .catch((error) => {
            let e = 'Errore caricamento contenuti';
            if (error) {
                e += ` ${error}`;
            }
            notification.info({
                message: e,
            });
            this.setState({ otherArticoliNewsVideoLoading: false });
        });
        this.setState({ otherArticoliNewsVideoLoading: true });
    }

    loadContenutiTematicaFisco(idsToExclude) {
        const themes = ['Fisco'];
        axios.post('/api/PaperApi/loadContentsByThem', {
            idsToExclude,
            them: themes,
        })
        .then(({ data: { resultList } }) => {
            if (resultList?.length > 0) {
                this.setState({ contenutiFiscoList: resultList.slice(0, 2), contenutiFiscoLoading: false });
            } else {
                this.setState({ contenutiFiscoList: [], contenutiFiscoLoading: false });
            }
        })
        .catch((error) => {
            let e = 'Errore caricamento contenuti';
            if (error) {
                e += ` ${error}`;
            }
            notification.info({
                message: e,
            });
            this.setState({ contenutiFiscoLoading: false });
        });
        this.setState({ contenutiFiscoLoading: true });
    }

    loadContenutiTematicaLavoro(idsToExclude) {
        const themes = ['Lavoro'];
        axios.post('/api/PaperApi/loadContentsByThem', {
            idsToExclude,
            them: themes,
        })
        .then(({ data: { resultList } }) => {
            if (resultList?.length > 0) {
                this.setState({ contenutiLavoroList: resultList.slice(0, 2), contenutiLavoroLoading: false });
            } else {
                this.setState({ contenutiLavoroList: [], contenutiLavoroLoading: false });
            }
        })
        .catch((error) => {
            let e = 'Errore caricamento contenuti';
            if (error) {
                e += ` ${error}`;
            }
            notification.info({
                message: e,
            });
            this.setState({ contenutiLavoroLoading: false });
        });
        this.setState({ contenutiLavoroLoading: true });
    }

    loadContenutiTematicaDiritto(idsToExclude) {
        const themes = ['Diritto dell\'Impresa e delle Società', 'Diritto', 'Diritto penale'];
        axios.post('/api/PaperApi/loadContentsByThem', {
            idsToExclude,
            them: themes,
        })
        .then(({ data: { resultList } }) => {
            if (resultList?.length > 0) {
                this.setState({ contenutiDirittoList: resultList.slice(0, 2), contenutiDirittoLoading: false });
            } else {
                this.setState({ contenutiDirittoList: [], contenutiDirittoLoading: false });
            }
        })
        .catch((error) => {
            let e = 'Errore caricamento contenuti';
            if (error) {
                e += ` ${error}`;
            }
            notification.info({
                message: e,
            });
            this.setState({ contenutiDirittoLoading: false });
        });
        this.setState({ contenutiDirittoLoading: true });
    }

    handleClickVideoPoint(vpindex) {
        this.setState({ vpindex });
    }

    render() {
        const { landingObject, showDrop, ...state } = this.state;
        const showDiv = showDrop ? '' : 'hidden';
        return (
            <div className="dp_paper_content">
                <div className="dp_paper_content_container">
                    <div className="row dp_paper_content_header">
                        <div className="col-3">
                            <a href="#" onClick={() => { this.setState({ showDrop: !showDrop }); }} className="rss_anchor">
                                <i className="fa fa-rss rss_icon" />
                            </a>
                            <div className={showDiv}>
                                <a href="/api/RSSApi/getRSSNews" target="_blank">
                                    <i className="fa fa-rss rss_icon" />
                                    News RSS
                                </a>
                                <br />
                                <a href="/api/RSSApi/getRSSVideo" target="_blank">
                                    <i className="fa fa-rss rss_icon" />
                                    Video RSS
                                </a>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="brand_container text-center">
                                <img className="img-fluid" src="https://directiositeassets.blob.core.windows.net/servizi/Images/logo.png" alt="Directio" />
                                <span>{landingObject.DataHeaderQuotidiano}</span>
                                <small>Direttore responsabile Alessio Berardino - Reg. Trib. Torino - n.10 - 29/04/2013</small>
                            </div>
                        </div>
                        <div className="col-3">
                            {/* <div className="search_subscribe">
                                <a href={null} onClick={() => this.displaySearch()} className="search_item d-none d-sm-block"><i className="fa fa-search" /></a>
                                <a href="/#subscribe" className="btn btn-primary subscribe_item">REGISTRATI</a>
                            </div> */}
                        </div>
                    </div>
                    <div className="row dp_paper_campaigns">
                        <div className="col-12 dp_paper_campaigns_col12">
                            {!state.campaignsLoading && state.campaigns && state.campaigns.length > 0 ? (
                                <PaperCampaigns Campaigns={state.campaigns} />
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                    <div className="row dp_paper_core dp_paper_ultimora" style={{ padding: '0px' }}>
                        <div className="col-12 dp_paper_ultimora_col12">
                            {!state.ultimoraLoading && state.ultimora && state.ultimora.length > 0 ? (
                                <PaperUltimora Ultimora={state.ultimora} />
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                    <div className="row dp_paper_maintitle" style={{ paddingRight: 'px', paddingLeft: 'px' }}>
                        <div className="col-12 dp_paper_maintitle_col12 text-center">
                            {Object.entries(landingObject).length > 0 && landingObject.ShortDescription && landingObject.ShortDescription !== '' && (
                                <div dangerouslySetInnerHTML={{ __html: landingObject.ShortDescription }} />
                            )}
                        </div>
                    </div>

                    <div className="row dp_paper_core">
                    <hr className="d-none d-sm-block" style={{ width: '100%', borderTop: '1px solid lightgray', margin: '20px 15px' }} />
                        <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                            {!state.articoliNewsPrimoPianoLoading && state.articoliNewsPrimoPiano.length > 0 ? (
                                <PaperCoreContents ArticoliNewsPrimoPiano={state.articoliNewsPrimoPiano} />
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-5 mt-5 mt-md-0 videobox">
                            <div className="card" style={{ borderRight: '10px solid #78b3d3', borderBottom: '10px solid #78b3d3' }}>
                                {!state.videoPrimoPianoLoading && Object.entries(state.videoPrimoPianoList).length > 0 ? (
                                    <PaperVideoBox
                                        VideoPrimoPianoList={state.videoPrimoPianoList}
                                        VPIndex={state.vpindex}
                                        HandleClickVideoPoint={this.handleClickVideoPoint}
                                    />
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* mt-5    other_items_container" */}
                    <div className="title_video_ultim"> </div>
                    <div className="row dp_paper_core" style={{ marginTop: '0px !important' }}>

                        {!state.otherArticoliNewsVideoLoading && state.otherArticoliNewsVideo && state.otherArticoliNewsVideo.length > 0 ? (
                            state.otherArticoliNewsVideo.slice(0, 4).map(value => (
                                <PaperOtherItemsContainer Item={value} key={`${value.typeDoc}_${value.id}`} />
                            ))
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    <div className=" dp_paper_core row mt-5 mt-5 mt-md-4 random_thematic_items_container">
                        <div className="col-12 col-lg-12 col-xl-4 random_thematic_item fisco">
                            <div className="rti_heading">
                                <strong>FISCO &amp; AZIENDA</strong>
                                <a href="/Archivio/Tematiche?t=Fisco">Vedi tutti</a>
                            </div>
                            <div className="row rti_body">
                                {!state.contenutiFiscoLoading && state.contenutiFiscoList && state.contenutiFiscoList.length > 0 ? (
                                    state.contenutiFiscoList.map((value, i) => {
                                        let contentUrl = '';
                                        let contentOf = 'di ';
                                        if (value !== undefined) {
                                            switch (value.typeDoc) {
                                                case 'articolo':
                                                case 'articoli':
                                                    contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'news':
                                                    contentUrl = `/News/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'video':
                                                    contentUrl = `/Video/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        return (
                                            <div key={`${value.id}_${value.typeDoc}`} className={i === 0 ? 'bordered' : null}>
                                                <div className="col-12 col-lg-6 rti_body_img">
                                                    <a href={contentUrl}><img src={value.image} alt="rti img" className="img-fluid" /></a>
                                                </div>
                                                <div className="col-12 col-lg-6 rti_body_txt">
                                                    {(value.thematicCategories || value.thematics) && (
                                                        value.thematicCategories.length > 0 ? (
                                                            value.thematicCategories.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                        ) : (
                                                            value.thematics.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                    ))}
                                                    <a href={contentUrl}><h4>{value.title}</h4></a>
                                                    <span className="rti_body_txt_author">
                                                    <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />

                                                        &nbsp;&nbsp;
                                                        <span className="article_of">{contentOf}</span>
                                                        {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                            <span key={index}>{`${author} `}</span>
                                                        ))}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-4 random_thematic_item lavoro">
                            <div className="rti_heading">
                                <strong>LAVORO &amp; SICUREZZA</strong>
                                <a href="/Archivio/Tematiche?t=Lavoro">Vedi tutti</a>
                            </div>
                            <div className="row rti_body">
                                {!state.contenutiLavoroLoading && state.contenutiLavoroList && state.contenutiLavoroList.length > 0 ? (
                                    state.contenutiLavoroList.map((value, i) => {
                                        let contentUrl = '';
                                        let contentOf = 'di ';
                                        if (value !== undefined) {
                                            switch (value.typeDoc) {
                                                case 'articolo':
                                                case 'articoli':
                                                    contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'news':
                                                    contentUrl = `/News/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'video':
                                                    contentUrl = `/Video/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        return (
                                            <div key={`${value.id}_${value.typeDoc}`} className={i === 0 ? 'bordered' : null}>
                                                <div className="col-12 col-lg-6 rti_body_img">
                                                    <a href={contentUrl}><img src={value.image} alt="rti img" className="img-fluid" /></a>
                                                </div>
                                                <div className="col-12 col-lg-6 rti_body_txt">
                                                    {(value.thematicCategories || value.thematics) && (
                                                        value.thematicCategories.length > 0 ? (
                                                            value.thematicCategories.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                        ) : (
                                                            value.thematics.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                    ))}
                                                    <a href={contentUrl}><h4>{value.title}</h4></a>
                                                    <span className="rti_body_txt_author">
                                                        {/* <PersonIcon /> */}
                                                        <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                                        &nbsp;&nbsp;
                                                        <span className="article_of">{contentOf}</span>
                                                        {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                            <span key={index}>{`${author} `}</span>
                                                        ))}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-4 random_thematic_item diritto">
                            <div className="rti_heading">
                                <strong>DIRITTO &amp; TERZO SETTORE</strong>
                                <a href="/Archivio/Tematiche?t=Diritto dell'Impresa e delle Società,Diritto,Diritto penale">Vedi tutti</a>
                            </div>
                            <div className="row rti_body">
                                {!state.contenutiDirittoLoading && state.contenutiDirittoList && state.contenutiDirittoList.length > 0 ? (
                                    state.contenutiDirittoList.map((value, i) => {
                                        let contentUrl = '';
                                        let contentOf = 'di ';
                                        if (value !== undefined) {
                                            switch (value.typeDoc) {
                                                case 'articolo':
                                                case 'articoli':
                                                    contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'news':
                                                    contentUrl = `/News/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                case 'video':
                                                    contentUrl = `/Video/Details/${value.id}/${value.niceUrl}/?from=paper`;
                                                    contentOf = 'di ';
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        return (
                                            <div key={`${value.id}_${value.typeDoc}`} className={i === 0 ? 'bordered' : null}>
                                                <div className="col-12 col-lg-6 rti_body_img">
                                                    <a href={contentUrl}><img src={value.image} alt="rti img" className="img-fluid" /></a>
                                                </div>
                                                <div className="col-12 col-lg-6 rti_body_txt">
                                                    {(value.thematicCategories || value.thematics) && (
                                                        value.thematicCategories.length > 0 ? (
                                                            value.thematicCategories.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                        ) : (
                                                            value.thematics.map((thematic, index) => (
                                                                <span key={index} className="them_rti_body_txt">{thematic}</span>
                                                            ))
                                                    ))}
                                                    <a href={contentUrl}><h4>{value.title}</h4></a>
                                                    <span className="rti_body_txt_author">
                                                        {/* <PersonIcon /> */}
                                                        <img className="icon_pen" src="https://directiositeassets.blob.core.windows.net/servizi/home/pen-nib-solid.svg" alt="editorial" />
                                                        &nbsp;&nbsp;
                                                        <span className="article_of">{contentOf}</span>
                                                        {value.authorsNames !== undefined && value.authorsNames.map((author, index) => (
                                                            <span key={index}>{`${author} `}</span>
                                                        ))}
                                                    </span>

                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


PaperContainer.propTypes = {
    LandingObject: PropTypes.shape({}),
};

PaperContainer.defaultProps = {
    LandingObject: '',
};
