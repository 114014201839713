import React from 'react';
import PropTypes from 'prop-types';

const PaperUltimora = ({ Ultimora }) => (
    <div>
        <div className="row" style={{ backgroundColor: '#ffffff' }}>
            <div className="col-12 float-left" style={{ padding: '0px' }}>
                {/* <h4>ULTIM&apos;ORA</h4> */}
                <div className="top_title_w" style={{ paddingBottom: '15px' }}>ULTIM&apos;ORA</div>

            </div>
        </div>
        <div className="row" style={{ backgroundColor: '#ffffff', paddingBottom: '15px', borderBottom: '1px solid #D3D3D3' }}>
            {Ultimora.map((value) => {
                let contentUrl = '';
                switch (value.typeDoc) {
                    case 'articolo':
                    case 'articoli':
                        contentUrl = `/Articles/Details/${value.id}/${value.niceUrl}/?from=paper`;
                        break;
                    case 'news':
                        contentUrl = `/News/Details/${value.id}/${value.niceUrl}/?from=paper`;
                        break;
                    case 'video':
                        contentUrl = `/Video/Details/${value.id}/${value.niceUrl}/?from=paper`;
                        break;
                    default:
                        break;
                }
                return (
                    <div key={value.id} className="col-12 col-lg-3 mt-3 mt-md-2 mt-lg-0">
                        {/* <div className="ultimora_container"> */}
                        <div className="p_container">
                            <img src={`${value.image}`} alt={value.title} className="paper_img" />
                            <a href={contentUrl} rel="noopener noreferrer">{value.title}</a>
                        </div>
                    </div>
                );
            })}
        </div>

    </div>
);

PaperUltimora.propTypes = {
    Ultimora: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PaperUltimora;
