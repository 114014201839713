/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-const */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Form,
    Input,
    Button,
    Upload,
} from 'antd';

const { Dragger } = Upload;

class HelpdeskForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {
                nome: null,
                cognome: null,
                oggetto: null,
                descrizione: null,
                email: null,
                fileList: [],
            },
        };

        this.helpdeskSubmit = this.helpdeskSubmit.bind(this);

        this.updateNome = this.updateNome.bind(this);
        this.updateCognome = this.updateCognome.bind(this);
        this.updateOggetto = this.updateOggetto.bind(this);
        this.updateDescrizione = this.updateDescrizione.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateFile = this.updateFile.bind(this);
    }

    updateNome(value) {
        let { model } = this.state;
        model.nome = value.target.value;
        this.setState({ model });
    }

    updateCognome(value) {
        let { model } = this.state;
        model.cognome = value.target.value;
        this.setState({ model });
    }

    updateOggetto(value) {
        let { model } = this.state;
        model.oggetto = value.target.value;
        this.setState({ model });
    }

    updateDescrizione(value) {
        let { model } = this.state;
        model.descrizione = value.target.value;
        this.setState({ model });
    }

    updateEmail(value) {
        let { model } = this.state;
        model.email = value.target.value;
        this.setState({ model });
    }

    updateFile(value) {
        let { model } = this.state;
        model.fileList = value.fileList;
        this.setState({ model });
    }

    helpdeskSubmit() {
        let { model } = this.state;
        model.name = 'DEH';
        this.setState({ model });
    }

    render() {
        const { model } = this.state;

        return (
            <div>
                <Form
                    name="basic"
                    labelCol
                    wrapperCol
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.helpdeskSubmit}
                >
                    <Form.Item
                        label="Nome"
                        name="nome"
                        rules={[{ required: true, message: 'Inserire il campo mancante!' }]}
                    >
                        <Input value={model.name} onChange={this.updateNome} placeholder="nome" />
                    </Form.Item>

                    <Form.Item
                        label="Cognome"
                        name="cognome"
                        rules={[{ required: true, message: 'Inserire il campo mancante!' }]}
                    >
                        <Input value={model.cognome} onChange={this.updateCognome} placeholder="cognome" />
                    </Form.Item>

                    <Form.Item
                        label="Oggetto"
                        name="oggetto"
                        rules={[{ required: true, message: 'Inserire il campo mancante!' }]}
                    >
                        <Input value={model.oggetto} onChange={this.updateOggetto} placeholder="oggetto" />
                    </Form.Item>

                    <Form.Item
                        label="Descrizione segnalazione"
                        name="descrizione segnalazione"
                        rules={[{ required: true, message: 'Inserire il campo mancante!' }]}
                    >
                        <Input value={model.descrizione} onChange={this.updateDescrizione} placeholder="descrizione segnalazione" />
                    </Form.Item>

                    <Form.Item
                        label="E-mail"
                        name="e-mail"
                        rules={[{ required: true, message: 'Inserire il campo mancante!' }]}
                    >
                        <Input value={model.email} onChange={this.updateEmail} placeholder="e-mail" />
                    </Form.Item>

                    <Dragger
                        onChange={this.updateFile}
                        multiple
                        accept=".png, .jpg, .jpeg"
                        fileList={model.fileList}
                        beforeUpload={() => false}
                        style={{ background: '#ffffff', height: '180px !important', padding: '50px' }}
                    >

                        <p className="ant-upload-text">
                            <strong>Scegli file</strong>
                            &nbsp;o trascinalo qui
                        </p>

                    </Dragger>

                    <Form.Item style={{ float: 'right' }}>
                        <Button className="btn_n" htmlType="submit">
                            INVIA
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData,
    };
}

export default connect(mapStateToProps)(HelpdeskForm);


HelpdeskForm.propTypes = {

    userData: PropTypes.shape({
        id: PropTypes.number,
        clientType: PropTypes.number,
        email: PropTypes.string,
        billingInfo: PropTypes.shape({

            nome: PropTypes.string,
            cognome: PropTypes.string,
            cf: PropTypes.string,
            uniCode: PropTypes.string,
            iva: PropTypes.string,
            pec: PropTypes.string,
            ragioneSociale: PropTypes.string,
            indirizzo: PropTypes.string,
            nCivico: PropTypes.string,
            cap: PropTypes.string,
            istatCode: PropTypes.string,
        }),
        telefono: PropTypes.string,
        professione: PropTypes.string,
        tematicheInteresse: PropTypes.string,
        advConsent: PropTypes.bool,
        username: PropTypes.string,
        isLogged: PropTypes.bool,
    }),

};
HelpdeskForm.defaultProps = {

    userData: {
        id: 0,
        clientType: 1,
        billingInfo: {

            nome: null,
            cognome: null,
            cf: null,
            uniCode: null,
            iva: null,
            pec: null,
            ragioneSociale: null,
            indirizzo: null,
            nCivico: null,
            cap: null,
            istatCode: null,
        },
        email: null,
        telefono: null,
        professione: null,
        tematicheInteresse: null,
        advConsent: false,
        username: null,
        isLogged: false,
        isLoaded: false,
    },
};
