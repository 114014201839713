/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import reactStamp from 'react-stamp';
import axios from 'axios';
import Slider from 'react-rangeslider';
import Checkbox from 'rc-checkbox';
// import Select from 'react-select';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from 'react-simple-card';
import _ from 'lodash'; // js library with a lot of js funcs/methods
// Componenti che uso per prendere relativi articoli, news e video
import { notification, Select } from 'antd';
import LastArticlesItem from './items/LastArticlesItem';
import LastNewsItem from './items/LastNewsItem';
import LastVideosItem from './items/LastVideosItem';
import LoadingSpinner from '../../common/LoadingSpinner';
import { WidgetEndPoint } from '../../variables';

const { urlWebApiContent } = __globalJsData;

// TO DO: API EndPoints da definire poi bene in variables
const lastArticlesForWidgetApiEndpoint = '/api/ArticlesApi/getLastArticlesByCategory'; // getLastArticlesForWidget`;
const lastNewsForWidgetApiEndpoint = '/api/NewsApi/getLastNewsByCategory'; // /getLastNewsForWidget`;
const lastVideoForWidgetApiEndpoint = '/api/VideoApi/getLastVideoByCategory'; // /getLastVideoForWidget`;
const generateHtmlWidgetEndpoint = '/api/contentApi/getHtmlStringForWidgetAreeTematiche/1';
//

// const ConsulenzaLogo = 'https://bdconsulenzastorage.blob.core.windows.net/site-assets/images/eFattura_images/C-widget.png';
const testVideo = 'https://bdconsulenzastorage.blob.core.windows.net/site-assets/images/eFattura_images/test-video.png';

const {
    AreeTematiche,
    VideoCorrelati,
    ArticoliCorrelati,
    NewsCorrelate,
    articoloId,
    newsId,
    videoId,
} = __globalJsData;
const hasWidgetEnabled = __globalJsData.HasWidgetEnabled;

const {
    widgetIdShop,
    idLanding,
    linkServizio,
    linkProdottoFull,
    linkProdottoBase,
    Dominio,
    ArticoliSize,
    NewsSize,
    VideoSize,
} = __globalJsData;

/* IMPOSTAZIONI GLOBALI DI PERSONALIZZAZIONE UTENTE */
const widgetWidth = 300;
const widgetHeigth = 670;
const articlesSize = 1;
const newsSize = 1;
const videoSize = 1;

// TO DO: AGGIUNGERE SELETTORE DELLE CAMPAGNE
const container = {
    // A string used in React debugging messages or React Debugger .
    displayName: 'WidgetAreeTematiche',
    init() {
        this.onDeleteCategory = this.onDeleteCategory.bind(this);
        this.onAddCategory = this.onAddCategory.bind(this);
        this.handleCategoryTypeChange = this.handleCategoryTypeChange.bind(this);
        this.onChangeArticlesNumber = this.onChangeArticlesNumber.bind(this);
        this.handleChangeArticles = this.handleChangeArticles.bind(this);
        this.onChangeNewsNumber = this.onChangeNewsNumber.bind(this);
        this.handleChangeNews = this.handleChangeNews.bind(this);
        this.onChangeVideosNumber = this.onChangeVideosNumber.bind(this);
        this.handleChangeVideos = this.handleChangeVideos.bind(this);
        this.onClickedButton = this.onClickedButton.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.onReloadElements = this.onReloadElements.bind(this);
        this.onReloadRelativeArticles = this.onReloadRelativeArticles.bind(this);
        this.onReloadRelativeNews = this.onReloadRelativeNews.bind(this);
        this.onReloadRelativeVideos = this.onReloadRelativeVideos.bind(this);
        this.onLoadAreeTematiche = this.onLoadAreeTematiche.bind(this);
        this.handleAreeTematicheChange = this.handleAreeTematicheChange.bind(this);
        this.onReloadAllElements = this.onReloadAllElements.bind(this);
    },
    componentDidMount() {
        this.onLoadEntities();
    },
    onLoadEntities() {
        this.onLoadAreeTematiche();
        this.onReloadRelativeArticles();
        this.onReloadRelativeNews();
        this.onReloadRelativeVideos();
    },
    state: {
        bColor: '#494949',
        fColor: '#ffffff',
        isDark: true,
        isLight: false,
        isArticoli: true,
        isNews: true,
        isVideos: true,
        categoryStatesSelected: [],
        // imgPath: ConsulenzaLogo,
        items: [],
        dataSize: 0,
        queryMaxTake: 0,
        loaded: true,
        aLoading: false,
        nLoading: false,
        vLoading: false,
        htmlLoading: false,
        // campagneIdList: campagnaId,
        aValue: (ArticoliSize || 1),
        nValue: (NewsSize || 1),
        vValue: (VideoSize || 1),

        wIsNull: false,
        hIsNull: false,
        dIsNull: false,
        eventW: true,
        eventH: true,
        eventD: true,

        articleNumbersSelected: [],
        newsNumbersSelected: [],
        videoNumbersSelected: [],

        htmlTextbox: '',
        textboxWidth: '300',
        textboxHeight: '800',
        textboxDomain: (Dominio || ''),
        widgetWidth,
        widgetHeigth,
        articlesSize,
        newsSize,
        videoSize,

        articoloList: [],
        videoList: [],
        newsList: [],
        articoloLoaded: false,
        videoLoaded: false,
        newsLoaded: false,

        filterAreeTematiche: [],
        selectedAreeTematiche: [],
        areeTematicheListLoaded: false,
        areeTematicheList: undefined,
        hasErrors: false,
    },
    handleAreeTematicheChange(values) {
        const { filterAreeTematiche, selectedAreeTematiche } = this.state;
        if (values && Object.entries(values).length > 0) {
            // const newListString = selectedAreeTematiche.join('|');
            // const indexArea = filterAreeTematiche.indexOf(values);
            // filterAreeTematiche.splice(indexArea, 1);
            this.setState({ selectedAreeTematiche: values });
            this.onReloadAllElements();
        } else {
            this.setState({ filterAreeTematiche: [], selectedAreeTematiche: [] });
            this.onReloadAllElements(null);
        }
    },
    onLoadAreeTematiche() {
        debugger;
        const { selectedAreeTematiche } = this.state;
        const newList = [];
        const newValue = [];
        const filterValues = [];
        if (AreeTematiche !== null && AreeTematiche.length > 0) {
            const areeTematiche = AreeTematiche;
            // newValue.push({ value: '', label: '' });
            areeTematiche.forEach((val) => {
                // let options = undefined;
                if (val.SubAree && val.SubAree.length > 0) {
                    // options = [];
                    val.SubAree.forEach((sub) => {
                        newValue.push({ value: sub.Id, label: `${val.Title} - ${sub.Title}` });
                        // options.push({ value: sub.Id, label: `${val.Title} - ${sub.Title}` });
                    });
                } else {
                    newValue.push({ value: val.Id, label: val.Title });
                }
            });

            if (selectedAreeTematiche && selectedAreeTematiche.length > 0) {
                selectedAreeTematiche.forEach((area) => {
                    const filter = selectedAreeTematiche.filter(at => (at.value ? at.value.toString() : 0) === area.Id.toString());
                    if (filter && filter[0]) {
                        filterValues.push(filter[0]);
                        newList.push(filter[0].value);
                    }
                });
            }
            const newListString = newList.join('|');
            this.onReloadAllElements(newListString);
            this.setState({
                areeTematicheListLoaded: true,
                areeTematicheList: newValue,
                filterAreeTematiche: filterValues,
                selectedAreeTematiche: newListString,
            });
            return newValue;
        }
        this.setState({
            areeTematicheListLoaded: false,
            areeTematicheList: [],
            filterAreeTematiche: [],
            selectedAreeTematiche: undefined,
        });
        return [];
    },
    handleCategoryTypeChange(event) {
        const value = event.target.checked;
        const { target } = event;
        const inputName = target.name;
        let { categoryStatesSelected } = this.state;
        if (inputName === 'isArticoli' && value === false) {
            categoryStatesSelected = _.without(categoryStatesSelected, inputName);
            this.onDeleteCategory(inputName, value);
            this.setState({ categoryStatesSelected });
        } else if (inputName === 'isArticoli' && value === true) {
            categoryStatesSelected.push(inputName);
            this.onAddCategory(inputName, value);
            this.setState({ categoryStatesSelected });
        } else if (inputName === 'isNews' && value === false) {
            categoryStatesSelected = _.without(categoryStatesSelected, inputName);
            this.onDeleteCategory(inputName, value);
            this.setState({ categoryStatesSelected });
        } else if (inputName === 'isNews' && value === true) {
            categoryStatesSelected.push(inputName);
            this.onAddCategory(inputName, value);
            this.setState({ categoryStatesSelected });
        } else if (inputName === 'isVideos' && value === false) {
            categoryStatesSelected = _.without(categoryStatesSelected, inputName);
            this.onDeleteCategory(inputName, value);
            this.setState({ categoryStatesSelected });
        } else if (inputName === 'isVideos' && value === true) {
            categoryStatesSelected.push(inputName);
            this.onAddCategory(inputName, value);
            this.setState({ categoryStatesSelected });
        }
    },
    onDeleteCategory(inputName) {
        switch (inputName) {
            case 'isArticoli':
                this.setState({ isArticoli: false });
                this.onChangeArticlesNumber(0);
                break;
            case 'isNews':
                this.setState({ isNews: false });
                this.onChangeNewsNumber(0);
                break;
            case 'isVideos':
                this.setState({ isVideos: false });
                this.onChangeVideosNumber(0);
                break;
            default:
                break;
        }
    },
    onAddCategory(inputName) {
        const { aValue, nValue, vValue } = this.state;
        switch (inputName) {
            case 'isArticoli':
                this.setState({ isArticoli: true, aValue });
                this.onChangeArticlesNumber(aValue);
                break;
            case 'isNews':
                this.setState({ isNews: true, nValue });
                this.onChangeNewsNumber(nValue);
                break;
            case 'isVideos':
                this.setState({ isVideos: true, vValue });
                this.onChangeVideosNumber(vValue);
                break;
            default:
                break;
        }
    },
    handleChangeArticles(value) {
        const { isArticoli } = this.state;
        if (value !== this.state.aValue) {
            this.setState({
                aValue: value,
            });
            if (isArticoli === true) {
                this.onChangeArticlesNumber(value);
            }
        }
    },
    onChangeArticlesNumber(value) {
        let articlesSizer = value;
        switch (articlesSizer) {
            case 0:
                articlesSizer = 0;
                this.setState({ articlesSizer });
                this.onReloadRelativeArticles(articlesSizer);
                break;
            case 1:
                articlesSizer = 1;
                this.setState({ articlesSizer });
                this.onReloadRelativeArticles(articlesSizer);
                break;
            case 2:
                articlesSizer = 2;
                this.setState({ articlesSizer });
                this.onReloadRelativeArticles(articlesSizer);
                break;
            case 3:
                articlesSizer = 3;
                this.setState({ articlesSizer });
                this.onReloadRelativeArticles(articlesSizer);
                break;
            default:
                break;
        }
    },
    handleChangeNews(value) {
        const { isNews } = this.state;
        if (value === this.state.nValue) {
            this.setState({
                nValue: value,
            });
            if (isNews === true) {
                this.onChangeNewsNumber(value);
            }
        }
    },
    onChangeNewsNumber(value) {
        let newsSizer = value;
        switch (newsSizer) {
            case 0:
                newsSizer = 0;
                this.setState({ newsSizer });
                this.onReloadRelativeNews(newsSizer);
                break;
            case 1:
                newsSizer = 1;
                this.setState({ newsSizer });
                this.onReloadRelativeNews(newsSizer);
                break;
            case 2:
                newsSizer = 2;
                this.setState({ newsSizer });
                this.onReloadRelativeNews(newsSizer);
                break;
            case 3:
                newsSizer = 3;
                this.setState({ newsSizer });
                this.onReloadRelativeNews(newsSizer);
                break;
            default:
                break;
        }
    },
    handleChangeVideos(value) {
        const { isVideos } = this.state;
        if (value === this.state.vValue) {
            this.setState({
                vValue: value,
            });
            if (isVideos === true) {
                this.onChangeVideosNumber(value);
            }
        }
    },
    onChangeVideosNumber(value) {
        let videoSizer = value;
        switch (videoSizer) {
            case 0:
                videoSizer = 0;
                this.setState({ videoSizer });
                this.onReloadRelativeVideos(videoSizer);
                break;
            case 1:
                videoSizer = 1;
                this.setState({ videoSizer });
                this.onReloadRelativeVideos(videoSizer);
                break;
            case 2:
                videoSizer = 2;
                this.setState({ videoSizer });
                this.onReloadRelativeVideos(videoSizer);
                break;
            case 3:
                videoSizer = 3;
                this.setState({ videoSizer });
                this.onReloadRelativeVideos(videoSizer);
                break;
            default:
                break;
        }
    },
    onReloadAllElements(areaList = undefined) {
        this.onReloadRelativeArticles(undefined, areaList);
        this.onReloadRelativeNews(undefined, areaList);
        this.onReloadRelativeVideos(undefined, areaList);
    },
    onReloadRelativeArticles(articleSize = undefined, newList = undefined) {
        const { selectedAreeTematiche } = this.state;
        let aSize = articlesSize;
        let areetematiche = selectedAreeTematiche;
        if (newList) { areetematiche = newList; }

        if (articleSize === 0) {
            aSize = 0;
        } else if (articleSize) {
            aSize = articleSize;
        }

        let newArticoloList = [];
        let isLoaded = false;
        debugger;
        if (ArticoliCorrelati != null && ArticoliCorrelati.length > 0) {
            axios.post(lastArticlesForWidgetApiEndpoint, {
                idList: ArticoliCorrelati,
            }).then(({ data }) => {
                isLoaded = true;
                if (data.data != null) {
                    newArticoloList = data.data;
                }
                this.setState({
                    hasErrors: false,
                    articoloList: newArticoloList,
                    articoloLoaded: isLoaded,
                    aLoading: false,
                });
            });
            this.setState({ aLoading: true });
        } else {
            if (areetematiche && areetematiche.length > 0) {
                debugger;
                const url = `${lastArticlesForWidgetApiEndpoint}/?id=${areetematiche[0].value}&t=${areetematiche[0].label}&size=${aSize}`;
                // , {
                //     size: aSize,
                //     start: 0,
                //     AreeTematiche: this.state.AreeTematiche
                // }
                axios.get(url).then(({ data }) => {
                    isLoaded = true;
                    if (data.data != null) {
                        newArticoloList = data.data;
                    }
                    this.setState({
                        hasErrors: false,
                        articoloList: newArticoloList,
                        articoloLoaded: isLoaded,
                        articlesSize: aSize,
                        aLoading: false,
                    });
                }).catch(({ response }) => {
                    this.setState({
                        hasErrors: true,
                        articoloList: newArticoloList,
                        articoloLoaded: isLoaded,
                        articlesSize: aSize,
                        aLoading: false,
                    });
                });
                this.setState({ aLoading: true });
            } else {
                this.setState({
                    hasErrors: false,
                    articoloList: [],
                    articoloLoaded: true,
                    aLoading: false,
                });
            }
        }
    },
    onReloadRelativeNews(newList = undefined) {
        let nSize = this.state.newsSize;
        let areetematiche = this.state.selectedAreeTematiche;
        if (newList) { areetematiche = newList; }

        if (newsSize === 0) {
            nSize = 0;
        } else if (newsSize) {
            nSize = newsSize;
        }

        let newNewsList = [];
        let isLoaded = false;
        if (NewsCorrelate != null && NewsCorrelate.length > 0) {
            axios.post(lastNewsForWidgetApiEndpoint, {
                idList: NewsCorrelate,
            }).then(({ data }) => {
                isLoaded = true;
                if (data.data != null) {
                    newNewsList = data.data;
                }
                this.setState({ newsList: newNewsList, newsLoaded: isLoaded, nLoading: false });
            });
            this.setState({ nLoading: true });
        } else {
            if (areetematiche && areetematiche.length !== '') {
                const url = `${lastNewsForWidgetApiEndpoint}/?areatematica=${areetematiche}&size=${nSize}`;
                // , {
                //     size: nSize,
                //     start: 0,
                //     AreeTematiche: this.state.AreeTematiche
                // }
                axios.get(url).then(({ data }) => {
                    isLoaded = true;
                    if (data.data != null) {
                        newNewsList = data.data;
                    }
                    this.setState({ newsList: newNewsList, newsLoaded: isLoaded, nLoading: false });
                });
                this.setState({ nLoading: true });
            } else {
                this.setState({
                    hasErrors: false,
                    newsList: [],
                    newsLoaded: true,
                    nLoading: false,
                });
            }
        }
    },
    onReloadRelativeVideos(newList = undefined) {
        let vSize = this.state.videoSize;
        let areetematiche = this.state.selectedAreeTematiche;
        if (newList) { areetematiche = newList; }

        if (videoSize === 0) {
            vSize = 0;
        } else if (videoSize) {
            vSize = videoSize;
        }

        let newVideosList = [];
        let isLoaded = false;
        if (VideoCorrelati != null && VideoCorrelati.length > 0) {
            axios.post(lastVideoForWidgetApiEndpoint, {
                idList: VideoCorrelati,
            }).then(({ data }) => {
                isLoaded = true;
                if (data.data != null) {
                    newVideosList = data.data;
                }
                this.setState({ videoList: newVideosList, videoLoaded: isLoaded, vLoading: false });
            });
            this.setState({ vLoading: true });
        } else {
            if (areetematiche && areetematiche.length !== '') {
                const url = `${lastVideoForWidgetApiEndpoint}/?areatematica=${areetematiche}&size=${vSize}`;
                // , {
                //     size: vSize,
                //     start: 0,
                //     AreeTematiche: this.state.AreeTematiche
                // }
                axios.get(url).then(({ data }) => {
                    isLoaded = true;
                    if (data.data != null) {
                        newVideosList = data.data;
                    }
                    this.setState({ videoList: newVideosList, videoLoaded: isLoaded, vLoading: false });
                });
                this.setState({ vLoading: true });
            } else {
                this.setState({
                    hasErrors: false,
                    videoList: [],
                    videoLoaded: true,
                    vLoading: false,
                });
            }
        }
    },
    handleInputChange(event) {
        let {
            eventW,
            eventH,
            eventD,
        } = this.state;
        const {
            wIsNull,
            hIsNull,
            dIsNull,
        } = this.state;
        const { target } = event;
        const { value, name } = target;
        if (name === 'wTextbox') {
            if (value === '' && wIsNull === true) {
                eventW = false;
                this.setState({ textboxWidth: value, eventW });
            } else {
                this.setState({ textboxWidth: value, eventW: true, wIsNull: false });
            }
            return;
        }
        if (name === 'hTextbox') {
            if (value === '' && hIsNull === true) {
                eventH = false;
                this.setState({ textboxHeight: value, eventH });
            } else {
                this.setState({ textboxHeight: value, eventH: true, hIsNull: false });
            }
            return;
        }
        if (name === 'dTextbox') {
            if (value === '' && dIsNull === true) {
                eventD = false;
                this.setState({ textboxDomain: value, eventD });
            } else {
                this.setState({ textboxDomain: value, eventD: true, dIsNull: false });
            }
        }
    },
    onClickedButton() {
        const areeTematicheSelected = this.state.filterAreeTematiche;
        const txtW = this.state.textboxWidth;
        const txtH = this.state.textboxHeight;
        const txtD = this.state.textboxDomain;
        let { wIsNull, hIsNull, dIsNull } = this.state;
        if (txtW === '') {
            wIsNull = true;
            this.setState({ wIsNull });
            notification('error', 'Inserire l\'altezza');
        }
        if (txtH === '') {
            hIsNull = true;
            this.setState({ hIsNull });
            notification('error', 'Inserire larghezza');
        }
        if (txtD === '' || txtD.indexOf('/') !== -1) {
            dIsNull = true;
            this.setState({ dIsNull });
            notification('error', 'Inserire il dominio o verificare che sia valido!');
        }
        if (wIsNull === true || hIsNull === true || dIsNull === true) {
            return;
        }

        // const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        if (areeTematicheSelected && areeTematicheSelected.length !== 0) {
            const areeList = [];
            areeTematicheSelected.forEach(area => areeList.push(area.value));
            axios.post(generateHtmlWidgetEndpoint, {
                areeTematicheList: areeList,
                bColor: this.state.bColor,
                fColor: this.state.fColor,
                imgPath: this.state.imgPath,
                aValue: this.state.aValue,
                nValue: this.state.nValue,
                vValue: this.state.vValue,
                textboxWidth: this.state.textboxWidth,
                textboxHeight: this.state.textboxHeight,
                textboxDomain: this.state.textboxDomain,
                articlesSize: this.state.articlesSize,
                newsSize: this.state.newsSize,
                videoSize: this.state.videoSize,
                htmlTextbox: this.state.htmlTextbox,
                idLanding,
            }).then((response) => {
                const { data } = response;
                this.setState({ htmlTextbox: data.data, htmlLoading: false });
                notification('success', response.data.message, 'Html Generato');
            }).catch((error) => {
                notification('error', 'Errore nella generazione del codice HTML');
            });
            this.setState({ htmlLoading: true });
        } else {
            notification('error', 'Errore nella generazione del codice HTML. Nessuna area tematica inserita');
        }
    },
    render() {
        const {
            areeTematicheListLoaded,
            filterAreeTematiche,
            areeTematicheList,
            aValue,
            nValue,
            vValue,
            hIsNull,
            eventH,
            textboxHeight,
            textboxWidth,
            wIsNull,
            eventD,
            eventW,
            dIsNull,
            textboxDomain,
            bColor,
            fColor,
            imgPath,
            articoloList,
            videoList,
            newsList,
            isArticoli,
            isNews,
            isVideos,
            htmlLoading,
            htmlTextbox,
        } = this.state;
        return (
            <div className="row">
                <div className="col-xs-4" style={{ marginTop: '-10px' }}>
                    <h3> Personalizza il tuo Widget tematico dei contenuti</h3>
                    <br />
                    <p style={{ textAlign: 'left' }}>
                        Con il Widget tematico puoi portare articoli, news e video da Consulenza.it al tuo sito web, filtrandoli sulla base dei temi per te interessanti. In questo modo potrai offrire ai visitatori del tuo sito un servizio di aggiornamento in tempo reale sulle tematiche da te selezionate
                    </p>
                    <br />
                    <p style={{ textAlign: 'left' }}>
                        Puoi personalizzare il tuo Widget scegliendo quanti contenuti visualizzare per ogni tipologia (articoli, news, video) in base allo spazio che hai a disposizione sulla pagina web nel quale desideri visualizzarlo. Per poter ancorare l’applicazione sul tuo sito occorrerà inserire il codice del tuo widget personalizzato nel codice html della pagina di destinazione. Prova ad impostare le caratteristiche del widget nella sezione qui a fianco, per vedere come si visualizzerà.
                    </p>
                    {(hasWidgetEnabled === 'false')
                        ? (
                            <a href={linkServizio} target="_blank" rel="noreferrer">
                                <input type="button" className="btn widget-button" value="Acquista il Widget" />
                            </a>
                        ) : null}
                </div>
                <hr className="vertical-hr" />
                <div className="col-xs-4">
                    <div className="" style={{ width: '80%' }}>
                        <h5><b>Seleziona le aree tematiche:</b></h5>
                        <Select
                            loading={!areeTematicheListLoaded}
                            labelInValue
                            placeholder="Aree Tematiche"
                            mode="multiple"
                            style={{ width: '100%' }}
                            name="areeTematiche"
                            value={filterAreeTematiche}
                            options={areeTematicheList}
                            onChange={this.handleAreeTematicheChange}
                        />
                    </div>
                    <div className="widget-checkboxes">
                        <h5><b>Tipologia del contenuto da visualizzare:</b></h5>
                        <div style={{ float: 'left' }}>
                            <Checkbox
                                defaultChecked
                                onChange={this.handleCategoryTypeChange}
                                // disabled={this.state.isLight}
                                name="isArticoli"
                            />
                            &nbsp;&nbsp;
                            <b>
                                Articoli
                            </b>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div style={{ float: 'left' }}>
                            <Checkbox
                                defaultChecked
                                onChange={this.handleCategoryTypeChange}
                                // disabled={this.state.isDark}
                                name="isNews"
                            />
                            &nbsp;&nbsp;
                            <b>
                                News
                            </b>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div style={{ float: 'left' }}>
                            <Checkbox
                                defaultChecked
                                onChange={this.handleCategoryTypeChange}
                                // disabled={this.state.isDark}
                                name="isVideos"
                            />
                            &nbsp;&nbsp;
                            <b>
                                Video
                            </b>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                    <div className="slider-horizontal">
                        <h5><b>Numero di Articoli da visualizzare:</b></h5>
                        <Slider
                            min={1}
                            max={3}
                            value={aValue}
                            labels={{ 1: '1', 2: '2', 3: '3' }}
                            orientation="horizontal"
                            onChange={this.handleChangeArticles}
                        />
                    </div>
                    <div className="slider-horizontal">
                        <h5><b>Numero di News da visualizzare:</b></h5>
                        <Slider
                            min={1}
                            max={3}
                            value={nValue}
                            labels={{ 1: '1', 2: '2', 3: '3' }}
                            orientation="horizontal"
                            onChange={this.handleChangeNews}
                        />
                    </div>
                    <div className="slider-horizontal">
                        <h5><b>Numero di Video da visualizzare:</b></h5>
                        <Slider
                            min={1}
                            max={3}
                            value={vValue}
                            labels={{ 1: '1', 2: '2', 3: '3' }}
                            orientation="horizontal"
                            onChange={this.handleChangeVideos}
                        />
                    </div>
                    <div className="widget-dimension">
                        <div className="form-group-widget">
                            <label>Altezza:&nbsp;</label>
                            {hIsNull === false && eventH === true
                                ? <input className="form-control-widget" id="hTextbox" name="hTextbox" type="text" value={textboxHeight} onChange={this.handleInputChange} />
                                : <input className="form-control-widget error" id="hTextbox" name="hTextbox" type="text" value={textboxHeight} onChange={this.handleInputChange} />
                            }
                            <small>px&nbsp;&nbsp;</small>
                        </div>
                        <div className="form-group-widget">
                            <label>&nbsp;Larghezza:&nbsp;</label>
                            {wIsNull === false && eventW === true
                                ? <input className="form-control-widget" id="wTextbox" name="wTextbox" type="text" value={textboxWidth} onChange={this.handleInputChange} />
                                : <input className="form-control-widget error" id="wTextbox" name="wTextbox" type="text" value={textboxWidth} onChange={this.handleInputChange} />
                            }
                            <small>px</small>
                        </div>
                        <br />
                    </div>
                    <br />
                    <div className="form-group-widget">
                        <label>
                            Inserisci il dominio di destinazione del widget:&nbsp;
                            <br />
                            <small>
                                (ad esempio: consulenza.it, senza &apos;https://&apos; o &apos;/Home&apos;)
                            </small>
                            &nbsp;
                        </label>
                        {dIsNull === false && eventD === true
                            ? <input className="form-control-widget-domain" id="dTextbox" name="dTextbox" type="text" value={textboxDomain} onChange={this.handleInputChange} />
                            : <input className="form-control-widget-domain error" id="dTextbox" name="dTextbox" type="text" value={textboxDomain} onChange={this.handleInputChange} />
                        }
                    </div>
                    <br />
                </div>
                <hr className="vertical-hr-2" />
                <div className="col-xs-4" style={{ marginTop: '15px', width: '30%', marginLeft: '-50px' }}>
                    <Card
                        style={{ width: `${widgetWidth}px`, borderRadius: '20px' }}
                        bgColor={bColor}
                        fontColor={fColor}
                        className="widget-style-landing"
                    >
                        <CardHeader
                            style={{
                                borderBottom: 'none',
                                color: '#ffffff',
                                marginTop: '10px',
                                flexDirection: 'column',
                                alignItems: 'start',
                            }}
                        >
                            {/* <div><a href="https://www.consulenza.it" target="_blank" rel="noreferrer"><img src={imgPath} style={{ position: 'absolute', right: 5, top: 5 }} alt="img" /></a></div> */}
                            <div style={{ lineHeight: '0px', marginTop: '10px', marginBottom: '15px' }}>
                                <h4 style={{ fontSize: '22px', marginBottom: '3px' }}><b>WIDGET</b></h4>
                                {/* <p style={{ fontSize: '18px', lineHeight: '20px' }}><i>{_.toUpper(nomeCampagna)}</i></p> */}
                            </div>
                            <hr className="wHr" />
                        </CardHeader>
                        <CardBody
                            style={{ marginBottom: '-50px', marginTop: '30px' }}
                        >
                            {(articoloList != null && articoloList.length > 0)
                                ? (
                                    <div className="article">
                                        {articoloList.map(article => <LastArticlesItem key={article.Id} {...article} />)}
                                    </div>
                                ) : isArticoli === true
                                && (
                                    <div className="article">
                                        <li className="single-content-art">
                                            Nessun articolo
                                        </li>
                                        <hr className="wHr" />
                                    </div>
                                )
                            }
                            <br />
                            {(newsList != null && newsList.length > 0)
                                ? (
                                    <div className="article">
                                        {newsList.map(news => <LastNewsItem key={news.Id} {...news} />)}
                                    </div>
                                ) : isNews === true
                                && (
                                    <div className="article">
                                        <li className="single-content-art">
                                            Nessuna news
                                        </li>
                                        <hr className="wHr" />
                                    </div>
                                )
                            }
                            <br />
                            {(videoList != null && videoList.length > 0)
                                ? (
                                    <div className="article">
                                        {videoList.map(video => <LastVideosItem key={video.Id} {...video} />)}
                                    </div>
                                ) : isVideos === true
                                && (
                                    <div className="article">
                                        <li className="single-content-art">
                                            Nessun video
                                        </li>
                                        <hr className="wHr" />
                                    </div>
                                )
                            }
                        </CardBody>
                        <CardFooter
                            style={{ borderBottom: 'none', borderTop: 'none' }}
                        >
                            {/* <div className="footer-content">
                            <p className="wP">Informa i tuoi utenti/clienti direttamente<br />sul tuo sito con il widget di consulenza.it</p><br />
                            <div className="widget-button">
                                <a className="wA" href="#" >SCOPRI COME</a>
                        </div>
                            </div> */}
                        </CardFooter>
                    </Card>
                </div>
                <div className="col-xs-12">
                    {(hasWidgetEnabled === 'true' && htmlLoading === false)
                        ? (
                            <div>
                                <div className="generate-html-button">
                                    <input type="button" className="btn widget-button" value="Conferma selezione e GENERA CODICE HTML" onClick={this.onClickedButton} />
                                </div>
                                <input className="form-control-widget-html" id="htmlTextbox" name="htmlTextbox" type="text" value={htmlTextbox} />
                            </div>
                        ) : (
                            <div>
                                {htmlLoading === true
                                    ? <LoadingSpinner /> : null}
                            </div>
                        )}
                </div>
            </div>
        );
    },
};

const WidgetAreeTematiche = reactStamp(React).compose(
    container,
);

export default WidgetAreeTematiche;
