/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import axios from 'axios';
import {
    Form,
    Input,
    Button,
    Checkbox,
} from 'antd';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../common/loadingOverlay';
import { errorNotification, successNotification } from '../../../common/Notification';

export default class BpDemoContainer extends Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(values) {
        showLoadingOverlay();
        axios.post('/api/BpDemoApi/submitSubscription', {
            ...values,
        }).then(({ data }) => {
            axios.get(data.redirectUrl).then(() => {
                hideLoadingOverlay();
                successNotification('Demo BP attivata con successo, riceverai un\'email a breve.');
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
            }).catch((error) => {
                errorNotification(error.response.data);
            });
        }).catch((error) => {
            hideLoadingOverlay();
            errorNotification(error.response.data);
        });
    }

    render() {
        return (
            <>
                <div className="row w-100 text-center p-3">
                    <div className="col-2" />
                    <div className="col-md-8 col-12">
                        <img className="img-fluid" src="https://bpstorage.directio.it/images/logoBP-white.png" alt="logo" />
                        <h2 style={{ color: '#77B3D4' }}>Modulo per l'attivazione di una demo gratuita della piattaforma Business Plan Directio.</h2>
                        <p>Compila questo form per richiedere una demo gratuita ed esplorare le caratteristiche funzionali della piattaforma Business Plan Directio. Riceverai via mail le credenziali di accesso al tuo account di prova che ti permetterà di creare il tuo piano e valutare tutte le funzionalità della piattaforma per 7 giorni.</p>
                    </div>
                    <div className="col-2" />
                </div>
                {/* <div className="m-auto" style={{ width: '70vw' }}> */}
                <div className="row">
                <div className="col-md-2 col-1" />
                    <div className="col-md-8 col-10">

                    <Form
                        layout="vertical"
                        onFinish={this.submitForm}
                    >
                        <div className="row">
                            <Form.Item
                                className="col-md-6 col-12"
                                label="Nome"
                                name="nome"
                                rules={[{ required: true, message: 'Il nome è obbligatorio.' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                className="col-md-6 col-12"
                                label="Cognome"
                                name="cognome"
                                rules={[{ required: true, message: 'Il cognome è obbligatorio.' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>


                        <div className="row">
                            <Form.Item
                                className="col-md-6 col-12"
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'L\' email è obbligatorio.' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                className="col-md-6 col-12"
                                label="Telefono"
                                name="telefono"
                                rules={[{ required: true, message: 'Il numero di telefono è obbligatorio.' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>


                        <div className="row">
                            <Form.Item
                                className="col-md-6 col-12"
                                label="Codice Fiscale"
                                name="codiceFiscale"
                                rules={[{ required: true, message: 'Il codice fiscale è obbligatorio.' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                className="col-md-6 col-12"
                                label="Professione"
                                name="professione"
                            >
                                <Input />
                            </Form.Item>
                        </div>


                        <div className="row align-items-end">
                            <Form.Item
                                className="col-md-6 col-12"
                                label="Azienda"
                                name="azienda"
                            >
                                <Input />
                            </Form.Item>

                            <div id="checkBoxMargin">
                                <Form.Item name="consent" valuePropName="checked">
                                    <Checkbox>
                                        Autorizzo il trattamento dei miei dati personali ai sensi del Decreto Legislativo 30 giugno 2003,
                                        <br />
                                        n. 196 e del GDPR (Regolamento UE 2016/679)*
                                    </Checkbox>
                                </Form.Item>
                            </div>

                        </div>

                        <div className="text-center">
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Iscriviti
                                </Button>
                            </Form.Item>
                        </div>

                    </Form>
                    </div>
                    <div className="col-md-2 col-1" />
                </div>
            </>
        );
    }
}
